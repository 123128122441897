const halfHourTemplates = (prefix) => {
  let template = ``
  // Polska
  if(prefix === "+48") {
    template = `Twoje zamówienie zostanie dostarczone w ciągu 30 minut.`
  }
  // Niemcy
  if(prefix === "+49") {
    template = `Ihre Bestellung wird innerhalb von 30 Minuten zugestellt.`
  }
  // Francja
  if(prefix === "+33") {
    template = `Votre commande sera livrée dans les 30 minutes`
  }
  // Luksemburg
  if(prefix === "+352") {
    template = `Ihre Bestellung wird innerhalb von 30 Minuten zugestellt`
  }
  // Włochy
  if(prefix === "+39") {
    template = `Il vostro ordine sarà consegnato entro 30 minuti.`
  }
  // Holandia
  if(prefix === "+31") {
    template = `Je bestelling wordt binnen 30 minuten bezorgd.`
  }
  // Belgia
  if(prefix === "+32") {
    template = `Je bestelling wordt binnen 30 minuten bezorgd.`
  }
  // Austria
  if(prefix === "+43") {
    template = `Ihre Bestellung wird innerhalb von 30 Minuten zugestellt.`
  }

  return template
}

const notificationDayBeforeDelivery = (prefix, orderId, key, shortenId, code) => {
  let template = ``

  // Polska
  if(prefix === "+48") {
    template = `Twoje zamówienie jutro wyruszy z naszego magazynu. Potwierdź odbiór poprzez stronę: (https://sofart.me/${shortenId}). Kod odbioru zamówienia: ${code}.`
  }
  // Niemcy
  if(prefix === "+49") {
    template = `Ihre Bestellung wird morgen unser Lager verlassen. Bestätigen Sie den Empfang über die Website: (https://moeblo.net/${shortenId}). Code für die Abholung der Bestellung: ${code}.`
  }
  // Francja
  if(prefix === "+33") {
    template = `Votre commande quittera notre entrepôt demain. Confirmez la réception via le site web: (https://e-meubles.com/${shortenId}). Code d'enlèvement de la commande :${code}.`
  }
  // Luksemburg
  if(prefix === "+352") {
    template = `Ihre Bestellung wird morgen unser Lager verlassen. Bestätigen Sie den Empfang über die Website: (https://moeblo.net/${shortenId}). Code für die Abholung der Bestellung: ${code}.`
  }
  // Włochy
  if(prefix === "+39") {
    template = `Il vostro ordine lascerà il nostro magazzino domani. Confermare la ricezione tramite il sito web: (https://mbmoebel.com/${shortenId}). Codice di ritiro dell'ordine: ${code}.`
  }
  // Holandia
  if(prefix === "+31") {
    template = `Je bestelling verlaat morgen ons magazijn. Bevestig de ontvangst via de website: (https://inspiremefurnitures.com/${shortenId}). Bestelcode afhalen: ${code}.`
  }
  // Belgia
  if(prefix === "+32") {
    template = `Je bestelling verlaat morgen ons magazijn. Bevestig de ontvangst via de website: (https://inspiremefurnitures.com/${shortenId}). Bestelcode afhalen: ${code}`
  }
  // Austria
  if(prefix === "+43") {
    template = `Ihre Bestellung wird morgen unser Lager verlassen. Bestätigen Sie den Empfang über die Website: (https://moeblo.net/${shortenId}). Code für die Abholung der Bestellung: ${code}.`
  }

  return template
}

const changedDeliveryTimeTemplate = (prefix, time) => {
  let template = ``

  // Polska
  if(prefix === "+48") {
    template = `Zmiana godziny dostawy. Nowa godzina: ${time}. Przepraszamy.`
  }
  // Niemcy
  if(prefix === "+49") {
    template = `Änderung der Lieferfrist. Neue ungefähre Zeit: ${time}. Entschuldigung.`
  }
  // Francja
  if(prefix === "+33") {
    template = `Modification du délai de livraison. Nouveau délai approximatif : ${time}. Désolé.`
  }
  // Luksemburg
  if(prefix === "+352") {
    template = `Änderung der Lieferfrist. Neue ungefähre Zeit: ${time}. Entschuldigung.`
  }
  // Włochy
  if(prefix === "+39") {
    template = `Modifica dell'orario di consegna. Nuovo orario approssimativo: ${time}. Spiacente.`
  }
  // Holandia
  if(prefix === "+31") {
    template = `Wijziging van levertijd. Nieuwe geschatte tijd: ${time}. Sorry.`
  }
  // Belgia
  if(prefix === "+32") {
    template = `Wijziging van levertijd. Nieuwe geschatte tijd: ${time}. Sorry.`
  }
  // Austria
  if(prefix === "+43") {
    template = `Änderung der Lieferfrist. Neue ungefähre Zeit: ${time}. Entschuldigung.`
  }

  return template
}


module.exports = function(data) { 
  let template = ``

  if(data.messageType === 'halfHour') {
    template = halfHourTemplates(data.prefix)
  }

  if(data.messageType === 'changeDeliveryTime') {
    template = changedDeliveryTimeTemplate(data.prefix, data.time)
  }

  if(data.messageType === 'notificationDayBeforeDelivery') {
    template = notificationDayBeforeDelivery(data.prefix, data.orderId, data.key, data.shortenId, data.code)
  }

  return template
}

