<template>
   <div :class="[this.tabs[0].current ? searcher.found.length > 0 ? searcher.found.length > 8 ? '' : 'h-screen' : products.length > 8 ? '' : 'h-screen' : '', this.tabs[1].current ? products.length > 8 ? '' : 'h-screen' : '', this.tabs[2].current ? loadingRoutes ? 'h-screen' : '' : '', 'bg-gray-100']">
      <div class="mx-auto max-w-7xl px-5 pb-10">
         <div class="pt-10 pb-8">
            <div class="sm:hidden select-none">
               <label for="tabs" class="sr-only">Select a tab</label>
               <!-- Use an "onChange" listener to redirect the user to the selected tab URL. -->
               <select id="tabs" name="tabs" class="block w-full rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500">
               <option v-for="tab in tabs" :key="tab.name" :selected="tab.current">{{ tab.name }}</option>
               </select>
            </div>
            <div class="hidden sm:block select-none">
               <nav :class="[this.tabs[0].current ? 'justify-between' : '', 'flex']" aria-label="Tabs">
                  <div class="flex flex-wrap gap-4">
                     <a :disabled="blockBtn" v-for="(tab, index) in tabs" :key="tab.name" :href="tab.href" @click="changeTab(index)" :class="[tab.current ? 'bg-indigo-200 text-indigo-700' : 'text-gray-500 hover:text-gray-700 hover:bg-gray-300', 'bg-gray-200  inline-flex items-center rounded-md px-6 py-4 text-md font-semibold uppercase transition-all duration-500', blockBtn ? 'opacity-30 pointer-events-none' : '']" :aria-current="tab.current ? 'page' : undefined">
                        <component :is="tab.icon" :class="[tab.current ? 'text-indigo-500' : 'text-gray-400 group-hover:text-gray-500', '-ml-0.5 mr-2 h-7 w-7']" aria-hidden="true" />
                        {{ tab.name }}
                     </a>
                  </div>
                  <div v-if="this.tabs[0].current" class="flex gap-4 items-center">
                     <Transition name="fade">
                     <div class="flex items-center gap-4" v-if="searchBar">
                        <label for="search" class="sr-only">Szukaj</label>
                        <div class="relative">
                           <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                              <SearchIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
                           </div>
                           <input :disabled="this.searcher.locked" v-model="searcher.phrase" id="search" name="search" :class="['block w-full rounded-md border border-gray-300 bg-white pl-10 py-4 text-sm placeholder-gray-500 focus:border-blue-500 focus:text-gray-900 focus:placeholder-gray-400 focus:outline-none focus:ring-1 focus:ring-blue-500 sm:text-sm', this.searcher.locked ? 'opacity-50' : '']" placeholder="Wyszukaj" type="search" />
                        </div>
                        <button :disabled="this.searcher.locked" type="button" @click.prevent="search" :class="['rounded-md bg-indigo-100 px-5 py-4 text-sm font-semibold text-indigo-600 shadow-sm transition-all duration-300 cursor-pointer', this.searcher.locked || this.searcher.phrase.length === 0 ? 'opacity-50 cursor-default' : 'hover:bg-blue-200 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2']">
                           Szukaj
                        </button>
                     </div>
                     </Transition>
                     <div @click="openSearch" :class="[searchBar ? 'bg-indigo-200 hover:bg-gray-300' : 'bg-gray-200 hover:bg-gray-300', 'inline-flex justify-center gap-x-1.5 rounded-md px-3 py-2 text-sm font-semibold text-gray-900 outline-none transition-all duration-300']">
                        <SearchCircleIcon v-if="this.tabs[0].current" class="text-gray-500 w-10 h-10" />
                     </div>
                  </div>
               </nav>
            </div>
         </div>
         <div class="pb-8" v-if="this.tabs[1].current">
            <div>
               <div class="sm:hidden">
                  <label for="tabs" class="sr-only">Select a tab</label>
                  <!-- Use an "onChange" listener to redirect the user to the selected tab URL. -->
                  <select id="tabs" name="tabs" class="block w-full rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500">
                  <option v-for="tab in tabs" :key="tab.name" :selected="tab.current">{{ tab.name }}</option>
                  </select>
               </div>
               <div class="hidden sm:block select-none">
                  <nav class="flex space-x-4" aria-label="Tabs">
                     <a v-for="(tab, index) in suggestedTabs" @click="changeSuggestedTab(index)" :key="tab.name" :href="tab.href" :class="[tab.current ? 'bg-indigo-200 text-indigo-600' : 'text-gray-500 hover:text-gray-700', 'rounded-md px-3 py-2 text-sm uppercase font-semibold transition-all duration-300']" :aria-current="tab.current ? 'page' : undefined">
                        {{ tab.name }}
                     </a>
                  </nav>
               </div>
            </div>
         </div>

         <div class="relative mb-8">
            <div class="absolute inset-0 flex items-center" aria-hidden="true">
               <div class="w-full border-t border-gray-300" />
            </div>
            <div class="relative flex justify-center">
               <span class="bg-gray-100 px-2 text-gray-500">
               <ChevronDownIcon class="h-5 w-5 text-gray-500" aria-hidden="true" />
               </span>
            </div>
         </div>

         <!-- all -->
         <Transition name="fade">
         <div v-if="this.tabs[0].current">
            <Transition name="fade">
            <div class="mb-4" v-if="searcher.found.length > 0">
               <span class="select-none inline-flex items-center py-0.5 pl-2 pr-0.5 rounded-full text-xs font-medium bg-blue-100 text-blue-700">
                  Wynik wyszukiwania dla frazy: "{{searcher.lastCall.phrase}}" (Znaleziono: {{searcher.found.length}})
                  <button @click.prevent="resetSearch"  type="button" class="transition ease-in-out duration-300 flex-shrink-0 ml-0.5 h-4 w-4 rounded-full inline-flex items-center justify-center text-blue-400 hover:bg-blue-200 hover:text-blue-500 focus:outline-none focus:bg-blue-500 focus:text-white">
                     <svg class="h-2 w-2" stroke="currentColor" fill="none" viewBox="0 0 8 8">
                        <path stroke-linecap="round" stroke-width="1.5" d="M1 1l6 6m0-6L1 7" />
                     </svg>
                  </button>
               </span>           
            </div>
            </Transition>
            <Transition name="fade">
            <div v-if="products.length > 0">
               <ul role="list" class="grid grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
                  <li v-for="(product, index) in searcher.found.length > 0 ? searcher.found : products" :key="product.id" @click.prevent="selectProduct(index)" class="col-span-1 flex flex-col divide-y divide-gray-200 rounded-lg  text-center shadow select-none">
                  <div :class="[product.selected ? 'bg-green-100' : 'bg-white hover:bg-green-50', 'transition-all duration-300 flex flex-1 flex-col p-8 ']">
                     <img :onload="loadImage" class="mx-auto h-32 w-32 flex-shrink-0 mix-blend-multiply" :src="product.image.url !== undefined && product.image.url !== null && product.image.url.length > 0 ? product.image.url : `https://api.dicebear.com/7.x/initials/svg?seed=${product.name}`" alt="" />
                     <h3 class="mt-6 text-sm font-medium text-gray-900">{{ product.name }}</h3>
                     <dl class="mt-1 flex flex-grow flex-col justify-between">
                        <dd class="text-sm text-gray-500">{{ product.manufacturer.name !== undefined && product.manufacturer.name !== null ? product.manufacturer.name : product.manufacturer }}</dd>
                     </dl>
                  </div>
                  </li>
               </ul>
               <div class="py-10">
                  <LoadingSpinnerHub v-if="loading" />
                  <div v-if="products.length > 0 && searcher.found.length === 0 && !loading && !queryLimitReached" class="flex justify-center mt-10">
                     <InfiniteLoading @infinite="onLoadMore('infLoading')" />
                  </div>
               </div>
            </div>
            </Transition>
         </div>
         </Transition>
         <!-- suggested -->
         <Transition name="fade">
         <div v-if="this.tabs[1].current">
            <Transition name="fade">
            <div v-if="this.suggestedTabs[0].current">
               <div class="mb-4 -mt-4">
                  <Transition name="fade">
                     <div v-if="lists.length > 0" class="mb-6">
                        <div class="mx-auto max-w-7xl">
                           <div class="mx-auto max-w-2xl lg:mx-0">
                              <p class="text-base font-semibold leading-7 text-indigo-600">Zamówione produkty</p>
                              <span class="mt-2 text-3xl font-bold tracking-tight text-gray-9002">Utworzone listy PZ / WZ</span>
                           </div>
                        </div>
                        <nav :class="[lists.length > 5 ? 'overflow-x-scroll' : '', 'flex space-x-4 whitespace-nowrap select-none cursor-pointer py-5']" aria-label="Tabs">
                           <a v-tooltip="`Użycie listy spowoduje usunięcie wszystkiego z koszyka.`" v-for="(tab, index) in lists" :key="tab.title" @click="selectList(index)" :class="[selectedList === tab.id ? 'ring-indigo-400 ring-2' : '', ' rounded-md px-3 py-2 text-sm font-medium transition-all duration-300 bg-indigo-200 hover:bg-indigo-300 text-indigo-700']" :aria-current="tab.current ? 'page' : undefined">
                              {{ tab.title }}
                           </a>
                        </nav>
                        <p class="text-gray-400 italic text-sm select-none">Użycie powyżej listy spowoduje usunięcie wszystkiego z koszyka.</p>
                     </div>
                  </Transition>
                  <div class="flex">
                     <div class="flex mr-4">
                        <label for="search" class="sr-only">Szukaj</label>
                        <div class="relative">
                           <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                              <SearchIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
                           </div>
                           <input :disabled="this.searcher.locked" v-model="searcher.phrase" id="search" name="search" :class="['block w-full rounded-md border border-gray-300 bg-white py-2 pl-10 pr-3 text-sm placeholder-gray-500 focus:border-blue-500 focus:text-gray-900 focus:placeholder-gray-400 focus:outline-none focus:ring-1 focus:ring-blue-500 sm:text-sm', this.searcher.locked ? 'opacity-50' : '']" placeholder="Wyszukaj" type="search" />
                        </div>
                        <button :disabled="this.searcher.locked" type="button" @click.prevent="search" :class="['transition ease-in-out duration-300 ml-2 inline-flex items-center rounded border border-transparent bg-blue-100 px-2.5 py-1.5 text-xs font-medium text-blue-700', this.searcher.locked || this.searcher.phrase.length === 0 ? 'opacity-50 cursor-default' : 'hover:bg-blue-200 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2']">Szukaj</button>
                     </div>
                  </div>
                  <div class="mt-4" v-if="searcher.found.length > 0">
                     <span class="inline-flex items-center py-0.5 pl-2 pr-0.5 rounded-full text-xs font-medium bg-blue-100 text-blue-700">
                        Wynik wyszukiwania dla frazy: "{{searcher.lastCall.phrase}}" (Znaleziono: {{searcher.found.length}})
                        <button @click.prevent="resetSearch"  type="button" class="transition ease-in-out duration-300 flex-shrink-0 ml-0.5 h-4 w-4 rounded-full inline-flex items-center justify-center text-blue-400 hover:bg-blue-200 hover:text-blue-500 focus:outline-none focus:bg-blue-500 focus:text-white">
                           <svg class="h-2 w-2" stroke="currentColor" fill="none" viewBox="0 0 8 8">
                              <path stroke-linecap="round" stroke-width="1.5" d="M1 1l6 6m0-6L1 7" />
                           </svg>
                        </button>
                     </span>   
                  </div>
               </div>

               <div class="mx-auto max-w-3xl lg:max-w-7xl">
                  <h1 class="sr-only">Page title</h1>
                  <!-- Main 3 column grid -->
                  <div class="grid grid-cols-1 items-start gap-4 lg:grid-cols-3 lg:gap-8">
                     <!-- Left column -->
                     <div :class="[selectedList !== null ? 'opacity-40 pointer-events-none' : '', 'grid grid-cols-1 gap-4 lg:col-span-2 transition-all duration-300']">
                        <section aria-labelledby="section-1-title">
                        <h2 class="sr-only" id="section-1-title">Section title</h2>
                        <div class="overflow-hidden rounded-lg bg-white shadow">
                           <!-- Your content -->
                           <table class="min-w-full divide-y divide-gray-300">
                              <thead class="bg-gray-50">
                                 <tr>
                                    <th scope="col" class="py-3.5 pl-4 pr-3 text-left text-sm uppercase font-semibold text-gray-500 sm:pl-6">Produkt</th>
                                    <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-500">EAN / SKU</th>
                                    <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold uppercase text-gray-500">Atrybuty</th>
                                    <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold uppercase text-gray-500">Warianty</th>
                                    <th scope="col" class="pr-3 py-3.5 text-left text-sm font-semibold text-gray-500">
                                       <span class="sr-only">Informacje</span>
                                    </th>
                                 </tr>
                              </thead>
                              <tbody class="divide-y divide-gray-200" v-for="(product,index) in searcher.found.length > 0 ? searcher.found : products" :key="index">
                              <tr>
                                 <td class="whitespace-nowrap py-4 text-sm sm:pl-6">
                                 <div class="flex items-center">
                                       <div class="h-10 w-10 flex-shrink-0">
                                          <img class="h-10 w-10 rounded-md object-contain" :src="product.image.url.length>0 ? product.image.url : `https://api.dicebear.com/7.x/initials/svg?seed=${`Product${product.id}`}`" :alt="product.name" />
                                       </div>
                                       <div class="ml-4">
                                          <div class="font-medium text-gray-900" v-tooltip="`${product.name}`">{{product.name.slice(0, 20) + `${product.name.length > 20 ? '...' : ''}`}}
                                             <span class="ml-1 transition ease-in-out duration-300 text-xs text-blue-300 hover:text-blue-500 select-none cursor-pointer" @click.prevent="copyToClipboard(product.id)">Kopiuj ID</span>
                                          </div>
                                          <div class="text-gray-500">
                                             <span class="text-xs" v-if="product.category.name.length>0">
                                                   {{product.category.name}}
                                             <span class="text-xs opacity-60">(ID kategorii:{{product.category.id}})</span>
                                             </span>
                                             <span class="text-xs" v-if="product.category.name.length===0">Brak kategorii</span>
                                          </div>
                                       </div>
                                 </div>
                                 </td>
                                 <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500 select-none">
                                    <div class="text-gray-500">
                                       <span class="text-xs" v-if="product.ean.length>0">
                                          <span class="text-gray-700 font-medium">EAN: </span>{{product.ean}}</span><span class="text-xs font-medium opacity-60" v-if="product.ean.length===0">Brak EAN</span><span class="transition ease-in-out duration-300 ml-2 text-xs text-blue-300 hover:text-blue-600 select-none cursor-pointer" v-if="product.ean.length>0" @click.prevent="copyToClipboard(product.ean)">Kopiuj</span></div>
                                    <div class="text-gray-500"><span class="text-xs" v-if="product.sku.length>0"><span class="text-gray-700 font-medium">SKU: </span>{{product.sku}}</span><span class="text-xs font-medium opacity-60" v-if="product.sku.length===0">Brak SKU</span><span class="transition ease-in-out duration-300 ml-2 text-xs text-blue-300 hover:text-blue-600 select-none cursor-pointer" v-if="product.sku.length>0" @click.prevent="copyToClipboard(product.sku)">Kopiuj</span></div>
                                 </td>
                                 <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500 select-none">
                                    <div>
                                       <span v-for="value in product.attribs.list.slice(0, 1)" :key="value.id" @click="showModal(product)"  class="transition ease-in-out duration-300 select-none w-fit inline-flex items-center px-2.5 py-0.5 rounded-md text-xs font-medium bg-gray-100 text-gray-600 cursor-pointer hover:bg-blue-100 hover:text-blue-400"> {{value.parent.name}}: {{value.name}} </span>
                                       <span class="transition ease-in-out duration-300 text-xs px-2.5 py-0.5 text-blue-300 hover:text-blue-500 cursor-pointer" v-if="product.attribs.list.length-1 > 0" @click="showModal(product)">+ {{product.attribs.list.length-1}} więcej</span>
                                       <span class="text-xs" v-if="product.attribs.list.length === 0">Brak</span>
                                    </div>
                                 </td>
                                 <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-700 select-none">
                                    <div class="w-28">
                                       <span class="w-full justify-center inline-flex items-center rounded-full bg-blue-50 px-2 py-1 text-xs font-medium text-blue-700 ring-1 ring-inset ring-blue-700/10" v-if="Object.keys(product.variants).length > 0">{{Object.keys(product.variants).length}} {{Object.keys(product.variants).length === 1 ? "wariant" : Object.keys(product.variants).length > 1 && Object.keys(product.variants).length < 5 ? "warianty" : "wariantów"}}</span>
                                       <span class="text-xs" v-if="Object.keys(product.variants).length === 0">Brak wariantów</span>
                                    </div>
                                 </td>
                                 <td class="whitespace-nowrap py-4 pr-5 text-xs cursor-pointer">
                                    <div v-auto-animate class="flex h-6 items-center text-gray-500" v-tooltip="`${Object.keys(product.variants).length > 0 ? ' Wyświetl szczegóły produktu' : 'Utwórz nowy wariant produktu.'}`" @click="Object.keys(product.variants).length > 0 ? openAdditionalInformation(index,product) : this.$router.push(`/dashboard/products/list/editProduct?id=${product.id}`)">
                                       <EyeIcon class="h-6 w-6" v-if="Object.keys(product.variants).length > 0 && !this.toggleInformation[index].open" />  
                                       <EyeOffIcon class="h-6 w-6" v-if="this.toggleInformation[index].open" />
                                    </div>
                                 </td>
                              </tr>
                              <td colspan="12" v-if="this.toggleInformation[index].open" class="bg-gray-100 border-t border-gray-300 w-full">
                                 <table class="min-w-full divide-y divide-gray-300">
                                    <thead class="bg-gray-100 w-full">
                                          <tr>
                                             <th scope="col" class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 uppercase">Warianty</th>
                                             <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 uppercase">Ilość Sztuk</th>
                                             <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"></th>
                                          </tr>
                                          <tr v-for="(variant,index) in createPositionsList(product)" :key="index">
                                             <td class="border-t border-gray-300 whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6">
                                                <div class="flex items-center">
                                                      <div class="h-10 w-10 flex-shrink-0">
                                                         <img class="h-10 w-10 rounded-md object-contain" :src="variant.item.image.url.length>0 ? variant.item.image.url : `https://api.dicebear.com/7.x/initials/svg?seed=${`Product${variant.item.id}`}`" :alt="variant.item.id" />
                                                      </div>
                                                      <div class="ml-4" >
                                                         <div class="font-medium text-gray-900">{{variant.item.name}} <span class="transition ease-in-out duration-300 text-xs text-blue-300 hover:text-blue-500 select-none cursor-pointer" @click.prevent="copyToClipboard(variant.item.id)">Kopiuj ID</span></div>
                                                      </div>
                                                </div>
                                             </td>
                                             <td class="border-t border-gray-300 whitespace-nowrap px-3 py-4 text-sm text-gray-500 select-none">
                                                <div :class="['text-gray-400 font-medium', variant.item.quantity < 10 ? 'text-red-700 font-medium':'']"><span class="text-xs" ><span class="text-gray-700 font-medium ">Ilość: </span>{{variant.item.quantity}}</span></div>
                                             </td>
                                             <td class="border-t border-gray-300 whitespace-nowrap px-3 py-4 text-sm text-gray-500 select-none">
                                                <div class="flex justify-center">
                                                   <button @click="addVariantToList(variant)" type="button" class="inline-flex items-center gap-x-1.5 rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 transition-all duration-300">
                                                      <DocumentAddIcon class="-ml-0.5 h-6 w-6" aria-hidden="true" />
                                                      Dodaj
                                                   </button>
                                                </div>
                                             </td>
                                          </tr>
                                    </thead>
                                 </table>
                              </td>
                              </tbody>
                           </table>
                        </div>
                        <div class="py-10">
                           <LoadingSpinnerHub v-if="loading" />
                           <div v-if="products.length > 0 && searcher.found.length === 0 && !loading && !queryLimitReached" class="flex justify-center mt-10">
                              <InfiniteLoading @infinite="onLoadMore('infLoading')" />
                           </div>
                        </div>
                        </section>
                     </div>

                     <!-- Right column -->
                     <div class="grid grid-cols-1  overflow-x-auto gap-4 sticky top-10">
                        <section aria-labelledby="section-2-title">
                        <h2 class="sr-only" id="section-2-title">Section title</h2>
                        <div class="overflow-hidden rounded-lg bg-white shadow">
                           <!-- Your content -->
                           <section aria-labelledby="section-2-title">
                           <h2 class="sr-only" id="section-2-title">Variants</h2>
                           <div class="pt-2 pb-6 px-6">
                              <div class="flex items-center justify-between py-3">
                                 <span class="uppercase text-gray-500 font-semibold text-base">Produkty do wydrukowania</span>
                              </div>
                              <ul style="max-height: 60vh" :class="[Object.entries(productOrdersCart).length > 3 ? 'overflow-scroll' : '', 'overflow-x-hidden']">
                                 <li class="text-lg border-b" v-for="(manufacturer, index) in Object.entries(productOrdersCart)" :key="manufacturer[0]">
                                       <div class="flex justify-between items-center" :class="index === 0 ? '' : 'mt-3'">
                                          <div class="flex gap-x-2">
                                             <p class=" text-gray-600 truncate uppercase font-semibold">Produkt {{index+1}}</p>
                                          </div>
                                          <button @click="toggleManufacturerVariants(manufacturer[0])">
                                             <ChevronDownIcon class="w-6 h-6 transition-all duration-200 transform text-gray-500" :class="[manufacturersToggleList[manufacturer[0]] ? 'rotate-180' : '']"/>
                                          </button>
                                       </div>
                                       <ul v-if="!manufacturersToggleList[manufacturer[0]]" class="pl-2">
                                          <li v-for="variant, indx in Object.values(manufacturer[1])" :key="variant.id" class="relative py-2 mb-2 ">
                                             <div class="mx-auto flex max-w-4xl cursor-pointer justify-between gap-x-6" @click="selectProductToPrint(variant, variant.index)">
                                                <div class="flex gap-x-4">
                                                   <img class="h-10 w-10 flex-none rounded-full" :src="variant.item.image.url.length > 0 ? variant.item.image.url : `https://api.dicebear.com/7.x/initials/svg?seed=${`${variant.item.name}`}`" alt="" />
                                                   <div class="min-w-0 flex-auto">
                                                      <p v-tooltip="`${variant.item.name}`" class="text-sm font-semibold leading-6 text-gray-900">
                                                         {{ variant.item.name.slice(0, 25) }} {{ variant.item.name.length > 25 ? '...' : ''}}
                                                      </p>
                                                      <p class="flex text-sm leading-5 text-gray-500">
                                                         {{variant.orderQuantity}} szt.
                                                      </p>
                                                   </div>
                                                </div>
                                                <div v-if="selectedList != null" class="flex h-6 items-center mr-1">
                                                   <input id="comments" v-model="variant.accepted" aria-describedby="comments-description" name="comments" type="checkbox" class="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600" />
                                                </div>
                                                <TrashIcon v-else @click="decrementVariant(variant, manufacturer[0])" class="mr-1 w-8 text-gray-300 hover:text-gray-400 transition-all duration-200 cursor-pointer"/>
                                             </div>
                                          </li>
                                       </ul>
                                 </li>
                              </ul>
                              <EmptyState class="my-3" v-if="Object.keys(productOrdersCart).length === 0" />
                              <div :class="[selectedList ? 'justify-between' : 'justify-end', 'flex gap-2 mt-4']">
                                 <Transition name="fade">
                                    <button v-if="selectedList != null" @click.prevent="toggleTypeOfPrint(null, 'cancel')" type="button" :class="['inline-flex gap-x-2 items-center rounded-md bg-gray-100 px-3.5 py-2.5 text-sm font-semibold text-gray-600 shadow-sm hover:bg-gray-200 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 transition-all duration-300']">
                                       <BanIcon class="-ml-0.5 h-5 w-5" aria-hidden="true" />
                                       Anuluj
                                    </button>
                                 </Transition>
                                 <Transition name="fade">
                                    <button v-if="Object.keys(productOrdersCart).length > 0 && this.selectedList != null" @click.prevent="toggleTypeOfPrint(selectedVariants, 'cart')"  type="button" :class="[this.selectedVariants.length > 0 ? '' : 'opacity-30 pointer-events-none', 'inline-flex items-center rounded-md bg-indigo-100 px-3.5 py-2.5 text-sm font-semibold text-indigo-600 shadow-sm hover:bg-indigo-200 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 whitespace-nowrap gap-x-2 transition-all duration-300']">
                                       <PrinterIcon class="-ml-0.5 h-5 w-5" aria-hidden="true" />
                                       Wydrukuj etykiety
                                    </button>
                                 </Transition>
                                 <Transition name="fade">
                                    <button v-if="Object.keys(productOrdersCart).length > 0 && this.selectedList == null" @click.prevent="toggleTypeOfPrint(productOrdersCart, 'cartMassive')" type="button" class="inline-flex items-center gap-x-2 rounded-md bg-indigo-100 px-3.5 py-2.5 text-sm font-semibold text-indigo-600 shadow-sm hover:bg-indigo-200 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 transition-all duration-300">
                                       <PrinterIcon class="-ml-0.5 h-5 w-5" aria-hidden="true" />
                                       Wydrukuj etykiety
                                    </button>
                                 </Transition>
                              </div>
                           </div>
                           </section>
                        </div>
                        </section>
                     </div>
                  </div>
               </div>
            </div>
            </Transition>
            <Transition name="fade">
            <div v-if="this.suggestedTabs[1].current && products.length > 0">
               <ul role="list" class="grid grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
                  <li v-for="(product, index) in searcher.found.length > 0 ? searcher.found : products" :key="product.id" @click.prevent="selectProduct(index)" class="col-span-1 flex flex-col divide-y divide-gray-200 rounded-lg  text-center shadow select-none">
                  <div :class="[product.selected ? 'bg-green-100' : 'bg-white hover:bg-green-50', 'transition-all duration-300 flex flex-1 flex-col p-8 ']">
                     <img :onload="loadImage" class="mx-auto h-32 w-32 flex-shrink-0 mix-blend-multiply" :src="product.image.url !== undefined && product.image.url !== null && product.image.url.length > 0 ? product.image.url : `https://api.dicebear.com/7.x/initials/svg?seed=${product.name}`" alt="" />
                     <h3 class="mt-6 text-sm font-medium text-gray-900">{{ product.name }}</h3>
                     <dl class="mt-1 flex flex-grow flex-col justify-between">
                        <dd class="text-sm text-gray-500">{{ product.manufacturer.name !== undefined && product.manufacturer.name !== null ? product.manufacturer.name : product.manufacturer }}</dd>
                     </dl>
                  </div>
                  </li>
               </ul>
               <div class="py-10">
                  <LoadingSpinnerHub v-if="loading" />
                  <div v-if="products.length > 0 && searcher.found.length === 0 && !loading && !queryLimitReached" class="flex justify-center mt-10">
                     <InfiniteLoading @infinite="onLoadMore('infLoading')" />
                  </div>
               </div>
            </div>
            </Transition>
         </div>
         </Transition>
         <!-- route -->
         <Transition name="fade">
         <div v-if="this.tabs[2].current">
            <div v-if="routes.length > 0 && !loadingRoutes">
               <div class="min-w-0 flex-1 xl:col-span-6 pr-0.5 lg:pr-10 xl:pr-0">
                  <div :class="{'flex items-center justify-between': true}">
                        <div :class="{'w-full flex': true, 'justify-between': true, 'flex-col space-y-4 lg:space-y-0 lg:flex-row': this.showStatusFilters}">
                           <div v-if="!this.showStatusFilters" class="w-2/3 sm:w-1/3 flex">
                              <label for="search" class="sr-only">Szukaj</label>
                              <div class="relative">
                                 <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                                    <SearchIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
                                 </div>
                                 <input :disabled="this.routeSearcher.locked" v-model="routeSearcher.phrase" id="search" name="search" :class="['block w-full rounded-md border border-gray-300 bg-white py-3.5 pl-10 pr-3 text-sm placeholder-gray-500 focus:border-blue-500 focus:text-gray-900 focus:placeholder-gray-400 focus:outline-none focus:ring-1 focus:ring-blue-500', this.routeSearcher.locked ? 'opacity-50' : '']" placeholder="Wyszukaj" type="search" />
                              </div>
                              <button :disabled="this.routeSearcher.locked" type="button" @click.prevent="searchRoutes" :class="['transition ease-in-out duration-300 ml-2 inline-flex items-center rounded border border-transparent bg-blue-100 px-2.5 py-1.5 text-xs font-medium text-blue-700', this.routeSearcher.locked || this.routeSearcher.phrase.length === 0 ? 'opacity-50 cursor-default' : 'hover:bg-blue-200 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2']">Szukaj</button>
                           </div>
                           <div v-if="showStatusFilters === true" class="mr-4 flex flex-col lg:flex-row w-full justify-between lg:items-center lg:space-x-3 space-y-4 lg:space-y-0 select-none">
                              <div class="flex flex-col lg:flex-row justify-center mt-2 lg:mt-0 lg:items-center lg:space-x-3 space-y-4 lg:space-y-0">
                                    <div class="flex flex-col lg:flex-row justify-center mt-2 lg:mt-0 lg:items-center lg:space-x-3 space-y-4 lg:space-y-0">
            
                                       <div class="flex items-center">
                                          <div :class="{'relative w-full rounded-md border border-gray-300 bg-white px-3 py-2 shadow-sm focus-within:border-indigo-600 focus-within:ring-1 focus-within:ring-indigo-600': true, 'opacity-50': pickedDateOfDelivery !== null || pickedDateOfDeliveryUnix !== null}">
                                                <label for="name" class="absolute -top-2 left-2 -mt-px inline-block bg-gradient-to-t from-white to-gray-100 px-1 text-xs font-medium text-gray-900">Data wyjazdu</label>
                                                <input :disabled="pickedDateOfDelivery !== null || pickedDateOfDeliveryUnix !== null" type="date" @change="pickDateOfDelivery($event)" v-model="pickedDateOfDelivery" ref="pickedDeliveryDate"  name="name" id="name" class="block w-full border-0 p-0 text-gray-900 placeholder-gray-500 focus:ring-0 text-sm" />
                                          </div>
                                          <Transition name="fade2">
                                          <div v-if="pickedDateOfDelivery !== null || pickedDateOfDeliveryUnix !== null" class="ml-2 mr-2">
                                                <span @click="resetDateOfCreate" class="transition ease-in-out duration-300 text-sm text-red-500 hover:text-red-700 cursor-pointer">&#10005;</span>
                                          </div>
                                          </Transition>
                                       </div>
                                       <div class="flex flex-col lg:flex-row lg:space-x-3 space-y-4 lg:space-y-0">
                                          <div class="relative rounded-md focus-within:border-indigo-600 focus-within:ring-1 focus-within:ring-indigo-600">
                                                <label for="name" class="absolute -top-2 left-2 -mt-px inline-block bg-gradient-to-t from-white to-gray-100 px-1 text-xs font-medium text-gray-900 rounded-t-md">Sortowanie</label>
                                                <select @change="changeSort()" v-model="sortName" class="block w-full pl-3 pr-10 py-2 text-sm border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 rounded-md">
                                                   <option value="creationDesc">Utworzono: Od najnowszych</option>
                                                   <option value="creationAsc">Utworzono: Od najstarszych</option>
                                                   <option value="returnDateAsc">Data powrotu: Od najwcześniejszych</option>
                                                   <option value="returnDateDesc">Data powrotu: Od najpóźniejszych</option>
                                                </select>
                                          </div>
                                       </div>
                                       <div class="flex flex-col lg:flex-row lg:space-x-3 space-y-4 lg:space-y-0">
                                          <div class="relative rounded-md focus-within:border-indigo-600 focus-within:ring-1 focus-within:ring-indigo-600">
                                                <label for="name" class="absolute -top-2 left-2 -mt-px inline-block bg-gradient-to-t from-white to-gray-100 px-1 text-xs font-medium text-gray-900 rounded-t-md">Kraj</label>
                                                <select v-model="pickedCountry" class="block w-full pl-3 pr-10 py-2 text-sm border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 rounded-md">
                                                   <option value="all">Wszystkie</option>
                                                   <option v-for="(country, index) in countries" :key="index" :value="country.name">{{country.displayName}}</option>
                                                </select>
                                          </div>
                                       </div>
                                       <button type="button" @click="this.pickStatus()" class="transition ease-in-out duration-300 inline-flex items-center justify-center rounded border border-transparent bg-blue-100 px-2.5 py-2 lg:py-1.5 text-xs font-medium text-blue-700 hover:bg-blue-200 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2">Zastosuj</button>
                                    </div>
                              </div>
                           </div>
                           <div v-if="routeSearcher.found.length === 0" :class="{'flex justify-between items-end lg:items-center lg:justify-start transition-all duration-1000 w-auto': true, 'min-w-filters': showStatusFilters}">
                              <div @click="showFilters()" class="mr-4 mt-1 cursor-pointer flex justify-center items-center flex-col select-none">
                                    <FilterIcon class="h-4 w-4 text-blue-500 flex-shrink-0" aria-hidden="true" />
                                    <span class="text-xs mt-1 font-medium text-blue-700">{{showStatusFilters ? 'Resetuj filtry' : 'Filtry'}}</span>
                              </div>
                           </div>
                        </div>
            
                  </div>

                  <div class="mt-4" v-if="routeSearcher.found.length > 0">
                        <span class="inline-flex items-center py-0.5 pl-2 pr-0.5 rounded-full text-xs font-medium bg-blue-100 text-blue-700">
                           Wynik wyszukiwania dla frazy: "{{routeSearcher.lastCall.phrase}}" (Znaleziono: {{routeSearcher.found.length}})
                           <button @click.prevent="resetSearchRoutes"  type="button" class="transition ease-in-out duration-300 flex-shrink-0 ml-0.5 h-4 w-4 rounded-full inline-flex items-center justify-center text-blue-400 hover:bg-blue-200 hover:text-blue-500 focus:outline-none focus:bg-blue-500 focus:text-white">
                              <svg class="h-2 w-2" stroke="currentColor" fill="none" viewBox="0 0 8 8">
                                    <path stroke-linecap="round" stroke-width="1.5" d="M1 1l6 6m0-6L1 7" />
                              </svg>
                           </button>
                        </span>          
                  </div>
               </div>
               <div class="mt-4 flex flex-col">
                  <div class="overflow-x-auto">
                     <div class="inline-block min-w-full py-2 align-middle">
                        <div class="overflow-hidden shadow border rounded-lg">
                           <table class="min-w-full divide-y divide-gray-300">
                              <thead class="bg-gray-50">
                                 <tr>
                                    <th scope="col" class="py-3.5 pl-4 pr-3 sm:pl-6 text-left text-sm font-semibold text-gray-600 uppercase">Kraj</th>
                                    <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-600 uppercase">Status</th>
                                    <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-600 uppercase">Trasa</th>
                                    <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-600 uppercase">Data wyjazdu</th>
                                    <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-600 uppercase">Data powrotu</th>
                                    <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-600 uppercase">Kierowcy</th>
                                    <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-600 uppercase">Załadunek</th>
                                    <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-600 uppercase">Generuj zbiorczo</th>
                                 </tr>
                              </thead>
                              <tbody class="divide-y divide-gray-200 bg-white">
                                 <tr v-for="(route, index) in routeSearcher.found.length > 0 ? routeSearcher.found : routes" :key="route.id">
                                    <td class="whitespace-nowrap px-3 py-4 pl-4 sm:pl-6 text-sm font-medium text-gray-900">
                                       <div class="inline-flex">
                                             <div v-if="route.country !== undefined">
                                                <img v-if="route.country.flagUrl.length>0" :src="route.country.flagUrl" v-tooltip="`${route.country.displayName}`" class="h-4 w-6 mr-1 border border-gray-300">
                                                <span v-if="route.country.flagUrl.length === 0">---</span>
                                             </div>
                                             <div v-else>
                                                <span>---</span>
                                             </div>
                                       </div>
                                    </td>
                                    <td class="whitespace-nowrap py-4 px-3 text-xs font-medium text-gray-900">
                                       <span class="inline-flex items-center rounded-md  px-2.5 py-0.5 font-medium " :class="{'bg-yellow-100 text-yellow-800': route.statusId === 0,'bg-yellow-100 text-yellow-800': route.statusId === 1, 'bg-blue-100 text-blue-800': route.statusId === 2, 'bg-green-100 text-green-800': route.statusId === 3}">
                                             {{ findStatus(route.statusId)?.name }}
                                       </span>
                                    </td>
                                    <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-900">
                                       <div class="flex gap-2 select-none">
                                          <TruckIcon class="w-7 h-7 text-gray-400 hover:text-gray-500 transition-all duration-300" v-tooltip="`Tablica rejestracyjna: ${route.vehicle.attribs.licensePlate.value} - Przestrzeń transportowa: ${route.vehicle.attribs.payloadCapacity.value} m3`" />
                                          <div class="inline-flex gap-2 items-center w-48">
                                             <span v-tooltip="`${route.title !== undefined && route.title.length > 0  ? route.title : (route.vehicle.brand + ' ' + route.vehicle.model + ' - ' + route.vehicle.attribs.licensePlate.value)}`" class="truncate font-semibold">{{route.title !== undefined && route.title.length > 0  ? route.title : (route.vehicle.brand + ' ' + route.vehicle.model + ' - ' + route.vehicle.attribs.licensePlate.value)}}</span>
                                          </div>
                                       </div>
                                    </td>
                                    <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-600">
                                       <span class="text-sm font-medium">{{ moment.unix(route.date.start.seconds || route.date.start._seconds).format("LL") }}</span>
                                    </td>
                                    <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-600">
                                       <span class="text-sm font-medium">{{ moment.unix(route.date.end.seconds || route.date.end._seconds).format("LL") }}</span>
                                    </td>
                                    <td class="whitespace-nowrap px-3 py-4 text-xs text-gray-400 hover:text-gray-600 transition-all duration-300">
                                       <div class="pl-6">
                                          <PrinterIcon v-tooltip="`Kliknij, aby wygenerować listę dla kierowców.`" @click.prevent="printLabel(route)" class="h-5 w-5 cursor-pointer" aria-hidden="true" />
                                       </div>
                                    </td>
                                    <td class="whitespace-nowrap px-3 py-4 text-xs text-gray-400 hover:text-gray-600 transition-all duration-300">
                                       <div class="pl-8">
                                          <PrinterIcon v-tooltip="`Kliknij, aby wygenerować listę załadunku.`" @click.prevent="printLoadPdf(route)" class="h-5 w-5 cursor-pointer" aria-hidden="true" />
                                       </div>
                                    </td>
                                    <td class="whitespace-nowrap px-3 py-4 text-xs text-gray-400 hover:text-gray-600 transition-all duration-300">
                                       <div>
                                          <button v-tooltip="`Kliknij, aby wygenerować zbiorczo etykiety.`" @click="printLabels(route)" type="button" :class="['w-full flex justify-center relative items-center gap-x-1.5 rounded-md bg-gray-100 px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-200 focus:z-10 trasition-all duration-300']">
                                          <DocumentDownloadIcon class="-ml-0.5 h-5 w-5 text-gray-500" aria-hidden="true" />
                                          Generuj
                                          </button>
                                       </div>
                                    </td>
                                    <td v-if="index == routes.length - 1 && !queryLimitReached" colspan="12">
                                       <div class="flex justify-center pt-4 pb-4">
                                          <InfiniteLoading v-tooltip="`W przypadku zatrzymania się ładowania, proszę nacisnąć kręcące się kółko.`" @click.prevent="loadMoreRoutes" v-if="!load && !queryLimitReached" @infinite="loadMoreRoutes()" />
                                       </div>
                                    </td>
                                 </tr>
                              </tbody>
                           </table>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
            <LoadingSpinnerHub v-if="loadingRoutes" />
         </div>
         </Transition>
         <EmptyState v-if="products.length === 0 && (routes.length === 0 && !loadingRoutes)" />
      </div>
   </div>
   <SelectTypeOfPdfPrint v-if="openPdfPrintTypeModal" @printClassic="printVariantClassic" @printWide="printVariantWide" @close="closePdfPrintTypeModal" />
   <SelectVariantModal v-if="openSelectVariant" :variants="variants" :orders="selectedOrder" @print="toggleTypeOfPrint" @close="closeVariantModal" />
   <Transition name="fade2">
      <Notification v-if="$store.state.notification.show" />
   </Transition>
   <Transition name="fade2">
      <Loader v-if="$store.state.globalLoader.show" :header="$store.state.globalLoader.head" :key="$store.state.globalLoader.head" :subheader="$store.state.globalLoader.subheader" />
   </Transition>
</template>
<script>

import {db} from "@/firebase/gfbconf.js";
import moment from 'moment';
import randomstring from 'randomstring';
import axios from 'axios';
import InfiniteLoading from "v3-infinite-loading";
import "v3-infinite-loading/lib/style.css";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

import { BanIcon, ViewGridIcon, ViewGridAddIcon, DocumentDuplicateIcon, SearchCircleIcon, MailIcon, PhoneIcon, CheckIcon, ChevronDownIcon, SearchIcon } from '@heroicons/vue/solid'
import { PlusCircleIcon, MinusCircleIcon, InformationCircleIcon, AnnotationIcon, EyeIcon, EyeOffIcon, DocumentDownloadIcon, PlusSmIcon, XIcon , FilterIcon, ArchiveIcon, PrinterIcon, TrashIcon, TruckIcon, DocumentAddIcon } from '@heroicons/vue/outline'
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue'

import SelectVariantModal from '../components/selectVariantModal.vue';
import EmptyState from '../components/EmptyState.vue';
import AlertModal from '../components/AlertModal.vue';
import Notification from '../components/POSNotification.vue';
import LoadingSpinnerHub from '../components/LoadingSpinnerHub.vue';
import TableRoutesList from '../components/TableRoutesList.vue';
import SelectTypeOfPdfPrint from '../components/SelectTypeOfPdfPrintModal.vue'
import Loader from '../components/Loader.vue';

export default {
   components: {
      Loader, BanIcon, DocumentAddIcon, PlusCircleIcon, MinusCircleIcon, InformationCircleIcon, AnnotationIcon, EyeIcon, EyeOffIcon, DocumentDownloadIcon, ChevronDownIcon, SelectTypeOfPdfPrint,ViewGridIcon, ViewGridAddIcon, TruckIcon, PlusSmIcon, XIcon , FilterIcon, ArchiveIcon, TableRoutesList, SearchIcon, TrashIcon, PrinterIcon, Menu, MenuButton, MenuItem, MenuItems, DocumentDuplicateIcon, SearchCircleIcon, SelectVariantModal, InfiniteLoading, LoadingSpinnerHub, Notification, MailIcon, PhoneIcon, CheckIcon, EmptyState, AlertModal
   },
   data() {
      return {
         load: false,
         printData: null,
         openPdfPrintTypeModal: false,
         blockPrint: false,
         searchBar: false,
         loading: false,
         pickedStatus: "all",
         pickedCountry: 'all',
         sortName: "creationDesc",
         sortType: {
            order: "desc",
            type: "meta.createdDate"
         },
         showStatusFilters: false,
         modal: {
            open: false
         },
         vehicles: {},
         routes:[],
         orders: [],
         statuses: [],
         products: [],
         variants: [],
         countries: [
            {
               displayName: 'Polska',
               name: 'Poland',
               code: 'PL',
               flagUrl: 'https://flagcdn.com/w320/pl.png',
            },
            {
               displayName: 'Niemcy',
               name: 'Germany',
               code: 'DE',
               flagUrl: 'https://flagcdn.com/w320/de.png',
            },
            {
               displayName: 'Francja',
               name: 'France',
               code: 'FR',
               flagUrl: 'https://flagcdn.com/w320/fr.png',
            },
            {
               displayName: 'Luksemburg',
               name: 'Luxembourg',
               code: 'LU',
               flagUrl: 'https://flagcdn.com/w320/lu.png',
            },
            {
               displayName: 'Włochy',
               name: 'Italy',
               code: 'IT',
               flagUrl: 'https://flagcdn.com/w320/it.png',
            },
            {
               displayName: 'Holandia',
               name: 'Netherlands',
               code: 'NL',
               flagUrl: 'https://flagcdn.com/w320/nl.png',
            },
            {
               displayName: 'Belgia',
               name: 'Belgium',
               code: 'BE',
               flagUrl: 'https://flagcdn.com/w320/be.png',
            },
            {
               displayName: 'Austria',
               name: 'Austria',
               code: 'AT',
               flagUrl: 'https://flagcdn.com/w320/at.png',
            },
         ],
         searcher: {
            found: [],
            locked: false,
            phrase: "",
            lastCall: null
         },
         routeSearcher: {
            found: [],
            locked: false,
            phrase: "",
            lastCall: null
         },
         lastLoaded: null,
         pickedDateOfDelivery: null,
         pickedDateOfDeliveryUnix: null,
         dbListener: null,
         query: null,
         queryLimit: 12,
         queryLimitRoutes: 10,
         queryLimitReached: false,
         type: 'all',
         openSelectVariant: false,
         selectedOrder: null,
         printAmount: 0,
         open: false,
         alreadySelected: true,
         selected: {
            all: false,
            suggested: false,
            route: false,
         },
         modalData: {
            headerText: "Drukowanie etykiet",
            bodyText: "Czy na pewno chcesz wydrukować wybrane etykiety?",
            acceptButtonText: "Akceptuj"
         },
         storage: [],
         segments: [],
         tabs: [
            { name: 'Wszystkie produkty', current: true,  icon: ViewGridIcon },
            { name: 'Sugerowane', current: false, icon: ViewGridAddIcon },
            { name: 'Trasy', current: false, icon: TruckIcon },
         ],
         suggestedTabs: [
            { name: 'Wybór produktów do druku', current: false, icon: ViewGridAddIcon },
            { name: 'Sugerowane produkty', current: false,  icon: ViewGridIcon },
            { name: '   ', current: false,  icon: ViewGridIcon },
         ],
         loadingRoutes: false,
         toggleInformation: {},
         modalDescritpion: {
            open: false,
            data: {
               headerText: "Dodatkowa informacja",
               bodyText: "Dodaj dodatkową informację do zamówienia.",
               acceptButtonText: "Dodaj"
            },
            manufacturerId: null,
            description: ""
         },
         modalSelectManufacturer: {
               open: false,
               variant: null,
               order: null
         },
         modal: {
               open: false,
               product: null,
               saving: false
         },
         productOrdersCart: {},
         loadedProducts: false,
         manufacturersToggleList: {},
         allManufacturers: [],
         productOrdersDescriptions: {},
         lists: [],
         blockBtn: false,
         selectedVariants: [],
         selectedList: null,
      }
   },
   methods: {
      selectProductToPrint(product)
      {
         const index = this.selectedVariants.findIndex(variant => variant.index === product.index);
         if (index !== -1) {
            // If the product is already selected, remove it
            product.accepted = false;
            this.selectedVariants.splice(index, 1);
         } else {
            // If the product is not selected, add it
            product.accepted = true;
            this.selectedVariants.push(product);
         }
      },
      getAllLists()
      {
         this.query = null;
         if(this.dbListener !== null){
            this.dbListener()
            this.dbListener = null
         }
         this.query = db.collection("Lists")

         this.dbListener = this.query
         .onSnapshot((querySnapshot) => {
            this.lists = [];
            if(querySnapshot.docs.length<this.queryLimitRoutes)
            {
               this.queryLimitReached = true;
            }
            else
            {
               this.queryLimitReached = false;
            }
            for(let i = 0; i < querySnapshot.docs.length; i++)
            {
               let data = querySnapshot.docs[i].data()
               let products = Object.entries(data.products);
               for(let y=0; y<products.length; y++)
               {
                  let variants = Object.values(products[y][1]);
                  for(let z=0; z<variants.length; z++)
                  {
                     if(variants[z].accepted == null)
                     {
                        variants[z].accepted = false;
                     } else if(variants[z].accepted)
                     {
                        delete variants[z];
                     }
                  };
               }
               for(let id in data.products)
               {
                  for(let variantId in data.products[id])
                  {
                     if(data.products[id][variantId].accepted)
                     {
                        delete data.products[id][variantId];
                        if(Object.keys(data.products[id]).length === 0)
                        {
                           delete data.products[id];
                           data.everythingPrinted = true;
                           this.$store.commit('setNotification', {
                              show: true,
                              head: `Produkty zostały już wydrukowane.`,
                              subheader: `Produkty z listy zostały już wydrukowane.`,
                              success: true
                           })
                        }
                     }
                  }
               };
               data.current = false;
               if(!data.everythingPrinted)
               {
                  this.lists.push(data)
               }
            }
         });
      },
      selectList(indx)
      {
         let copyLists = JSON.parse(JSON.stringify(this.lists[indx].products))
         this.productOrdersCart = {...copyLists};
         for(let id in this.productOrdersCart)
         {
            for(let variantId in this.productOrdersCart[id])
            {
               if(this.productOrdersCart[id][variantId].accepted)
               {
                  delete this.productOrdersCart[id][variantId];
                  if(Object.keys(this.productOrdersCart[id]).length === 0)
                  {
                     delete this.productOrdersCart[id];
                     this.$store.commit('setNotification', {
                        show: true,
                        head: `Produkty zostały już wydrukowane.`,
                        subheader: `Produkty z listy zostały już wydrukowane.`,
                        success: true
                     })
                        
                  }
               }
            }
         };

         this.selectedList = this.lists[indx].id;
      },
      async changeTab(indx)
      {
         this.blockBtn = true;
         if(this.dbListener !== null){
            this.dbListener();
         }
         this.clearData();
         for(let i=0; i<this.tabs.length; i++)
         {
         this.tabs[i].current = false;
         }
         this.tabs[indx].current = true;
         if(indx === 0)
         {
            await this.getAllProducts('all')
         }
         else if(indx === 1)
         {
            for(let i=0; i<this.suggestedTabs.length; i++)
            {
               this.suggestedTabs[i].current = false;
            }
            setTimeout(() => {
               this.changeSuggestedTab(0)
            }, 500);
         }
         else if(indx === 2)
         {
            this.loadingRoutes = true;
            await this.getVehicles()
            await this.getStatuses()
            await this.getRoutes();
         }
         setTimeout(() => {
            this.blockBtn = false;
         }, 1500);
      },
      async changeSuggestedTab(indx)
      {
         if(this.dbListener !== null){
            this.dbListener();
         }
         this.clearData();
         for(let i=0; i<this.suggestedTabs.length; i++)
         {
            this.suggestedTabs[i].current = false;
         }
         this.suggestedTabs[indx].current = true;
         if(indx === 0)
         {
            await this.getAllProducts('all')
            this.getAllLists();
         }
         else if(indx === 1)
         {
            await this.getAllProducts('suggested')
         }
      },
      checkIfDeleteFields(variant, id, order = "directly") {
         // if(this.productOrdersCart[manufacturerId][variant.id].orderIds[order] === 0) 
         //    delete this.productOrdersCart[manufacturerId][variant.id].orderIds[order]
         if(this.productOrdersCart[id][variant.id].orderQuantity === 0) 
            delete this.productOrdersCart[id][variant.id]
         if(Object.values(this.productOrdersCart[id]).length === 0) {
            delete this.productOrdersCart[id]
            this.createToggleList()
         }
      },
      decrementVariant(variant, id) {
         variant.item.printAmount--;
         this.productOrdersCart[id][variant.id].orderQuantity--
         // this.productOrdersCart[manufacturerId][variant.id].orderIds["directly"]--
         this.checkIfDeleteFields(variant, id)
      },
      createToggleList() {
         Object.keys(this.productOrdersCart).forEach(manufacturer => {
            this.manufacturersToggleList[manufacturer] = this.manufacturersToggleList[manufacturer] || true
         })
      },
      toggleManufacturerVariants(manufacturer) {
         this.manufacturersToggleList[manufacturer] = !this.manufacturersToggleList[manufacturer]
      },
      // async massPrinting() { 
      //    try {
      //       this.$store.commit('setGlobalLoader',{
      //          show: true,
      //          head: "Składanie zamówienia..",
      //          subheader: `Nie zamykaj tego okna.`,
      //       });

      //       setTimeout(() => {
      //          throw 'error';
      //       }, 4500);
      //    }
      //    catch (error) {
      //       console.log(error);
      //       this.$store.commit('resetGlobalLoader');
      //       this.$store.commit('setNotification',{
      //          show: true,
      //          head: "Nie udało się wydrukować etykiet.",
      //          subheader: ``,
      //          success: false
      //       });
      //    }
      // },
      addDescription() {
         this.productOrdersDescriptions[this.modalDescritpion.manufacturerId] = this.modalDescritpion.description
      },
      toggleDescriptionModal(manufacturerId) {
         if(this.modalDescritpion.open) {
            this.modalDescritpion.manufacturerId = null
            this.modalDescritpion.description = ""
            this.modalDescritpion.open = false
         } else {
            this.modalDescritpion.description = this.productOrdersDescriptions[manufacturerId] || ""
            this.modalDescritpion.manufacturerId = manufacturerId
            this.modalDescritpion.open = true
         }
      },
      findManufacturerById(manufacturerId) {
         if(!this.allManufacturers.length) {
            return manufacturerId
         }
         return this.allManufacturers.find(el => el.ref.id === manufacturerId).name
      },
      addVariantToList(variant) {
         const variantId = variant.item.id

         let str = variantId;
         let num = str.lastIndexOf("_");
         let prodId = str.slice(num+1, str.length);

         if(!this.productOrdersCart[prodId]) {
            this.productOrdersCart[prodId] = {}
         }
         if(!this.productOrdersCart[prodId][variantId]) {
            variant.item.printAmount = 1;
            this.productOrdersCart[prodId][variantId] = {
               id: variantId,
               variant: variant.item,
               orderQuantity: variant.orderQuantity,
               item: {
                  name: variant.item.name,
                  image: variant.item.image,
                  amountOfPackages: variant.item.amountOfPackages
               },
            }
         } else {
            this.productOrdersCart[prodId][variantId].variant.printAmount += variant.orderQuantity
            this.productOrdersCart[prodId][variantId].orderQuantity += variant.orderQuantity
         }
      },
      createPositionsList(prod) {
         const variants = Object.entries(prod.variants).map((arr) =>  { 
            return {
               item: arr[1],
               orderQuantity: 1
            }});
         return variants
      },
      openAdditionalInformation(indx,product)
      {
         if(this.toggleInformation[indx].open)
         {
               this.toggleInformation[indx].open = false
         }
         else
         {
               for (let i = 0; i < Object.keys(this.toggleInformation).length; i++) {
                  this.toggleInformation[i].open = false
               }
               this.toggleInformation[indx].open = true
         }
      },
      async openSelectManufacturerModal(prod)
      {
         try {
            //get variant's product
            const product = await db.collection("Products").doc(prod.id).get()
            if(product.exists) {
               this.modalSelectManufacturer.product = product.data()
            } else {
               return console.log("Can't find product")
            }
            this.modalSelectManufacturer.open = true
         }
         catch (error) {
            console.log(error);
         }
      },
      copyToClipboard(id)
      {
         navigator.clipboard.writeText(id).then(()=>
         {
            this.$store.commit('setNotification',{
               show: true,
               head: "Skopiowano do schowka!",
               subheader: ``,
               success: true
            }); 
         })
      },
      checkManufacturer(product) {
         //in order from orders this functionality is in 'checkExist' function
         if(typeof product.manufacturer === "string") {
               return false
         }
         return true
      },
      closePdfPrintTypeModal()
      {
         this.openPdfPrintTypeModal = false;
      },
      amountOfItemsForLabel(positions)
      {
         let posAm = 0;
         let items = 0;
         for (const pos in positions) {
            posAm = posAm +1
            items = items + positions[pos].product.quantity;
         }

         return `${posAm}`
      },
      createCommentList(order)
      {
         const products = Object.entries(order.positions)
         let translatedListOfComments = [];
         products.forEach(element => {
               if(element[0])
               {
                  if(element[1].infos.onOrderCreation.length > 0)
                  {
                     translatedListOfComments.push(element[1].infos.onOrderCreation);
                  }
               }
         })

         return translatedListOfComments
      },
      continueListening()
      {
         this.queryLimitRoutes = this.queryLimitRoutes + 10;
         this.getRoutes()
      },
      loadMoreRoutes()
      {
         this.dbListener();
         this.continueListening();      
      },
      async printLabels(route)
      {
         this.$store.commit('setNotification',{
            show: true,
            head: "Trwa generowanie zbiorczo etykiet.",
            subheader: `Prosimy o cierpliwość...`,
            success: true
         });

         var docDefinition = {
               pageSize: 'A6',
               pageMargins: [10, 35, 10, 0 ],
               content: [
               ]
         };

         let orders = [];
         for(let i=0; i<route.orders.length; i++)
         {
            let ordersRef = (await route.orders[i].ref.get()).data()
            orders.push(ordersRef);
         };

         for (let o=0; o<orders.length; o++) {
               let order = orders[o]
               let address = []
               if(order.delivery.address.length > 0){
                  address.push(order.delivery.address)
               }
               if(order.delivery.postCode.length > 0){
                  address.push(order.delivery.postCode)
               }
               if(order.delivery.city.length > 0){
                  address.push(order.delivery.city)
               }
               if(order.delivery.country.name.length > 0){
                  address.push(order.delivery.country.name)
               }
               moment.locale('pl');
               let date = moment().format('L LT')

               let packageIndex = 0
               for(let i = 0; i < Object.keys(order.positions).length; i++) {
                  let prod = (await order.positions[i+1].product.ref.get()).data();

                  for(let y = 0; y < prod.variants[order.positions[i+1].product.variantId].amountOfPackages; y++) {
                     let partName = 'BRAK NAZWY';
                     if(order.packages != null && order.packages.length > 0)
                     {
                        if(order.packages[packageIndex] != null)
                        {
                           partName = order.packages[packageIndex].id.slice(order.packages[packageIndex].id.indexOf(':')+1);
                        }
                     }

                     let template = [
                     {stack: [
                     {text: 'Zamówienie', fontSize: 14, bold:true, alignment: 'center'},
                     {text: `${order.id}`, fontSize: 12, bold:true, alignment: 'center'},
                     {text: '', margin: [0,4,0,4]},
                     {text: [{text: 'Produkt: ', bold:true},`${order.positions[i+1].id} z ${this.amountOfItemsForLabel(order.positions)}`], fontSize: 9, alignment: 'center'},
                     {text: '', margin: [0,9,0,9]},
                     {text: {text: 'Nazwa produktu: ', bold:true}, fontSize: 9, alignment: 'center'},
                     {text: '', margin: [0,1,0,1]},
                     {text: {text: `${order.positions[i+1].product.name.length > 0 ? order.positions[i+1].product.name.substring(0,40) + `${order.positions[i+1].product.name.length > 40 ? '...' : ''}` : '--'}`, bold:true}, fontSize: 10, alignment: 'center'},
                     {text: '', margin: [0,9,0,9]},
                     {text: 'Klient: ', bold:true, fontSize: 9, alignment: 'center'},
                     {text: '', margin: [0,1,0,1]},
                     {
                           table: {
                           widths: ['50%','50%'],
                           body: [
                              [
                                 {text: {text: 'Imię i nazwisko:', bold:true}, fontSize: 9, alignment: 'center', border: [false, false, false, false]},
                                 {text: {text: 'Firma: ', bold:true}, fontSize: 9, alignment: 'center', border: [false, false, false, false]},
                              ],
                              [
                                 {text: {text: `${order.client.name.length > 0 ? order.client.name.substring(0,25) + `${order.client.name.length > 25 ? '...' : ''}` : order.invoice.fullname.length > 0 ? order.invoice.fullname.substring(0,25) + `${order.invoice.fullname.length > 25 ? '...' : ''}` : 'Brak'}`}, fontSize: 7, alignment: 'center', border: [false, false, false, false]},
                                 {text: {text: `${order.client.company.length > 0 ? order.client.company.substring(0,25) + `${order.client.company.length > 25 ? '...' : ''}` : order.invoice.company.length > 0 ? order.invoice.company.substring(0,25) + `${order.invoice.company.length > 25 ? '...' : ''}` : order.delivery.company.length > 0 ? order.delivery.company.substring(0,25) + `${order.delivery.company.length > 25 ? '...' : ''}` : '--'}`}, fontSize: 7, alignment: 'center', border: [false, false, false, false]},
                              ]
                           ]}
                     },
                     {
                           table: {
                           widths: ['50%','50%'],
                           body: [
                              [
                                 {text: {text: 'E-mail:', bold:true}, fontSize: 9, alignment: 'center', border: [false, false, false, false]},
                                 {text: {text: 'Numer telefonu: ', bold:true}, fontSize: 9, alignment: 'center', border: [false, false, false, false]},
                              ],
                              [
                                 {text: {text: `${order.client.email.length > 0 ? order.client.email.substring(0,25) + `${order.client.email.length > 25 ? '...' : ''}` : 'Brak'}`}, fontSize: 7, alignment: 'center', border: [false, false, false, false]},
                                 {text: {text: `${order.client.phone.full.length > 0 ? order.client.phone.full : 'Brak'}`}, fontSize: 7, alignment: 'center', border: [false, false, false, false]},
                              ]
                           ]}
                     },
                     {text: '', margin: [0,9,0,9]},
                     {text: 'Dostawa: ', bold:true, fontSize: 9, alignment: 'center'},
                     {text: '', margin: [0,1,0,1]},
                     {text: {text: `${address.toString().substring(0,120)}${address.toString().length > 120 ? '...' : ''}`}, fontSize: 7, alignment: 'center', border: [false, false, false, false]},
                     {text: '', margin: [0,9,0,9]},
                     {
                           table: {
                           widths: ['50%','50%'],
                           body: [
                              [
                                    { qr: `${i+1}_${order.id}:${partName === 'BRAK NAZWY' ? order.positions[i+1].id+'_'+(y+1) : partName}`, fit: '115', version: 3,  alignment: 'center', border: [false, false, false, false]},
                                    {
                                       table: {
                                       widths: ['100%'],
                                       body: [
                                          [
                                                {text: {text: 'NAZWA PACZKI:', bold:true}, fontSize: 9, alignment: 'center', border: [false, false, false, false]},
                                          ],
                                          [
                                                {text: {text: `${partName === 'BRAK NAZWY' ? order.positions[i+1].id+'_'+(y+1) : partName}`, bold:true}, fontSize: 12, alignment: 'center', border: [false, false, false, false],margin: [0,0,0,6]},
                                          ],
                                          [
                                                {text: {text: 'Numer paczki:', bold:true}, fontSize: 9, alignment: 'center', border: [false, false, false, false]},
                                          ],
                                          [
                                                {text: {text: `${y+1} z ${prod.variants[order.positions[i+1].product.variantId].amountOfPackages}`, bold: true}, fontSize: 16, alignment: 'center', border: [false, false, false, false]},
                                          ]
                                       ]}, border: [false, false, false, false], margin: [0,0,0,0]
                                    },
                              ]
                           ]}
                     },
                     {text: '', margin: [0,12,0,12]},
                     {text: [{text: 'Wygenerowano: '},`${date}`], fontSize: 7, alignment: 'right', pageBreak: `${parseFloat(order.positions[i+1].id) === parseFloat(this.amountOfItemsForLabel(order.positions)) && parseFloat(y+1) === parseFloat(order.positions[i+1].product.amountOfPackages) ? '' : 'after'}`},
                     ],
                     unbreakable: true
                     }]
                     docDefinition.content.push(...template)
                     packageIndex++;
                  }
               }
         }
         pdfMake.createPdf(docDefinition).download(`Etykiety_zamówień_${route.title}.pdf`);
      },
      async printLabel(route)
      {
         this.$store.commit('setNotification',{
            show: true,
            head: "Trwa generowanie listy dla kierowców.",
            subheader: `Prosimy o cierpliwość...`,
            success: true
         });
         let drivers = []

         route.drivers.forEach(driver => {
               drivers.push(`${driver.name} ${driver.surname}`)
         })

         drivers = drivers.toString()
         drivers = drivers.replaceAll(',', ', \n')

         var dd = {
               pageSize: 'A4',
               content: [
                  {
                  columns: [
                     [
                     {
                           text: `Trasa #${route.id}`,
                           color: '#333333',
                           width: '*',
                           fontSize: 12,
                           bold: true,
                           alignment: 'center',
                           margin: [0, 0, 0, 10],
                     },
                     {
                           text: `${route.title}`,
                           color: '#333333',
                           width: '*',
                           fontSize: 16,
                           bold: true,
                           alignment: 'center',
                           margin: [0, 0, 0, 10],
                     }
                     ],
                  ],
                  },
                  {
                  columns: [
                     {
                     text: 'Data trasy',
                     color: '#aaaaab',
                     bold: true,
                     fontSize: 12,
                     alignment: 'left',
                     margin: [0, 15, 0, 2],
                     },
                     {
                     text: 'Kierowcy',
                     color: '#aaaaab',
                     bold: true,
                     fontSize: 12,
                     alignment: 'left',
                     margin: [0, 15, 0, 2],
                     },
                  ],
                  },
                  {
                  columns: [
                     {
                     text: `Od: ${moment.unix(route.date.start.seconds).format('DD.MM.YYYY')}r \nDo: ${moment.unix(route.date.end.seconds).format('DD.MM.YYYY')}r`,
                     bold: true,
                     fontSize: 10,
                     color: '#333333',
                     alignment: 'left',
                     },
                     {
                     text: `${drivers} \n ${route.vehicle.brand} ${route.vehicle.model}  |  ${route.vehicle.attribs.licensePlate.value}`,
                     bold: true,
                     fontSize: 10,
                     color: '#333333',
                     alignment: 'left',
                     },
                  ],
                  },
                  '\n',
                  {
                  layout: {
                     defaultBorder: false,
                     hLineWidth: function(i, node) {
                     return 1;
                     },
                     vLineWidth: function(i, node) {
                     return 1;
                     },
                     hLineColor: function(i, node) {
                     if (i === 1 || i === 0) {
                           return '#bfdde8';
                     }
                     return '#eaeaea';
                     },
                     vLineColor: function(i, node) {
                     return '#eaeaea';
                     },
                     hLineStyle: function(i, node) {
                     // if (i === 0 || i === node.table.body.length) {
                     return null;
                     //}
                     },
                     // vLineStyle: function (i, node) { return {dash: { length: 10, space: 4 }}; },
                     paddingLeft: function(i, node) {
                     return 10;
                     },
                     paddingRight: function(i, node) {
                     return 10;
                     },
                     paddingTop: function(i, node) {
                     return 2;
                     },
                     paddingBottom: function(i, node) {
                     return 2;
                     },
                     fillColor: function(rowIndex, node, columnIndex) {
                     return '#fff';
                     },
                  },
                  table: {
                     headerRows: 1,
                     widths: [1,50,50,50,'*',20,50],
                     body: [
                     [
                           {
                           text: 'Lp.',
                           border: [false, true, true, true],
                           alignment: 'center',
                           fillColor: '#eaf2f5',
                           margin: [-9, 14, -9, 5],
                           textTransform: 'uppercase',
                           fontSize: 8,
                           },
                           {
                           text: 'Imie, nazwisko',
                           fillColor: '#eaf2f5',
                           alignment: 'center',
                           border: [true, true, true, true],
                           margin: [-9, 14, -9, 5],
                           textTransform: 'uppercase',
                           fontSize: 8,
                           },
                           {
                           text: 'Adres dostawy',
                           fillColor: '#eaf2f5',
                           alignment: 'center',
                           border: [true, true, true, true],
                           margin: [-9, 14, -9, 5],
                           textTransform: 'uppercase',
                           fontSize: 8,
                           },
                           {
                           text: 'Telefon kontaktowy',
                           border: [true, true, true, true],
                           alignment: 'center',
                           fillColor: '#eaf2f5',
                           margin: [-9, 9, -9, 5],
                           textTransform: 'uppercase',
                           fontSize: 8,
                           },
                           {
                           text: 'Lista zamówień',
                           border: [true, true, true, true],
                           alignment: 'center',
                           fillColor: '#eaf2f5',
                           margin: [-9, 14, -9, 5],
                           textTransform: 'uppercase',
                           fontSize: 8,
                           },
                           {
                           text: 'Łączna ilość paczek',
                           border: [true, true, true, true],
                           alignment: 'center',
                           fillColor: '#eaf2f5',
                           margin: [-9, 5, -9, 5],
                           textTransform: 'uppercase',
                           fontSize: 8,
                           },
                           {
                           text: 'Więcej informacji',
                           border: [true, true, true, true],
                           alignment: 'center',
                           fillColor: '#eaf2f5',
                           margin: [-9, 14, -9, 5],
                           textTransform: 'uppercase',
                           fontSize: 8,
                           }
                     ],

                     ],
                  },
                  },
               ],
               styles: {
                  },
                  
               defaultStyle: {
                  columnGap: 20,
               },
         };

         route.orders.sort((a,b) => a.index - b.index)
         // for(let i = route.orders.length - 1; i >= 0; i--)
         for(let i = 0; i < route.orders.length; i++)
         {
               let ordersRef = (await route.orders[i].ref.get()).data()
               // route.orders[i] = (await route.orders[i].get()).data()

               let orders = []
               let totalPackages = 0
               let products = ""
               let variantName = '';

               const positions = Object.values(ordersRef.positions)

               for(let y=0; y<positions.length; y++)
               {
                  totalPackages += positions[y].product.amountOfPackages

                  let variantId = positions[y].product.variantId
                  let product = (await positions[y].product.ref.get()).data()

                  if(product !== undefined)
                  {
                     products = products.concat(`${positions[y].product.quantity}x ${product.name} \n (${product.variants[variantId] !== undefined ? product.variants[variantId].name : 'WYBRANY WARIANT ZOSTAŁ USUNIĘTY Z PRODUKTU W SYSTEMIE'}) \n`)
                  }
                  else
                  {
                     variantName += `${positions[y].product.quantity}x ${positions[y].product.name} \n`
                  }
               }

               for(let y = 0; y < positions.length; y++ )
               {
                  orders.push(positions[y].product)
               }

               let address = []

               if(ordersRef.delivery.address.length > 0)
               {
                  address.push(ordersRef.delivery.address)
               }
               if(ordersRef.delivery.postCode.length > 0)
               {
                  address.push(ordersRef.delivery.postCode)
               }

               if(ordersRef.delivery.city.length > 0)
               {
                  address.push(ordersRef.delivery.city)
               }
               if(ordersRef.delivery.country.name.length > 0)
               {
                  address.push(ordersRef.delivery.country.name)
               }

               address = address.toString()
               address = address.replaceAll(',', ', \n')

               moment.locale('pl');

               let template = [{
                  layout: {
                     defaultBorder: false,
                     hLineWidth: function(i, node) {
                     return 1;
                     },
                     vLineWidth: function(i, node) {
                     return 1;
                     },
                     hLineColor: function(i, node) {
                     if (i === 1 || i === 0) {
                           return '#bfdde8';
                     }
                     return '#eaeaea';
                     },
                     vLineColor: function(i, node) {
                     return '#eaeaea';
                     },
                     hLineStyle: function(i, node) {
                     // if (i === 0 || i === node.table.body.length) {
                     return null;
                     //}
                     },
                     // vLineStyle: function (i, node) { return {dash: { length: 10, space: 4 }}; },
                     paddingLeft: function(i, node) {
                     return 10;
                     },
                     paddingRight: function(i, node) {
                     return 10;
                     },
                     paddingTop: function(i, node) {
                     return 2;
                     },
                     paddingBottom: function(i, node) {
                     return 2;
                     },
                     fillColor: function(rowIndex, node, columnIndex) {
                           return '#fff';
                     },
                  },
                  table: {
                     headerRows: 1,
                     widths: [1,50,50,50,'*',20,50],
                     body: [
                           [
                              {
                              text: `${i+1}`,
                              border: [false, true, true, false],
                              margin: [-9, 5, -5, 5],
                              alignment: 'right',
                              fontSize: 8,
                              },
                              {
                              border: [true, true, true, false],
                              text: `${ordersRef.delivery.name}`,
                              fillColor: '#f5f5f5',
                              margin: [-9, 5, -9, 5],
                              alignment: 'center',
                              fontSize: 8,
                              },
                              {
                              border: [true, true, true, false],
                              text: `${address}`,
                              fillColor: '#f5f5f5',
                              margin: [-9, 5, -9, 5],
                              alignment: 'center',
                              fontSize: 8,
                              },
                              {
                              border: [true, true, true, false],
                              text: `${ordersRef.delivery.phone.full}`,
                              fillColor: '#f5f5f5',
                              margin: [-9, 5, -9, 5],
                              alignment: 'center',
                              fontSize: 8,
                              },
                              {
                              border: [true, true, true, false],
                              text: `${products + variantName}`,
                              //   text: `${positions[i].product.name === undefined ? '?' : positions[i].product.name } szt.${positions[i].product.quantity} p.${positions[i].product.amountOfPackages}`,
                              fillColor: '#f5f5f5',
                              margin: [-9, 5, -9, 5],
                              alignment: 'center',
                              fontSize: 8,
                              },
                              {
                              border: [true, true, true, false],
                              text: `${totalPackages}`,
                              fillColor: '#f5f5f5',
                              margin: [-9, 5, -9, 5],
                              alignment: 'center',
                              fontSize: 8,
                              },
                              {
                                 alignment: 'center',
                                 qr: `${ordersRef.id}`,
                                 fit: '60',
                                 width: "*",
                                 // foreground: "#293980",
                                 fillColor: '#f5f5f5',
                                 margin: [0, 5, 0, 5],
                                 border: [true, true, true, false]
                              },
                           ],
                     ],
                  },
               },
               {  
                  table: {
                     widths: ['*'],
                     body: [
                     ]
                  }
               },
               ]
               template[1].table.body.push([
                  {
                  text: `Komentarz wewnętrzny: ${this.createCommentList(ordersRef).length !== 0 ? this.createCommentList(ordersRef) : '---'}`,
                  alignment: 'center',
                  fontSize: 8,
                  bold: true
                  }
               ], )
               template[1].table.body.push([
                  {
                  text: `Komentarz zamówienia w trasie: ${ordersRef.description !== undefined ? ordersRef.description : '---'}`,
                  alignment: 'center',
                  fontSize: 8,
                  },
               ]
               )
               dd.content.push(...template)
         }
         pdfMake.createPdf(dd).download(`trasa_${route.title !== undefined ? route.title : route.vehicle.brand + ' ' + route.vehicle.model}.pdf`);
      },
      async printLoadPdf(route)
      {
         this.$store.commit('setNotification',{
            show: true,
            head: "Trwa generowanie listy załadunku.",
            subheader: `Prosimy o cierpliwość...`,
            success: true
         });
         route.orders.sort((a,b) => a.index - b.index)

         var dd = {
               pageSize: 'A4',
               content: [
                  {
                     columns: [
                        [
                           {
                              text: `${route.title}`,
                              color: '#333333',
                              width: '*',
                              fontSize: 16,
                              bold: true,
                              alignment: 'center',
                              margin: [0, 0, 0, 10],
                           },
                           {
                              text: `${route.vehicle.brand + ' ' + route.vehicle.model} | ${route.vehicle.attribs.licensePlate.value}`,
                              color: '#333333',
                              width: '*',
                              fontSize: 12,
                              bold: true,
                              alignment: 'center',
                              margin: [0, 0, 0, 10],
                           }
                        ],
                     ],
                     },
                  '\n',
                  {
                  layout: {
                     defaultBorder: false,
                     hLineWidth: function(i, node) {
                     return 1;
                     },
                     vLineWidth: function(i, node) {
                     return 1;
                     },
                     hLineColor: function(i, node) {
                     if (i === 1 || i === 0) {
                           return '#bfdde8';
                     }
                     return '#eaeaea';
                     },
                     vLineColor: function(i, node) {
                     return '#eaeaea';
                     },
                     hLineStyle: function(i, node) {
                     // if (i === 0 || i === node.table.body.length) {
                     return null;
                     //}
                     },
                     // vLineStyle: function (i, node) { return {dash: { length: 10, space: 4 }}; },
                     paddingLeft: function(i, node) {
                     return 10;
                     },
                     paddingRight: function(i, node) {
                     return 10;
                     },
                     paddingTop: function(i, node) {
                     return 2;
                     },
                     paddingBottom: function(i, node) {
                     return 2;
                     },
                     fillColor: function(rowIndex, node, columnIndex) {
                     return '#fff';
                     },
                  },
                  table: {
                     headerRows: 1,
                     widths: ['4%', '*', '20%', "7%", "13%"],
                     body: [
                     [
                           {
                           text: 'Lp.',
                           border: [true, true, true, true],
                           alignment: 'center',
                           margin: [0, 7, 0, 7],
                           fillColor: '#F4F4F4',
                           textTransform: 'uppercase',
                           fontSize: 10,
                           bold: true
                           },
                           {
                           text: 'Nazwa mebla',
                           border: [true, true, true, true],
                           alignment: 'center',
                           margin: [0, 7, 0, 7],
                           fillColor: '#F4F4F4',
                           textTransform: 'uppercase',
                           fontSize: 10,
                           bold: true
                           },
                           {
                           text: 'Klient',
                           border: [true, true, true, true],
                           alignment: 'center',
                           margin: [0, 7, 0, 7],
                           fillColor: '#F4F4F4',
                           textTransform: 'uppercase',
                           fontSize: 10,
                           bold: true
                           },
                           {
                           text: 'L. pacz.',
                           border: [true, true, true, true],
                           alignment: 'center',
                           margin: [-4, 7, -4, 7],
                           fillColor: '#F4F4F4',
                           textTransform: 'uppercase',
                           fontSize: 10,
                           bold: true
                           },
                           {
                           text: 'Dostawa',
                           border: [true, true, true, true],
                           alignment: 'center',
                           margin: [-4, 7, -4, 7],
                           fillColor: '#F4F4F4',
                           textTransform: 'uppercase',
                           fontSize: 10,
                           bold: true
                           },
                     ],

                     ],
                  },
                  },
               ],
               styles: {
                  },
                  
               defaultStyle: {
                  columnGap: 20,
               },
         };


         for(let i = route.orders.length - 1; i >= 0; i--)
         {
               let ordersRef = (await route.orders[i].ref.get()).data()

               let products = ""
               let totalPackages = 0
               let variantName = '';

               const positions = Object.values(ordersRef.positions)
   
               for(let y=0; y<positions.length; y++)
               {
                  totalPackages += positions[y].product.amountOfPackages
                  let variantId = positions[y].product.variantId
                  let product = (await positions[y].product.ref.get()).data()
                  if(product !== undefined)
                  {
                     products = products.concat(`${positions[y].product.quantity}x ${product.name} \n (${product.variants[variantId] !== undefined ? product.variants[variantId].name : 'WYBRANY WARIANT ZOSTAŁ USUNIĘTY Z PRODUKTU W SYSTEMIE'}) \n`)
                  }
                  else
                  {
                     variantName += `${positions[y].product.quantity}x ${positions[y].product.name} \n`
                  }
               }
               
               let template = [{
                  layout: {
                     defaultBorder: false,
                     hLineWidth: function(i, node) {
                     return 1;
                     },
                     vLineWidth: function(i, node) {
                     return 1;
                     },
                     hLineColor: function(i, node) {
                     if (i === 1 || i === 0) {
                           return '#bfdde8';
                     }
                     return '#eaeaea';
                     },
                     vLineColor: function(i, node) {
                     return '#eaeaea';
                     },
                     hLineStyle: function(i, node) {
                     // if (i === 0 || i === node.table.body.length) {
                     return null;
                     //}
                     },
                     // vLineStyle: function (i, node) { return {dash: { length: 10, space: 4 }}; },
                     paddingLeft: function(i, node) {
                     return 10;
                     },
                     paddingRight: function(i, node) {
                     return 10;
                     },
                     paddingTop: function(i, node) {
                     return 2;
                     },
                     paddingBottom: function(i, node) {
                     return 2;
                     },
                     fillColor: function(rowIndex, node, columnIndex) {
                           return '#fff';
                     },
                  },
                  table: {
                     headerRows: 1,
                     widths: ['4%', '*', '20%', "7%", "13%"],
                     body: [
                           [
                              {
                              text: `${route.orders.length-i}`,
                              border: [false, true, true, false],
                              margin: [-9, 5, -5, 5],
                              alignment: 'right',
                              fontSize: 10,
                              },
                              {
                              border: [true, true, true, false],
                              text: `${products + variantName}`,
                              fillColor: '#f5f5f5',
                              margin: [-9, 5, -9, 5],
                              alignment: 'center',
                              fontSize: 10,
                              },
                              {
                              border: [true, true, true, false],
                              text: `${ordersRef.client.name}`,
                              fillColor: '#f5f5f5',
                              margin: [-9, 5, -9, 5],
                              alignment: 'center',
                              fontSize: 10,
                              },
                              {
                              border: [true, true, true, false],
                              text: `${totalPackages}`,
                              fillColor: '#f5f5f5',
                              margin: [-9, 5, -9, 5],
                              alignment: 'center',
                              fontSize: 10,
                              },
                              {
                              border: [true, true, true, false],
                              text: `${ordersRef.delivery.date.confirmed !== null ? ordersRef.delivery.date.confirmed.asText : ordersRef.delivery.date.suggested !== null ? ordersRef.delivery.date.suggested.asText : 'Nie wskazano'}`,
                              fillColor: '#f5f5f5',
                              margin: [-9, 5, -9, 5],
                              alignment: 'center',
                              fontSize: 10,
                              },
                           ],
                     ],
                  },
               },
               { 
                  layout: {
                     defaultBorder: false,
                     hLineWidth: function(i, node) {
                     return 1;
                     },
                     vLineWidth: function(i, node) {
                     return 1;
                     },
                     hLineColor: function(i, node) {
                     if (i === 1 || i === 0) {
                           return '#bfdde8';
                     }
                     return '#eaeaea';
                     },
                     vLineColor: function(i, node) {
                     return '#eaeaea';
                     },
                     hLineStyle: function(i, node) {
                     // if (i === 0 || i === node.table.body.length) {
                     return null;
                     //}
                     },
                     // vLineStyle: function (i, node) { return {dash: { length: 10, space: 4 }}; },
                     paddingLeft: function(i, node) {
                     return 10;
                     },
                     paddingRight: function(i, node) {
                     return 10;
                     },
                     paddingTop: function(i, node) {
                     return 2;
                     },
                     paddingBottom: function(i, node) {
                     return 2;
                     },
                     fillColor: function(rowIndex, node, columnIndex) {
                           return '#fff';
                     },
                  }, 
                  table: {
                     widths: ['*'],
                     body: [
                     ]
                  }
               },
               ]
               template[1].table.body.push([
                  {
                  text: `Komentarz wewnętrzny: ${this.createCommentList(ordersRef).length !== 0 ? this.createCommentList(ordersRef) : '---'}`,
                  alignment: 'center',
                  fontSize: 8,
                  bold: true,
                  }
               ], )
               template[1].table.body.push([
                  {
                  text: `Komentarz zamówienia w trasie: ${ordersRef.description !== undefined ? ordersRef.description : '---'}`,
                  alignment: 'center',
                  fontSize: 8,
                  },
               ])
               template[1].table.body.push([
                  {
                  text: `Lokalizacja produktu: ${(await this.createLocationInOrderProduct(ordersRef))}`,
                  alignment: 'center',
                  fontSize: 8,
                  }
               ])
               dd.content.push(...template)
         };

         pdfMake.createPdf(dd).download(`załadunek_${route.title !== undefined ? route.title : route.vehicle.brand + ' ' + route.vehicle.model}.pdf`);
      },
      openSearch()
      {
         this.searchBar = !this.searchBar
      },
      getStatusById(id)
      {
         let currentStatus = {
               name: "Brak",
               info: "Brak",
               id: null
         }
         this.statuses.forEach((status) => {
            if(status.id === id){
               currentStatus = {
                  name: status.name,
                  info: status.info,
                  id: status.id
               }
            }
         })
         return currentStatus
      },
      resetDateOfCreate()
      {
         this.pickedDateOfDelivery = null;
         this.pickedDateOfDeliveryUnix = null
      },
      pickDateOfDelivery(event)
      {
         let mDate = moment(event.target.value)
         if(!mDate.isValid())
         {
            this.$store.commit('setNotification',{
               show: true,
               head: "Popraw datę!",
               subheader: `Wykryto, że data jest niepoprawna.`,
               success: false
            });
            this.pickedDateOfDelivery = null;
            this.pickedDateOfDeliveryUnix = null
         }
         else
         {
            this.pickedDateOfDeliveryUnix = mDate.unix()
         }
      },
      async getStatuses()
      {
         let res = await db.collection("RouteStatuses").doc("StatusList").get()
         this.statuses = res.data().list
      },
      async getVehicles()
      {
         let vehicleData = await db.collection('Vehicles').get()
         for(let i = 0; i < vehicleData.docs.length; i++)
         {
            const vehicle = vehicleData.docs[i];
            const vehicleToPush = vehicle.data()
            this.vehicles[vehicleToPush.id] = vehicleToPush
         }
      },
      async createLocationInOrderProduct(order)
      {
         let arrayOfCreatedLocations = [];
         const orderPositions = Object.entries(order.positions)
         let isFound = false;
         for(let i=0; i<orderPositions.length; i++)
         {
            if(orderPositions[i][0])
            {
               for(let y=0; y<this.segments.length; y++)
               {
                  const products = Object.entries(this.segments[y].products)
                  for(let p=0; p<products.length; p++)
                  {
                     if(orderPositions[i][1].product.id === products[p][0])
                     {
                        const variant = Object.entries(products[p][1])
                        for(let v=0;v<variant.length; v++)
                        {
                           variant[v][1].warehouse = this.segments[y].warehouse;
                           let str = variant[v][0];
                           let n = str.lastIndexOf('_');
                           let result = str.slice(0, n);
                           if(result === orderPositions[i][1].product.variantId)
                           {
                              isFound = true;
                              arrayOfCreatedLocations.push(`Paczka ${variant[v][1].part !== undefined ? variant[v][1].part : 'BRAK'}: Magazyn ${variant[v][1].warehouse !== undefined ? variant[v][1].warehouse.name : 'BRAK'} ${variant[v][1].segment !== undefined ? variant[v][1].segment.name : 'BRAK' }`,'\n' )
                           }
                        }
                     }
                  }
               }
            }
         }
         if(!isFound)
         {
            arrayOfCreatedLocations.push('---')
         }
         
         return arrayOfCreatedLocations
      },
      async getAllStorageLocations()
      {
         try {
            const storageElements = await db.collection('Storage').orderBy("meta.created.unix", "desc").get()
            storageElements.forEach(doc => {
               let element = doc.data();
               element.selected = false;
               element.block = false;
               this.storage.push(element)
            });

            for(let i=0; i<this.storage.length; i++)
            {
               const res = await db.collection('Storage').doc(this.storage[i].id.toString()).collection('Segments').get()
               for(let y=0; y<res.docs.length; y++)
               {
                  let current = res.docs[y].data();
                  current.warehouse = this.storage[i];
                  this.segments.push(current)
               };
            }
         } catch (error) {
            console.log(error);
         }
      },
      changeSort()
      {
         if(this.sortName === "creationAsc")
         {
            this.sortType = {
               order: "asc",
               type: "meta.createdDate"
            }
         }
         if(this.sortName === "creationDesc")
         {
            this.sortType = {
               order: "desc",
               type: "meta.createdDate"
            }
         }
         if(this.sortName === "returnDateAsc")
         {
            this.sortType = {
               order: "asc",
               type: "date.end"
            }
         }
         if(this.sortName === "returnDateDesc")
         {
            this.sortType = {
               order: "desc",
               type: "date.end"
            }
         }
      },
      showFilters()
      {
         this.showStatusFilters = !this.showStatusFilters
         if(!this.showStatusFilters)
         {
               this.pickedStatus = 'all';
               this.pickedCountry = 'all';
               this.sortName = "creationDesc";
               this.sortType = {
                  order: "desc",
                  type: "meta.createdDate"
               },
               this.pickedDateOfDelivery = null;
               this.pickedDateOfDeliveryUnix = null;
               this.pickStatus()
         }
      },
      pickStatus(){
         this.dbListener()
         this.getRoutes()
      },
      createDeliveryPrice(route)
      {
         let translatedListOfDelivery = [];
         if(route.value !== undefined)
         {
               if(route.value.delivery !== undefined)
               {
                  if(Object.keys(route.value.delivery).length > 0)
                  {
                     const values = Object.entries(route.value.delivery)
                     values.forEach(element => {
                        if(element[0])
                        {
                           translatedListOfDelivery.push(parseFloat(element[1].price).toFixed(2) + ' ' + element[0]);
                        }
                     })
                  }
                  else
                  {
                     translatedListOfDelivery.push('---'); 
                  }
               }
               else
               {
                  translatedListOfDelivery.push('---');
               }
         }
         else
         {
               translatedListOfDelivery.push('---');
         }
         return translatedListOfDelivery
      },
      getValues(route)
      {
         let translatedListOfValues = [];
         if(route.value !== undefined)
         {
               if(route.value.total !== undefined)
               {
                  const values = Object.entries(route.value.total)
                  values.forEach(element => {
                     if(element[0])
                     {
                           if(element[1].value !== 0)
                           {
                              translatedListOfValues.push(parseFloat(element[1].value).toFixed(2) + ' ' + element[0]);
                           }
                     }
                  })
               }
               else
               {
                  translatedListOfValues.push('Brak wartości trasy.');
               }
         }
         else
         {
               translatedListOfValues.push('Brak wartości trasy.');
         }
         return translatedListOfValues
      },
      findStatus(statusId)
      {
         return this.statuses.find(el => el.id === statusId)
      },
      async getRoutes()
      {
         this.load = true;
         this.query = null;
         if(this.dbListener !== null){
               this.dbListener()
               this.dbListener = null
         }
         this.query = db.collection("Routes").where("statusId", "in", [1])

         if(this.pickedCountry !== 'all')
         {
            this.query = this.query.where('country.name', "==", this.pickedCountry)
         }

         if(this.pickedDateOfDeliveryUnix !== null){
            this.pickedDateOfDeliveryUnix = parseInt(this.pickedDateOfDeliveryUnix)
            let startOfDayDelivery = this.pickedDateOfDeliveryUnix
            this.query = this.query.where('date.start', "==", moment.unix(startOfDayDelivery).toDate())
         }

         this.query = this.query.orderBy(`${this.sortType.type}`, `${this.sortType.order}`)

         this.dbListener = this.query.limit(this.queryLimitRoutes)
         .onSnapshot((querySnapshot) => {
            this.routes = [];
            if(querySnapshot.docs.length<this.queryLimitRoutes)
            {
               this.queryLimitReached = true;
            }
            else
            {
               this.queryLimitReached = false;
            }
            for (let i = 0; i < querySnapshot.docs.length; i++) {
               const data = querySnapshot.docs[i].data()
               data.vehicle = this.vehicles[data.vehicle.id]
               this.routes.push(data)
            }
            this.load = false;
            this.loadingRoutes = false;
         });
      },
      async search()
      {
         if(this.searcher.phrase.length > 0)
         {
            this.searcher.locked = true;
            const res = await axios.post(`${this.$store.state.apiLink}/search`, {
               index: "jan-art-products",
               collection: "Products",
               phrase: this.searcher.phrase,
               searchFields: ['names','models','skus','eans','id','categories','manufacturers','variantsIds','baselinkerIds']

            })
            if(res.data.success)
            {
               this.searcher.found = res.data.found;
               if(res.data.found.length > 0)
               {
                     this.searcher.lastCall = res.data.data;
                     // this.dbListener();
                     this.$store.commit('setNotification',{
                        show: true,
                        head: "Wyszukiwanie zakończone!",
                        subheader: `Znaleziono ${res.data.found.length} produktów.`,
                        success: true
                     });
                     for(let i=0; i<this.searcher.found.length; i++)
                     {
                        this.toggleInformation[i] = {
                           open: false,
                        }
                     }
               }else{
                     this.$store.commit('setNotification',{
                        show: true,
                        head: "Nie znaleziono wyników!",
                        subheader: `Spróbuj wyszukać inną frazę.`,
                        success: false
                     });
               }
            }
            else
            {
               this.$store.commit('setNotification',{
                     show: true,
                     head: "Wyszukiwanie zakończone niepowodzeniem!",
                     subheader: `Coś poszło nie tak...`,
                     success: false
               });
            }
            setTimeout(()=>
            {
               // this.searcher.phrase = "";
               this.searcher.locked = false;
            }, 3000)
         }
         else
         {
            return;
         }
      },
      async searchRoutes()
      {
         if(this.routeSearcher.phrase.length > 0)
         {
               this.routeSearcher.locked = true;
               const res = await axios.post(`${this.$store.state.apiLink}/search`, {
                  index: "jan-art-routes",
                  collection: "Routes",
                  phrase: this.routeSearcher.phrase,
                  searchFields: ['names', 'countries', 'brands', 'models', 'licensePlates', 'id']
               })
               if(res.data.success)
               {
                  let allFound = []
                  res.data.found.forEach((found) => {
                     let current = found
                     current.vehicle = this.vehicles[current.vehicle._path.segments[1]]
                     allFound.push(current);
                  });
                  this.routeSearcher.found = allFound;
                  if(res.data.found.length > 0)
                  {
                     this.routeSearcher.lastCall = res.data.data;
                     this.dbListener();
                     this.$store.commit('setNotification',{
                           show: true,
                           head: "Wyszukiwanie zakończone!",
                           subheader: `Znaleziono ${res.data.found.length} zamówień.`,
                           success: true
                     });

                  }else{
                     this.$store.commit('setNotification',{
                           show: true,
                           head: "Nie znaleziono wyników!",
                           subheader: `Spróbuj wyszukać inną frazę.`,
                           success: false
                     });
                  }
               }
               else
               {
                  this.$store.commit('setNotification',{
                     show: true,
                     head: "Wyszukiwanie zakończone niepowodzeniem!",
                     subheader: `Coś poszło nie tak...`,
                     success: false
                  });
               }
               setTimeout(()=>
               {
                  this.routeSearcher.locked = false;
               }, 3000)
         }
         else
         {
               return;
         }
      },
      loadImage()
      {
         this.loading = false;
      },
      async toggleTypeOfPrint(val, type)
      {
         if(type === 'cart')
         {
            let variants = [];
            const products = Object.entries(val);
            for(let i=0; i<products.length; i++)
            {
               let current = products[i][1];
               let prod = (await db.collection('Products').doc(products[i][1].item.id).get()).data();
               current.data = prod.variants[current.item.variantId];
               variants.push(current)
            }
            let print = {
               name: 'cart',
               variants: variants,
               list: this.selectedList
            }
            this.printData = print;
            this.selectedVariants = [];
            this.productOrdersCart = {};
            this.selectedList = null;
            this.openPdfPrintTypeModal = true;
         }
         else if(type === 'cartMassive')
         {
            let variants = [];
            const products = Object.entries(val)
            for(let i=0; i<products.length; i++)
            {
               const productVariant = Object.entries(products[i][1]);
               for(let y=0; y<productVariant.length; y++)
               {
                  variants.push(productVariant[y][1].variant);
               }
            }
            let print = {
               name: 'withoutCart',
               variants: variants,
            }
            this.printData = print;
            this.openPdfPrintTypeModal = true;
         }
         else if(type === 'cancel')
         {
            this.printData = null;
            this.selectedVariants = [];
            this.productOrdersCart = {};
            this.selectedList = null;
         }
         else
         {
            this.printData = val;
            this.openPdfPrintTypeModal = true;
         }
      },
      async printVariantWide()
      {
         if(this.printData === null) return;
         let val = this.printData;
         this.openPdfPrintTypeModal = false;
         this.$store.commit('setGlobalLoader',{
            show: true,
            head: "Trwa generowanie etykiet dla listy PZ/WZ.",
            subheader: `Nie zamykaj tego okna.`,
         });
         this.blockPrint = true;
         let docDefinition = {
            info: {
               author: 'Complaia Systems',
            },
            pageMargins: [ 1, 5, 6, 1 ],
            permissions: {
               printing: 'highResolution',
               modifying: false,
               copying: false,
               annotating: false,
               fillingForms: true,
               contentAccessibility: false,
               documentAssembly: false
            },
            pageSize: {
               width: 283.46456693,
               height: 85.039370079
            },
            content: []
         };

         let allUniqueIds = {};
         const products = Object.entries(val.variants);
         for(let i=0; i<products.length; i++)
         {
            let partIds = {};
            for(let o=0; o<products[i][1].printAmount; o++)
            {
               for(let y=0; y<(val.name === 'cart' ? products[i][1].data.amountOfPackages : products[i][1].amountOfPackages); y++)
               {
                  let uniqueId = randomstring.generate(15);
                  let packagePartName = "Brak nazwy paczki";
                  // if(products[i][1].parts != null && Object.keys(products[i][1].parts).length > 0)
                  // {
                  //    if(products[i][1].parts[`${y+1}`].name != null)
                  //    {
                  //       packagePartName = products[i][1].parts[`${y+1}`].name;
                  //    }
                  // }
                  if(val.name === 'cart')
                  {
                     if(products[i][1].data.parts != null && Object.keys(products[i][1].data.parts).length > 0)
                     {
                        if(products[i][1].data.parts[y+1] != null)
                        {
                           packagePartName = products[i][1].data.parts[y+1].name;
                        }
                     };
                  }
                  else
                  {
                     if(products[i][1].parts != null && Object.keys(products[i][1].parts).length > 0)
                     {
                        if(products[i][1].parts[y+1] != null)
                        {
                           packagePartName = products[i][1].parts[y+1].name;
                        }
                     };
                  }

                  let template = [{
                     columns: [
                     {
                        width: '*',
                        qr: `${val.name === 'cart' ? products[i][1].data.id : products[i][1].id}_${y+1}-${uniqueId}`,
                        fit: 85,
                        alignment: 'center',
                        width: '30%',
                        height: '100%',
                        eccLevel: "M",
                        // margin: [4,12,0,0]
                     },
                     {
                        
                     width: '70%',
                     layout: "noBorders",
                     table: {
                           headerRows: 1,
                           widths: [ '*' ],
                           
                           body: [
                           [ {
                                 text: `${val.name === 'cart' ? products[i][1].data.name : products[i][1].name}`,
                                 bold: true,
                                 fontSize: 10,
                                 alignment: 'center',
                                 margin: [0,7,0,0],
                           } ],
                           [ {
                                 text: packagePartName.toUpperCase(),
                                 fontSize: 8,
                                 bold: true,
                                 alignment: 'center'
                           } ],
                           [ {
                                 text: `Numer ${y+1} z ${(val.name === 'cart' ? products[i][1].data.amountOfPackages : products[i][1].amountOfPackages)} \n ID: ${uniqueId}`,
                                 fontSize: 8,
                                 bold: true,
                                 alignment: "center"
                           },]
                           ]
                        }
                     },
                     ],
                     columnGap: 1
                  }]
                  docDefinition.content.push(...template)
                  partIds[uniqueId] = false;
                  allUniqueIds[uniqueId] = false;
               }
            }
            if(val.name === 'cart')
            {
               products[i][1].ids = partIds;
               await db.collection('Lists').doc(val.list).update({
                  [`products.${products[i][1].item.id}.${products[i][1].index}-${products[i][1].item.variantId}.ids`]: products[i][1].ids,
                  [`products.${products[i][1].item.id}.${products[i][1].index}-${products[i][1].item.variantId}.accepted`]: true
               });
            }
         }
         if(val.name === 'cart')
         {
            const list = (await db.collection('Lists').doc(val.list).get()).data();
            if(list.uniqueIds != null)
            {
               allUniqueIds = {...allUniqueIds, ...list.uniqueIds};
            }
            await db.collection('Lists').doc(val.list).update({
               [`uniqueIds`]: allUniqueIds
            });
         }

         setTimeout(() => {
            this.$store.commit("resetGlobalLoader");
            this.$store.commit('setNotification',{
                show: true,
                head: "Wygenerowano etykiety dla listy PZ / WZ",
                subheader: `Plik został pobrany na Twój komputer.`,
                success: true
            });
            pdfMake.createPdf(docDefinition).download(`Etykieta_${randomstring.generate(8)}.pdf`);
            this.$store.state.variantsCart = {};
            this.blockPrint = false;
         }, 2000);
      },
      async printVariantClassic()
      {
         if(this.printData === null) return;
         let val = this.printData;
         this.openPdfPrintTypeModal = false;
         this.$store.commit('setGlobalLoader',{
            show: true,
            head: "Trwa generowanie etykiet dla listy PZ/WZ.",
            subheader: `Nie zamykaj tego okna.`,
         });
         this.blockPrint = true;
         let docDefinition = {
            pageSize: 'A6',
            pageMargins: [10, 35, 10, 35],
            content: [],
            footer: {
               columns: [
                  // { text: `ID: ${uniqueId}`, alignment: 'center' }
               ]
            },
         };
         const products = Object.entries(val.variants);
         let allUniqueIds = {};
         for(let i=0; i<products.length; i++)
         {
            let partIds = {};
            for(let o=0; o<products[i][1].printAmount; o++)
            {
               for(let y=0; y<(val.name === 'cart' ? products[i][1].item.amountOfPackages : products[i][1].amountOfPackages); y++)
               {
                  let uniqueId = randomstring.generate(15);
                  let partName = 'BRAK NAZWY';
                  let partDimensions = {
                     unit: "cm",
                     height: 0,
                     width: 0,
                     length: 0,
                     weight: {
                        unit: "kg",
                        amount: 0,
                     }
                  }
                  let template = [];
                  if(val.name === 'cart')
                  {
                     if(products[i][1].data.parts != null && Object.keys(products[i][1].data.parts).length > 0)
                     {
                        if(products[i][1].data.parts[y+1] != null)
                        {
                           partName = products[i][1].data.parts[y+1].name;
                        }
                     }
                     if(products[i][1].data.parts != null && Object.keys(products[i][1].data.parts).length > 0)
                     {
                        if(products[i][1].data.parts[y+1] != null && products[i][1].data.parts[y+1].dimensions != null)
                        {
                           partDimensions = {
                              unit: "cm",
                              height: products[i][1].data.parts[y+1].dimensions.height,
                              width: products[i][1].data.parts[y+1].dimensions.width,
                              length: products[i][1].data.parts[y+1].dimensions.length,
                              weight: {
                                 unit: "kg",
                                 amount: products[i][1].data.parts[y+1].weight.amount,
                              }
                           }
                        }
                     }
                     template = [
                        {text: 'ETYKIETA PRODUKTOWA', fontSize: 14, bold:true, alignment: 'center',pageBreak: 'none'},
                        {text: '', margin: [0,10,0,4]},   
                        {text: products[i][1].data.name, fontSize: 14, bold:true, alignment: 'center',pageBreak: 'none'},
                        {text: '', margin: [0,4,0,12]},
                        {text: [{text: 'Model: ', bold:true}, products[i][1].data.model.length > 0 ? products[i][1].data.model : "BRAK DANYCH" ], fontSize: 10, alignment: 'center'},
                        {text: '', margin: [0,1,0,1]},
                        {text: [{text: 'Producent: ', bold:true}, typeof products[i][1].data.manufacturer === "string" ? (products[i][1].data.manufacturer.length > 0 ? products[i][1].data.manufacturer : "BRAK DANYCH") : (products[i][1].data.manufacturer.name.length ? products[i][1].data.manufacturer.name : "BRAK DANYCH") ], fontSize: 10, alignment: 'center'},
                        {text: '', margin: [0,1,0,1]},
                        {text: [{text: 'Kategoria: ', bold:true}, products[i][1].data.category.name.length > 0 ? products[i][1].data.category.name : "BRAK KATEGORII" ], fontSize: 10, alignment: 'center'},
                        {text: '', margin: [0,8,0,8]},
                        {text: 'Wymiary: ', bold:true, fontSize: 10, alignment: 'center'},
                        {text: '', margin: [0,1,0,1]},
                        {
                              table: {
                              widths: ['50%','50%'],
                              body: [
                                 [
                                    {text: [{text: 'Długość: ', bold:true}, partDimensions.length > 0 ? `${partDimensions.length.toFixed(2)} ${partDimensions.unit}` : "BRAK DANYCH"], fontSize: 10, alignment: 'center', border: [false, false, false, false]},
                                    {text: [{text: 'Wysokość: ', bold:true}, partDimensions.height > 0 ? `${partDimensions.height.toFixed(2)} ${partDimensions.unit}` : "BRAK DANYCH"], fontSize: 10, alignment: 'center', border: [false, false, false, false]},
                                 ]
                              ]}
                        },
                        {
                              table: {
                              widths: ['50%','50%'],
                              body: [
                                 [
                                    {text: [{text: 'Szerokość: ', bold:true}, partDimensions.width > 0 ? `${partDimensions.width.toFixed(2)} ${partDimensions.unit}` : "BRAK DANYCH"], fontSize: 10, alignment: 'center', border: [false, false, false, false]},
                                    {text: [{text: 'Waga: ', bold:true}, partDimensions.weight.amount > 0 ? `${partDimensions.weight.amount.toFixed(2)} ${partDimensions.weight.unit}` : "BRAK DANYCH"], fontSize: 10, alignment: 'center', border: [false, false, false, false]},
                                 ]
                              ]}
                        },
                        {text: '', margin: [0,6,0,0]},
                        {
                              table: {
                              widths: ['50%','50%'],
                              body: [
                                 [
                                    {text: [{text: 'SKU: ', bold:true}, products[i][1].data.sku.length > 0 ? products[i][1].data.sku : "BRAK DANYCH"], fontSize: 10, alignment: 'center', border: [false, false, true, false]},
                                    {text: [{text: 'EAN: ', bold:true}, products[i][1].data.ean.length > 0 ? products[i][1].data.ean : "BRAK DANYCH"], fontSize: 10, alignment: 'center', border: [false, false, false, false]},
                                 ]
                              ]}
                        },
                        {text: '', margin: [0,12,0,0]},
                        {
                           table: {
                           widths: ['50%','50%'],
                           body: [
                                 [
                                    { qr: `${products[i][1].data.id}_${y+1}-${uniqueId}`, fit: '115', version: 4,  alignment: 'center', border: [false, false, false, false]},
                                    {
                                       table: {
                                       widths: ['100%'],
                                       body: [
                                             [
                                                {text: {text: 'NAZWA PACZKI:', bold:true}, fontSize: 9, alignment: 'center', border: [false, false, false, false]},
                                             ],
                                             [
                                                {text: {text: `${partName}`, bold:true}, fontSize: 12, alignment: 'center', border: [false, false, false, false],margin: [0,0,0,6]},
                                             ],
                                             [
                                                {text: {text: 'NUMER PACZKI:', bold:true}, fontSize: 9, alignment: 'center', border: [false, false, false, false]},
                                             ],
                                             [
                                                {text: {text: `${y+1} z ${products[i][1].data.amountOfPackages}`, bold: true}, fontSize: 14, alignment: 'center', border: [false, false, false, false]},
                                             ]
                                       ]}, border: [false, false, false, false], margin: [0,10,0,0]
                                    },
                                 ]
                           ]}
                        },
                        {text: '', margin: [0,12,0,0]},
                        {
                        text: [
                           { text: uniqueId},
                        ], fontSize: 7, alignment: 'right', margin: [0,0,0,12]
                        },
                     ]
                  }
                  else
                  {
                     if(products[i][1].parts != null && Object.keys(products[i][1].parts).length > 0)
                     {
                        if(products[i][1].parts[y+1] != null)
                        {
                           partName = products[i][1].parts[y+1].name;
                        }
                     }
                     if(products[i][1].parts != null && Object.keys(products[i][1].parts).length > 0)
                     {
                        if(products[i][1].parts[y+1] != null && products[i][1].parts[y+1].dimensions != null)
                        {
                           partDimensions = {
                              unit: "cm",
                              height: products[i][1].parts[y+1].dimensions.height,
                              width: products[i][1].parts[y+1].dimensions.width,
                              length: products[i][1].parts[y+1].dimensions.length,
                              weight: {
                                 unit: "kg",
                                 amount: products[i][1].parts[y+1].weight.amount,
                              }
                           }
                        }
                     }
                     template = [
                        {text: 'ETYKIETA PRODUKTOWA', fontSize: 12, bold:true, alignment: 'center',pageBreak: 'none'},
                        {text: '', margin: [0,10,0,4]},
                        {text: products[i][1].name, fontSize: 14, bold:true, alignment: 'center',pageBreak: 'none'},
                        {text: '', margin: [0,4,0,12]},
                        {text: [{text: 'Model: ', bold:true}, products[i][1].model.length > 0 ? products[i][1].model : "BRAK DANYCH" ], fontSize: 10, alignment: 'center'},
                        {text: '', margin: [0,1,0,1]},
                        {text: [{text: 'Producent: ', bold:true}, typeof products[i][1].manufacturer === "string" ? (products[i][1].manufacturer.length > 0 ? products[i][1].manufacturer : "BRAK DANYCH") : (products[i][1].manufacturer.name.length ? products[i][1].manufacturer.name : "BRAK DANYCH") ], fontSize: 10, alignment: 'center'},
                        {text: '', margin: [0,1,0,1]},
                        {text: [{text: 'Kategoria: ', bold:true}, products[i][1].category.name.length > 0 ? products[i][1].category.name : "BRAK KATEGORII" ], fontSize: 10, alignment: 'center'},
                        {text: '', margin: [0,8,0,8]},
                        {text: 'WYMIARY: ', bold:true, fontSize: 10, alignment: 'center'},
                        {text: '', margin: [0,1,0,1]},
                           {
                              table: {
                                 widths: ['50%','50%'],
                                 body: [
                                    [
                                       {text: [{text: 'Długość: ', bold:true}, partDimensions.length > 0 ? `${partDimensions.length.toFixed(2)} ${partDimensions.unit}` : "BRAK DANYCH"], fontSize: 10, alignment: 'center', border: [false, false, false, false]},
                                       {text: [{text: 'Wysokość: ', bold:true}, partDimensions.height > 0 ? `${partDimensions.height.toFixed(2)} ${partDimensions.unit}` : "BRAK DANYCH"], fontSize: 10, alignment: 'center', border: [false, false, false, false]},
                                    ]
                                 ]}
                           },
                           {
                              table: {
                                 widths: ['50%','50%'],
                                 body: [
                                    [
                                       {text: [{text: 'Szerokość: ', bold:true}, partDimensions.width > 0 ? `${partDimensions.width.toFixed(2)} ${partDimensions.unit}` : "BRAK DANYCH"], fontSize: 10, alignment: 'center', border: [false, false, false, false]},
                                       {text: [{text: 'Waga: ', bold:true}, partDimensions.weight.amount > 0 ? `${partDimensions.weight.amount.toFixed(2)} ${partDimensions.weight.unit}` : "BRAK DANYCH"], fontSize: 10, alignment: 'center', border: [false, false, false, false]},
                                    ]
                                 ]}
                           },
                           {text: '', margin: [0,12,0,0]},
                           {
                              table: {
                                 widths: ['50%','50%'],
                                 body: [
                                    [
                                       {text: [{text: 'SKU: ', bold:true}, products[i][1].sku.length > 0 ? products[i][1].sku : "BRAK DANYCH"], fontSize: 10, alignment: 'center', border: [false, false, true, false]},
                                       {text: [{text: 'EAN: ', bold:true}, products[i][1].ean.length > 0 ? products[i][1].ean : "BRAK DANYCH"], fontSize: 10, alignment: 'center', border: [false, false, false, false]},
                                    ]
                                 ]}
                           },
                           {text: '', margin: [0,12,0,0]},
                           {
                              table: {
                              widths: ['50%','50%'],
                              body: [
                                    [
                                       { qr: `${products[i][1].id}_${y+1}-${uniqueId}`, fit: '115', version: 4,  alignment: 'center', border: [false, false, false, false]},
                                       {
                                          table: {
                                          widths: ['100%'],
                                          body: [
                                                [
                                                   {text: {text: 'NAZWA PACZKI:', bold:true}, fontSize: 9, alignment: 'center', border: [false, false, false, false]},
                                                ],
                                                [
                                                   {text: {text: `${partName}`, bold:true}, fontSize: 12, alignment: 'center', border: [false, false, false, false],margin: [0,0,0,6]},
                                                ],
                                                [
                                                   {text: {text: 'NUMER PACZKI:', bold:true}, fontSize: 9, alignment: 'center', border: [false, false, false, false]},
                                                ],
                                                [
                                                   {text: {text: `${y+1} z ${products[i][1].amountOfPackages}`, bold: true}, fontSize: 14, alignment: 'center', border: [false, false, false, false]},
                                                ]
                                          ]}, border: [false, false, false, false], margin: [0,10,0,0]
                                       },
                                    ]
                              ]}
                           },
                           {text: '', margin: [0,12,0,0]},
                           {
                           text: [
                              { text: uniqueId},
                           ], fontSize: 7, alignment: 'right', margin: [0,0,0,12]
                           },
                     ]
                  }
                  docDefinition.content.push(...template)
                  partIds[uniqueId] = false;
                  allUniqueIds[uniqueId] = false;
               }
            };
            if(val.name === 'cart')
            {
               products[i][1].ids = partIds;
               await db.collection('Lists').doc(val.list).update({
                  [`products.${products[i][1].item.id}.${products[i][1].index}-${products[i][1].item.variantId}.ids`]: products[i][1].ids,
                  [`products.${products[i][1].item.id}.${products[i][1].index}-${products[i][1].item.variantId}.accepted`]: true
               });
            }
         }
         if(val.name === 'cart')
         {
            const list = (await db.collection('Lists').doc(val.list).get()).data();
            if(list.uniqueIds != null)
            {
               allUniqueIds = {...allUniqueIds, ...list.uniqueIds};
            }
            await db.collection('Lists').doc(val.list).update({
               [`uniqueIds`]: allUniqueIds
            });
         }
         setTimeout(() => {
            this.$store.commit("resetGlobalLoader");
            this.$store.commit('setNotification',{
               show: true,
                head: "Wygenerowano etykiety dla listy PZ / WZ",
                subheader: `Plik został pobrany na Twój komputer.`,
                success: true
            });
            pdfMake.createPdf(docDefinition).download(`Etykieta_${randomstring.generate(8)}.pdf`);
            this.blockPrint = false;
         }, 2000);
      },
      clearData()
      {
         this.loading = false;
         this.products = [];
         this.queryLimit = 12;
         this.dbListener = null;
         this.queryLimitReached = false;
         this.toggleInformation = {};
         this.openSelectVariant = false;
         this.selectedOrder = null;
         this.searchBar = false;
         this.blockPrint = false;
      },
      onLoadMore(val)
      {
         this.queryLimit += 8;
         this.getAllProducts(this.type, val)
      },
      openVariantModal()
      {
         this.openSelectVariant = false
         for(let i=0; i<this.products.length; i++)
         {
            this.products[i].selected = false;
         }
      },
      closeVariantModal()
      {
         this.openSelectVariant = false
         for(let i=0; i<this.products.length; i++)
         {
            this.products[i].selected = false;
         }
      },
      selectProduct(index)
      {
         if(this.searcher.found.length > 0)
         {
            for(let i=0; i<this.searcher.found.length; i++)
            {
               if(i === index)
               {
                  this.searcher.found[i].selected = true;
                  for(let y=0; y<Object.keys(this.searcher.found[i].variants).length; y++)
                  {
                     const products = Object.entries(this.searcher.found[i].variants)
                     products.forEach(element => {
                        this.searcher.found[i].variants[element[0]].checked = false;
                     })
                  }
                  this.openSelectVariant = true;
                  this.selectedOrder = this.searcher.found[i];
               }
               else
               {
                  this.searcher.found[i].selected = false;
               }
            }
         }
         else
         {
            for(let i=0; i<this.products.length; i++)
            {
               if(i === index)
               {
                  this.products[i].selected = true;
                  for(let y=0; y<Object.keys(this.products[i].variants).length; y++)
                  {
                     const products = Object.entries(this.products[i].variants)
                     products.forEach(element => {
                        this.products[i].variants[element[0]].checked = false;
                     })
                  }
                  this.openSelectVariant = true;
                  this.selectedOrder = this.products[i];
               }
               else
               {
                  this.products[i].selected = false;
               }
            }
         }
      },
      resetSuggested()
      {
         this.products = [];
         this.searcher.found = [];
         this.searcher.lastCall = null;
         this.searcher.phrase = "";
      },
      resetSearch()
      {
         this.products = [];
         this.searcher.found = [];
         this.searcher.lastCall = null;
         this.searcher.phrase = "";
         this.getAllProducts('all');
      },
      resetSearchRoutes()
      {  
         this.routes = [];
         this.routeSearcher.found = [];
         this.routeSearcher.lastCall = null;
         this.routeSearcher.phrase = "";
         this.getRoutes()
      },
      async getAllProducts(value, custom)
      {
         this.variants = [];
         this.loading = true;
         const res = await axios.post(`${this.$store.state.apiLink}/storage/products/getAll`, {
            type: value,
            limit: this.queryLimit
         })
         if(!res.data.success)
         {
            this.loading = false;
            if(value === 'all')
            {
               this.$store.commit('setNotification',{
                  show: true,
                  head: "Akcja zakończona niepowodzeniem!",
                  subheader: res.data.log,
                  success: false
               });
            }
         }
         else
         {
            this.queryLimitReached = res.data.limitReached
            this.products = res.data.orders;
            if(res.data.orderVariants !== undefined)
            {
               for(let i=0; i<res.data.orderVariants.length; i++)
               {
                  for(let y=0; y<res.data.orderVariants[i].variants.length; y++)
                  {
                     this.variants.push(res.data.orderVariants[i].variants[y])
                  }
               }
            }
            for(let i=0; i<this.products.length; i++)
            {
               this.toggleInformation[i] = {
                  open: false
               }
            }
            if(custom !== 'infLoading')
            {
               this.$store.commit('setNotification',{
                  show: true,
                  head: "Akcja zakończona sukcesem!",
                  subheader: res.data.log,
                  success: true
               });
            }
            this.loading = false;
         }
      },
      async checkPOSAccess()
      {
         try {
            this.lockContent = true;
            if(Object.keys(this.$route.query).length === 0) throw 'Error: Empty route query';
            if(this.$route.query.entry === undefined) throw 'Error: Wrong key was provided';

            const address = await axios.get('https://api64.ipify.org?format=json');
            const res = await axios.post(`${this.$store.state.apiLink}/checkAccess`, {
               query: this.$route.query.entry,
               ip: address.data.ip
            })
            if(res.data.success)
            {
               this.lockContent = false;
               this.changeTab(0)
            }
            else
            {
               this.$router.push('/')
            }
         }
         catch (error) {
            console.log(error);
            this.$router.push('/')       
         }
      },
   },
   beforeUnmount() {
      if(this.dbListener !== null){
         this.dbListener();
      }
    },
   async created()
   {
      this.moment = moment;
      moment.locale('pl')
      this.checkPOSAccess();
      this.getAllStorageLocations();
   }
}
</script>

<style scoped>

html {
   --tw-bg-opacity: 1;
   background-color: rgba(243, 244, 246, var(--tw-bg-opacity));
}

.fade-enter-active{
  transition: opacity 1s ease;
}
.fade-leave-active{
  transition: opacity 0s;
  display: none;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.fade2-enter-active,
.fade2-leave-active {
  transition: opacity 0.5s ease;
}

.fade2-enter-from,
.fade2-leave-to {
  opacity: 0;
}

/* width */
::-webkit-scrollbar {
   width: 8px;
   height: 3px;
}

/* Track */
::-webkit-scrollbar-track {
   background: rgba(209, 213, 219);
   border-radius: 5px;
}
  
/* Handle */
::-webkit-scrollbar-thumb {
   background: rgba(107, 114, 128);
   border-radius: 5px;
}
  
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
   background: #555;
} 
</style>