<template>
  <TransitionRoot as="template" :show="open">
    <Dialog as="div" class="fixed z-10 inset-0 overflow-y-auto" >
      <div class="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
          <DialogOverlay class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </TransitionChild> 
        <!-- This element is to trick the browser into centering the modal contents. -->
        <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
        <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leave-from="opacity-100 translate-y-0 sm:scale-100" leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
          <div class="relative inline-block align-bottom bg-white rounded-lg px-36 py-20 text-left overflow-visible shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-xl sm:w-full sm:p-6">
            <LoadingSpinnerHub class="pb-14 pt-9 mt-5" v-if="loading" />
              <div>
                <div v-show="!loading">
                  <div class="flex justify-between pr-2 pl-1 pb-1">
                  <div class="flex pt-1 pb-2">
                    <div class="select-none w-96">
                      <h4 class="text-lg font-bold text-gray-700">Wydruk etykiety</h4>
                      <p class="text-base mt-1 text-gray-500">Wybierz rodzaj etykiety, który chcesz wydrukować.</p>
                    </div>
                  </div>
                    <div class="pt-2 items-center -mr-1" >
                      <!-- <XIcon @click.prevent="emitClose(), addToCart(this.checkedVariants)" class="transition-all duration-300 w-6 h-6 text-gray-400 hover:text-gray-700" /> -->
                      <XIcon @click.prevent="emitClose()" class="transition-all duration-300 w-6 h-6 text-gray-400 hover:text-gray-700" />
                    </div>
                  </div>
                  <div>
                    <ul role="list" class="grid grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 select-none">
                        <li @click.prevent="emitPrintClassic()"  class="col-span-2 flex flex-col items-center justify-center divide-y divide-gray-200 rounded-lg bg-white text-center shadow cursor-pointer hover:opacity-70">
                            <div class="flex items-center justify-center flex-1 flex-col p-7">
                                <div>
                                    <DocumentIcon class="w-16 h-16 text-blue-600"></DocumentIcon>
                                </div>
                                <div>
                                    <h3 class="mt-2 text-sm font-medium text-gray-900 whitespace-nowrap">Klasyczna</h3>
                                    <p class="text-xs text-gray-700">A6</p>
                                </div>
                                
                            </div>
                        </li>
                        <li @click.prevent="emitPrintWide()" class="col-span-2 flex flex-col items-center justify-center divide-y divide-gray-200 rounded-lg bg-white text-center shadow cursor-pointer hover:opacity-70">
                            <div class="flex items-center justify-center flex-1 flex-col p-4">
                                <div>
                                    <DocumentIcon class="w-16 h-16 text-purple-600 transform rotate-90"></DocumentIcon>
                                </div>
                                <div>
                                    <h3 class="mt-2 text-sm font-medium text-gray-900">Podłóżna</h3>
                                    <p class="text-xs text-gray-700">10cm x 3cm</p>
                                </div>
                            </div>
                        </li>
                    </ul>
                    <div class="text-xs mt-4 text-gray-400 flex justify-center select-none"><div>Kliknij kafelek, aby rozpocząć wydruk.</div></div>
                  </div>

                </div>
              </div>



          </div>
        </TransitionChild>

      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>
import axios from 'axios';
import { Dialog, DialogOverlay, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'
import { DocumentIcon,TrashIcon, BanIcon, ExclamationIcon, CursorClickIcon, XIcon, CheckCircleIcon} from '@heroicons/vue/outline';
import LoadingSpinnerHub from './LoadingSpinnerHub.vue';
import EmptyState from './EmptyState.vue';

export default {
  components: {
    EmptyState, DocumentIcon, TrashIcon, CheckCircleIcon, BanIcon, LoadingSpinnerHub, XIcon, Dialog, DialogOverlay, DialogTitle, TransitionChild, TransitionRoot, ExclamationIcon, CursorClickIcon
  },
  props: ['orders', 'variants'],
  data() {
    return {
      open: true,
      loading: false,
    }
  },
  methods: {
    emitClose()
    {
      this.$emit("close");
    },
    emitPrintWide()
    {
        this.$emit("printWide");
        this.close();
    },
    emitPrintClassic()
    {
        this.$emit("printClassic");
        this.close();
    },
    close()
    {
      this.emitClose();
      this.open = false
    }
  },
  created() {
  }
}
</script>

<style scoped>
/* width */
::-webkit-scrollbar {
    width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
    background: rgba(209, 213, 219);
    border-radius: 5px;
}
  
/* Handle */
::-webkit-scrollbar-thumb {
    background: rgba(107, 114, 128);
    border-radius: 5px;
}
  
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
} 
</style>