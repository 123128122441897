<template>
<TransitionRoot as="template" :show="true">
  <Dialog as="div" class="fixed inset-0 overflow-hidden z-50" @close="open = false">
    <div @click="drivers.input.showList = false" class="absolute inset-0 overflow-hidden">
      <DialogOverlay class="absolute inset-0 bg-black opacity-40" />
      <div class="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
        <TransitionChild as="template" enter="transform transition ease-in-out duration-500 sm:duration-700" enter-from="translate-x-full" enter-to="translate-x-0" leave="transform transition ease-in-out duration-500 sm:duration-700" leave-from="translate-x-0" leave-to="translate-x-full">
          <div :class="['pointer-events-auto w-screen max-w-md bg-white relative']">
            <!-- loader -->
            <div v-if="loadRoute" class="h-screen flex items-center justify-center">
              <div class="wrap flex items-center justify-center">
                <div class="loading">
                  <div class="bounceball"></div>
                  <div class="text ml-4">Ładowanie..</div>
                </div>
              </div>
            </div>
            <Transition name="fade">
            <div v-if="blockModal.state && !ownChange && !loadRoute" class="bg-gray-400 bg-opacity-70 h-screen w-full absolute z-50 flex justify-center items-center">
              <div class="bg-white w-64 p-4 rounded-lg shadow-xl flex justify-center items-center flex-col">
                <div class="mx-auto mb-2 flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-yellow-100 sm:mx-0 sm:h-11 sm:w-11">
                  <ExclamationIcon class="h-8 w-8 text-yellow-600" aria-hidden="true" />
                </div>
                <div class="font-medium text-gray-700">Trasa została zaktualizowana</div>
                <div v-if="blockModal.updatedBy.length > 0" class="text-gray-500 text-sm">przez: {{blockModal.updatedBy}}</div>
                <button @click="refreshModalData()" :class="['transition-all mt-3 duration-300 bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 rounded-md border border-indigo-300 py-2 px-4 text-sm font-medium text-white shadow-sm flex gap-1 items-center']"><RefreshIcon class="h-4 w-4 text-white " aria-hidden="true" />Odśwież</button>
              </div>
            </div>
            </Transition>
            <Transition name="fade">
            <form v-if="!loadRoute" :class="[deleteAttempt ? 'opacity-40 pointer-events-none' : '', 'flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl transition-all duration-300']">
              <div class="h-0 flex-1 overflow-y-auto">
                <div class="bg-gradient-to-r from-indigo-600 to-purple-600 py-6 px-4 sm:px-6">
                  <div class="flex items-center justify-between">
                    <DialogTitle class="text-lg font-medium text-white truncate">Zarządzanie trasą <span class="text-blue-200 font-semibold" v-tooltip="`${nameToDisplay}`">{{nameToDisplay}}</span></DialogTitle>
                  </div>
                  <div class="mt-1">
                    <p class="text-sm text-blue-300">Wprowadź zmiany w wybranej trasie.</p>
                  </div>
                </div>
                <div class="px-4 sm:px-6 lg:px-8 mt-5">
                  <Listbox as="div" v-model="selectedCountry">
                    <ListboxLabel class="block text-sm font-semibold leading-6 text-gray-600 select-none uppercase">Kraj</ListboxLabel>
                    <div class="relative mt-1">
                      <ListboxButton class="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6">
                        <div class="flex gap-1 items-center">
                          <div v-if="selectedCountry !== undefined && selectedCountry !== null">
                            <img v-if="selectedCountry.flagUrl.length>0" :src="selectedCountry.flagUrl" v-tooltip="`${selectedCountry.code}`" class="h-4 w-6 mr-1 border border-gray-300">
                            <span v-if="selectedCountry.flagUrl.length === 0">--</span>
                          </div>
                          <span class="block truncate">{{selectedCountry?.displayName || 'Wybierz kraj...'}}</span>
                        </div>
                        <span class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                          <ChevronDownIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
                        </span>
                      </ListboxButton>


                      <transition leave-active-class="transition ease-in duration-100" leave-from-class="opacity-100" leave-to-class="opacity-0">
                        <ListboxOptions class="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                          <ListboxOption as="template" v-for="(country, index) in countries" :key="index" :value="country" v-slot="{ active, selected }">
                            <li :class="[active ? 'bg-indigo-600 text-white' : 'text-gray-900', 'relative cursor-default select-none py-2 pr-8 pl-4']">
                              <span :class="[selected ? 'font-semibold' : 'font-normal', 'block truncate']">{{country.displayName}} </span>
                              <span v-if="selected" :class="[active ? 'text-white' : 'text-indigo-600', 'absolute inset-y-0 right-1 flex items-center px-1.5']">
                                <CheckIcon class="h-5 w-5" aria-hidden="true" />
                              </span>
                                <span v-if="!selected" :class="[active ? 'text-white' : 'text-indigo-600', 'absolute inset-y-0 right-1 flex items-center px-1.5']">
                                  <div class="flex items-center">
                                    <img v-if="country.flagUrl.length>0" :src="country.flagUrl" v-tooltip="`${country.code}`" class="h-4 w-6 mr-1 border border-gray-300">
                                    <span v-if="country.flagUrl.length === 0">--</span>
                                  </div>
                                </span>
                            </li>
                          </ListboxOption>
                        </ListboxOptions>
                      </transition>
                    </div>
                  </Listbox>
                  <span class="text-xs select-none text-red-500" v-if="errors.countries">Pole nie może być puste.</span>
                </div>
                <div class="px-4 sm:px-6 lg:px-8 mt-5">
                  <Listbox as="div" v-model="selectedStatus">
                    <ListboxLabel class="block text-sm font-semibold leading-6 text-gray-600 select-none uppercase">Status transportu</ListboxLabel>
                    <div class="relative mt-1">
                      <ListboxButton class="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6">
                        <span class="block truncate">{{selectedStatus?.name || 'Wybierz typ'}}</span>
                        <span class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                          <ChevronDownIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
                        </span>
                      </ListboxButton>

                      <transition leave-active-class="transition ease-in duration-100" leave-from-class="opacity-100" leave-to-class="opacity-0">
                        <ListboxOptions class="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                          <ListboxOption as="template" v-for="status in statuses" :disabled="!allPackagesScanned && status.id === 2" :key="status.id" :value="status" v-slot="{ active, selected }">
                            <li v-tooltip="!allPackagesScanned && status.id === 2 ? 'Nie można zmienić statusu, zamówienia w trasie nie zostały zeskanowane.' : ''" :class="[active ? 'bg-indigo-600 text-white' : 'text-gray-900', 'relative cursor-default select-none py-2 pr-8 pl-4', !allPackagesScanned && status.id === 2 ? 'opacity-40' : '']">
                              <span :class="[selected ? 'font-semibold' : 'font-normal', 'block truncate']">{{status.name}} </span>
                              <span v-if="selected" :class="[active ? 'text-white' : 'text-indigo-600', 'absolute inset-y-0 right-1 flex items-center px-1.5']">
                                <CheckIcon class="h-5 w-5" aria-hidden="true" />
                              </span>
                              <span v-if="!allPackagesScanned && status.id === 2" :class="[active ? 'text-white' : 'text-indigo-600', 'absolute inset-y-0 right-1 flex items-center px-1.5']">
                                <QuestionMarkCircleIcon class="h-5 w-5" aria-hidden="true" />
                              </span>
                            </li>
                          </ListboxOption>
                        </ListboxOptions>
                      </transition>
                    </div>
                  </Listbox>  
                </div>
                <div v-if="newRoute.statusId === 2 && newRoute.orders.length > 0 && ordersDownloaded" class="px-4 sm:px-8 mt-4">
                  <label class="mb-3 font-medium text-indigo-500">Zaznacz zamówienia jako dostarczone</label>
                  <div class="flex items-center my-2">
                    <input id="mark-everything" v-model="markedEverthingAsDelivered" type="checkbox" class="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500">
                    <label for="mark-everything" class="text-xs ml-2 select-none">Zaznacz wszystkie</label>
                  </div>
                  <label class="text-xs select-none">Zamówienia:</label>
                  <div :class="['max-h-52 border-2 border-gray-400 px-2 py-1 rounded-lg border-opacity-40', newRoute.orders.length > 8 ? 'overflow-y-scroll ' : '']">
                    <div v-for="order in ordersMarkedAsDelivered" :key="order.id" class="relative flex items-start">
                      <div class="flex h-6 items-center">
                        <input v-model="order.delivered" :id="order.id" aria-describedby="comments-description" type="checkbox" class="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600" />
                      </div>
                      <div class="ml-2 text-sm leading-6">
                        <label :for="order.id" class="text-gray-900">
                          <span class="font-semibold mr-1">{{order.client}}</span>
                          {{ ' ' }}
                          <span class="text-gray-500"><span class="sr-only">{{order.client}} </span>{{order.address}}</span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <LoadingSpinnerHub v-if="newRoute.statusId === 2 && newRoute.orders.length > 0 && !ordersDownloaded" />

                <div class="relative my-6">
                  <div class="absolute inset-0 flex items-center" aria-hidden="true">
                    <div class="w-full border-t border-gray-300" />
                  </div>
                  <div class="relative flex justify-center">
                  </div>
                </div>

                <!-- date picker -->
                <div>
                  <div v-if="!loadingDateChange" class="px-4 sm:px-6 lg:px-8">
                    <label for="title" class="block text-sm font-semibold leading-6 text-gray-600 select-none uppercase">Data trasy</label>
                    <div :class="{'mt-1': true, 'opacity-30 pointer-events-none select-none': !changeDateMode, 'test': true}">
                      <VueDatePicker placeholder="Wybierz datę wyjazdu..." auto-apply locale="pl" v-model="date" :disabled-dates="disabledDates" range no-disabled-range :min-date="new Date()" :enable-time-picker="false" :clearable="hideClearableButton" @open="checkDate('open')" @closed="checkDate('closed')" @cleared="checkDate('cleared')" />
                    </div>
                    <span v-if="!changeDateMode" @click="changeDate()" class="text-indigo-700 text-sm cursor-pointer">Zmień datę trasy</span>
                    <!-- <span @click="test()" class="text-indigo-700 text-sm cursor-pointer">TEST</span> -->
                    <span v-if="changeDateMode" class="text-red-500 text-xs">*Brak możliwości wyboru niektórych z dat spowodowana jest występowaniem kierowcy bądź samochodu w innej trasie.</span>
                  </div>

                  <div v-if="loadingDateChange" class="px-4 sm:px-6 lg:px-8 mt-5">
                    <LoadingSpinnerHub />
                  </div>
                </div>

                <div class="relative my-6">
                  <div class="absolute inset-0 flex items-center" aria-hidden="true">
                    <div class="w-full border-t border-gray-300" />
                  </div>
                  <div class="relative flex justify-center">
                  </div>
                </div>

                <div class="px-4 sm:px-6 lg:px-8 flex flex-col gap-4">
                  <!-- Route title -->
                  <div>
                    <label for="title" class="block text-sm font-semibold leading-6 text-gray-600 select-none uppercase">Tytuł trasy</label>
                    <div class="mt-1">
                      <input v-model="newRoute.title" type="text" name="title" id="title" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" placeholder="np.: Trasa" />
                    </div>
                  </div>
                  <!-- Vehicles combobox -->
                  <div>
                    <label class="block text-sm font-semibold leading-6 text-gray-600 select-none uppercase">Wybierz pojazd</label>
                    <Combobox as="div" :class="[changeDateMode ? 'opacity-30 pointer-events-none select-none' : '']" v-model="newRoute.vehicle">
                      <div class="relative mt-1">
                        <ComboboxInput class="w-full rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm" @change="vehicle.query = $event.target.value" :display-value="(item) => item ? `${item.brand} ${item.model} ${item.attribs.licensePlate.value}` : `Wybierz pojazd`" />
                        <ComboboxButton class="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
                          <ChevronDownIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
                        </ComboboxButton>

                        <ComboboxOptions v-if="filteredVehicles.length > 0"  class="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                          <ComboboxOption :class="[item.available === false ? 'bg-gray-100' : undefined]" :disabled="!item.available" v-for="item in filteredVehicles" :key="item.id" :value="item" as="template" v-slot="{ active, selected }">
                            <li :class="['relative cursor-default select-none py-2 pl-3 pr-9', active ? 'bg-indigo-600 text-white' : 'text-gray-900']">
                              <span :class="['block truncate', selected && 'font-semibold']">
                                <span :class="[item.available ? 'bg-green-400' : 'bg-red-500', 'inline-block h-2 w-2 flex-shrink-0 rounded-full']" aria-hidden="true" />
                                <span class="mx-3" >{{ `${item.brand} ${item.model}`}}</span>
                                <span class="text-yellow-500">{{item.attribs.licensePlate.value}}</span>
                              </span>

                              <span v-if="selected" :class="['absolute inset-y-0 right-0 flex items-center pr-4', active ? 'text-white' : 'text-indigo-600']">
                                <CheckIcon class="h-5 w-5" aria-hidden="true" />
                              </span>
                            </li>
                          </ComboboxOption>
                        </ComboboxOptions>
                      </div>
                    </Combobox>
                  </div>
                  <!-- Driver combobox -->
                  <div>
                    <label class="block text-sm font-semibold leading-6 text-gray-600 select-none uppercase">Wybierz kierowcę</label>
                    <div class="w-full text-sm flex flex-col items-center">
                      <div class="inline-block relative w-full">
                        <div class="flex flex-col items-center relative">
                            <div @click.stop="drivers.input.showList = !drivers.input.showList" :class="[selectedDrivers.length ? 'py-0.5 pl-0.5' : 'py-1.5 pl-3', changeDateMode ? 'opacity-30 pointer-events-none select-none' : '']" class="pr-1.5 mt-1 flex items-center border text-gray-700 border-gray-300 w-full bg-white rounded-md">
                              <ul v-if="selectedDrivers.length" class="flex flex-auto flex-wrap cursor-pointer">
                                <li v-for="driver in selectedDrivers" :key="driver.id">
                                  <div class="flex justify-center items-center m-0.5 font-medium px-2 py-1 rounded bg-gray-100 border cursor-default">
                                    <div class="text-xs font-medium leading-none max-w-full flex-initial">{{ driver.name + ' ' + driver.surname }}</div>
                                    <div class="flex flex-auto flex-row-reverse text-center items-center">
                                      <XIcon @click.stop="driver.selected = false" class="text-gray-400 hover:text-red-500 transition-all duration-300 cursor-pointer w-4 h-4"/>
                                    </div>
                                  </div>
                                </li> 
                              </ul>
                              <div v-else class="w-full">
                                <span :class="['bg-red-500', 'bg-green-400 inline-block h-2 w-2 flex-shrink-0 rounded-full']" />
                                <span class="ml-3">Wybierz kierowców...</span>
                              </div>
                              <div class="text-gray-300 flex items-center cursor-pointer">
                                <ChevronDownIcon class="w-5 h-5 text-gray-400"/>
                              </div>
                            </div>
                          <div v-if="drivers.input.showList" class="w-full">
                            <div class="absolute shadow top-100 bg-white mt-1 z-40 rounded-md w-full left-0 max-h-select max-h-44 overflow-y-scroll">
                              <ul class="flex flex-col w-full overflow-y-auto h-auto rounded-md">
                                <li v-for="driver in drivers.all" :key="driver.id" class="overflow-auto"> 
                                  <button :disabled="!driver.available || blockModal.state" @click.stop.prevent="driver.selected = !driver.selected" class="w-full hover:bg-indigo-600 hover:text-white"  :class="[driver.available ? 'cursor-pointer' :  'cursor-default bg-gray-100']">
                                    <div class="w-full border-gray-100">
                                      <div class="flex w-full items-center p-2 pl-3 border-transparent border-l-2 relative">
                                        <div class="w-full items-center flex justify-between">
                                          <div class="flex flex-row items-center h-5">
                                            <span :class="[driver.available ? 'bg-green-400' : 'bg-red-500', 'inline-block h-2 w-2 flex-shrink-0 rounded-full']" aria-hidden="true" />
                                            <span class="ml-3 leading-6">{{ driver.name }} {{driver.surname}}</span>
                                          </div>
                                          <CheckIcon v-if="driver.selected" class="text-indigo-400 w-5 h-5"/>
                                        </div>
                                      </div>
                                    </div>
                                  </button>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <span class="text-xs select-none text-red-500" v-if="errors.drivers"> Pole nie może być puste.</span>
                  </div>  
                  <!-- Route comment -->
                  <div>
                    <label for="comment" class="block text-sm font-semibold leading-6 text-gray-600 select-none uppercase">Dodaj opis</label>
                    <div class="mt-1">
                      <textarea v-model="newRoute.description" rows="3" name="comment" id="comment" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
                    </div>
                  </div>
                </div>
                
                <div class="relative my-6">
                  <div class="absolute inset-0 flex items-center" aria-hidden="true">
                    <div class="w-full border-t border-gray-300" />
                  </div>
                  <div class="relative flex justify-center">
                  </div>
                </div>

                <!-- action buttons -->
                <div class="px-4 sm:px-6 lg:px-8 flex flex-col gap-2.5 mb-4">
                  <div class="flex justify-between">
                    <button @click="getOrders()" :disabled="!ordersDownloaded || blockModal.state" type="button" :class="[!ordersDownloaded ? 'pointer-events-none opacity-20' : '', 'relative inline-flex items-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-100 focus:z-10 trasition-all duration-300']">
                      <DocumentDownloadIcon class="-ml-0.5 h-5 w-5 text-gray-400" aria-hidden="true" />                    
                      Eksportuj
                    </button>
                    <input type="file" @change="handleFileUpload($event)" accept=".csv" class="cursor-pointer w-64 relative inline-flex items-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-100 focus:z-10 trasition-all duration-300" />
                  </div>
                  <!-- <button @click="optimizeRouteCheckbox = true;" :disabled="routeOptimizing" type="button" :class="[routeOptimizing ? 'bg-gray-200 cursor-not-allowed text-gray-500' : 'bg-yellow-100 text-yellow-500 hover:bg-yellow-200 focus:outline-none focus:ring-2 focus:ring-yellow-500 focus:ring-offset-2 border-yellow-100', 'w-full flex justify-center relative items-center gap-x-1.5 trasition-all duration-300 rounded-md border py-2 px-4 text-sm font-medium text-white shadow-sm']">
                    <div v-if="routeOptimizing" class="spinner"></div>
                    <SwitchVerticalIcon v-if="!routeOptimizing" class="-ml-0.5 h-5 w-5 mr-1" aria-hidden="true" />
                    <span v-if="!routeOptimizing" class="whitespace-nowrap">Optymalizuj trasę</span>
                  </button> -->
                  <button :disabled="!ordersDownloaded || blockModal.state || routeOptimizing" type="button" @click="emitOrdersModal('managed')" :class="[!ordersDownloaded || routeOptimizing ? 'pointer-events-none opacity-50' : '', 'w-full flex justify-center relative items-center gap-x-1.5 trasition-all duration-300 bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 rounded-md border border-indigo-300 py-2 px-4 text-sm font-medium text-white shadow-sm']">
                    <DocumentAddIcon class="-ml-0.5 h-5 w-5 text-white" aria-hidden="true" />
                    Edytuj zamówienia
                  </button>
                  <button :disabled="!ordersDownloaded || blockModal.state || routeOptimizing" @click="printLabels()" type="button" :class="[!ordersDownloaded || routeOptimizing ? 'pointer-events-none opacity-20' : '', 'w-full flex justify-center relative items-center gap-x-1.5 rounded-md bg-gray-100 px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-200 focus:z-10 trasition-all duration-300']">
                    <DocumentDownloadIcon class="-ml-0.5 h-5 w-5 text-gray-500" aria-hidden="true" />
                    Generuj zbiorczo etykiety
                  </button>
                  <Transition name="fade">
                    <div v-if="ordersDownloaded" class="flex flex-col gap-2">
                      <transition-group name="fade2">
                      <div v-if="!optimizeRouteCheckbox">
                        <span class="font-semibold text-red-500 uppercase text-sm">Usuń trasę</span>
                        <div class="flex items-center mt-1">
                          <input id="delete-route-checkbox" :disabled="routeOptimizing" v-model="deleteRouteCheckbox" type="checkbox" class="h-4 w-4 rounded border-gray-300 text-red-600 focus:ring-red-500 transition-all duration-300">
                          <label for="delete-route-checkbox" class="text-xs ml-2 select-none">Chcę usunąć tą trasę - wiem co robię.</label>
                        </div>
                      </div>
                      <div v-if="!deleteRouteCheckbox">
                        <span class="font-semibold text-yellow-500 uppercase text-sm">Optymalizuj trasę</span>
                        <div class="flex items-center mt-1">
                          <input id="optimize-route-checkbox" :disabled="routeOptimizing" v-model="optimizeRouteCheckbox" type="checkbox" class="h-4 w-4 rounded border-gray-300 text-yellow-600 focus:ring-yellow-500 transition-all duration-300">
                          <label for="optimize-route-checkbox" class="text-xs ml-2 select-none">Chcę zoptymalizować tą trasę - wiem co robię.</label>
                        </div>
                      </div>
                      </transition-group>
                    </div>
                  </Transition>
                </div>

              </div>
              <!-- buttons -->
              <div class="flex justify-between flex-shrink-0 px-8 py-4 items-center">
                <div>
                  <button :disabled="blockModal.state || routeOptimizing" @click.prevent="emitClose" :class="blockModal.state || routeOptimizing ? 'opacity-30' : ''" class="transition-all duration-300 rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2">Zamknij</button>
                </div>
                <div v-if="!saving" class="select-none">
                  <Transition name="fade">
                    <button v-if="!deleteRouteCheckbox && !optimizeRouteCheckbox" :disabled="this.content === null && actionRoute" @click.prevent="sortOrders()" :class="[this.content !== null ? '' : 'opacity-50 cursor-not-allowed', actionRoute ? 'opacity-50 pointer-events-none' : '', 'transition-all duration-300 bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 ml-2 rounded-md border border-indigo-300 py-2 px-4 text-sm font-medium text-white shadow-sm']">Posortuj zamówienia</button>
                  </Transition>
                  <transition-group name="fade">
                    <button v-if="!deleteRouteCheckbox && !optimizeRouteCheckbox" :disabled="!ordersDownloaded || blockModal.state || routeOptimizing" @click.prevent="validateRouteDataBeforeUpdate()" :class="[!ordersDownloaded || routeOptimizing ? 'pointer-events-none opacity-50' : '', 'transition-all duration-300 bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 ml-2 rounded-md border border-indigo-300 py-2 px-4 text-sm font-medium text-white shadow-sm']">Aktualizuj</button>
                    <button v-if="deleteRouteCheckbox" :disabled="actionRoute || blockModal.state || routeOptimizing" type="button" @click.prevent="deleteRoute()" :class="[actionRoute ? 'opacity-30 pointer-events-none' : '', 'transition-all duration-300 bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 ml-2 rounded-md border border-red-300 py-2 px-4 text-sm font-medium text-white shadow-sm']">
                      Usuń trasę
                    </button>
                    <button v-if="optimizeRouteCheckbox" @click="optimizeRoute()" :disabled="routeOptimizing" type="button" :class="[routeOptimizing ? 'bg-gray-200 cursor-not-allowed text-gray-500' : 'bg-yellow-100 text-yellow-500 hover:bg-yellow-200 focus:outline-none focus:ring-2 focus:ring-yellow-500 focus:ring-offset-2 border-yellow-100', 'w-full flex justify-center relative items-center gap-x-1.5 trasition-all duration-300 rounded-md border py-2 px-4 text-sm font-medium text-white shadow-sm']">
                      <div v-if="routeOptimizing" class="spinner"></div>
                      <SwitchVerticalIcon v-if="!routeOptimizing" class="-ml-0.5 h-5 w-5 mr-1" aria-hidden="true" />
                      <span v-if="!routeOptimizing" class="whitespace-nowrap">Optymalizuj trasę</span>
                    </button>
                  </transition-group>
                </div>
                <div v-else>
                  <p>Zapisywanie...</p>
                </div>
              </div>
            </form>
            </Transition>
          </div>
        </TransitionChild>
      </div>
    </div>
  </Dialog>
</TransitionRoot>
</template>

<script>
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
import Papa from 'papaparse';
import axios from 'axios';
import moment from 'moment';
import { db } from "@/firebase/gfbconf.js";
import { Dialog, DialogOverlay, DialogTitle, TransitionChild, TransitionRoot, Switch,Listbox, ListboxButton, ListboxLabel, ListboxOption, ListboxOptions } from '@headlessui/vue';
import { QuestionMarkCircleIcon, XCircleIcon, XIcon } from '@heroicons/vue/solid';
import { ChevronDownIcon, CheckIcon, DocumentDownloadIcon, DocumentAddIcon, RefreshIcon, ExclamationIcon, SwitchVerticalIcon } from '@heroicons/vue/outline';
import { Combobox, ComboboxButton, ComboboxInput, ComboboxLabel, ComboboxOption, ComboboxOptions } from '@headlessui/vue';
import EmptyState from './EmptyState.vue'
import LoadingSpinnerHub from './LoadingSpinnerHub.vue';
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
pdfMake.vfs = pdfFonts.pdfMake.vfs;
export default {
  name: "ClientsModal",
  components: {
    QuestionMarkCircleIcon, VueDatePicker, LoadingSpinnerHub, DocumentDownloadIcon, Dialog, DialogOverlay, DialogTitle, TransitionChild, TransitionRoot, EmptyState, Switch, XCircleIcon, DocumentAddIcon, XIcon, CheckIcon, ChevronDownIcon, Listbox, ListboxButton, ListboxLabel, ListboxOption, ListboxOptions, Combobox, ComboboxButton, ComboboxInput, ComboboxLabel, ComboboxOption, ComboboxOptions, RefreshIcon, ExclamationIcon, SwitchVerticalIcon
  },
  props: ['route', 'statuses', 'saving', 'blockModal'],
  data () {
    return {
      changeDateMode: false,
      loadingDateChange: false,
      date: [],
      disabledDates: [],
      nameToDisplay: '',
      lastLoaded: null,
      orders: [],
      file: '',
      content: null,
      parsed: false,
      selectedStatus: null,
      selectedCountry: null,
      routeOptimizing: false,
      vehicle: {
        query: '',
      },
      group: {
        query: '',
      },
      driver: {
        query: '',
      },
      vehicles: [],
      groups: [],
      drivers: {
        all: [],
        input: {
          showList: false,
          query: ""
        },
      },
      deleteRouteCheckbox: false,
      optimizeRouteCheckbox: false,
      open: false,
      newRoute: JSON.parse(JSON.stringify(this.route)),
      oldVehicle: null,
      oldDrivers: [],
      markedEverthingAsDelivered: false,
      ordersMarkedAsDelivered: [],
      errors: {
        drivers: false,
        countries: false
      },
      ordersDownloaded: false,
      loadRoute: false,
      backupDate: [],
      hideClearableButton: false,
      ownChange: false,
      dbListenerVehicles: null,
      allPackagesScanned: false,
      countries: [
        {
            displayName: 'Polska',
            name: 'Poland',
            code: 'PL',
            flagUrl: 'https://flagcdn.com/w320/pl.png',
        },
        {
            displayName: 'Niemcy',
            name: 'Germany',
            code: 'DE',
            flagUrl: 'https://flagcdn.com/w320/de.png',
        },
        {
            displayName: 'Francja',
            name: 'France',
            code: 'FR',
            flagUrl: 'https://flagcdn.com/w320/fr.png',
        },
        {
            displayName: 'Luksemburg',
            name: 'Luxembourg',
            code: 'LU',
            flagUrl: 'https://flagcdn.com/w320/lu.png',
        },
        {
            displayName: 'Włochy',
            name: 'Italy',
            code: 'IT',
            flagUrl: 'https://flagcdn.com/w320/it.png',
        },
        {
            displayName: 'Holandia',
            name: 'Netherlands',
            code: 'NL',
            flagUrl: 'https://flagcdn.com/w320/nl.png',
        },
        {
            displayName: 'Belgia',
            name: 'Belgium',
            code: 'BE',
            flagUrl: 'https://flagcdn.com/w320/be.png',
        },
        {
            displayName: 'Austria',
            name: 'Austria',
            code: 'AT',
            flagUrl: 'https://flagcdn.com/w320/at.png',
        },
      ],
      actionRoute: false,
      deleteAttempt: false
    }
  },
  beforeUnmount() {
    if(this.dbListenerVehicles !== null){
      this.dbListenerVehicles();
    }
  },
  async created()
  {
    this.loadRoute = true;
    await this.getVehicles()
    await this.getGroups()
    await this.getDrivers()

    if(!this.newRoute.vehicle)
    {
      this.newRoute.vehicle = null;
    }
    if(!this.newRoute.group)
    {
      this.newRoute.group = null;
    }
    if(this.route.vehicle)
    {
      this.oldVehicle = JSON.parse(JSON.stringify(this.route.vehicle))
    } 
    if(this.route.drivers)
    {
      this.oldDrivers = this.route.drivers.slice()
    }
    if(!this.newRoute.country)
    {
      this.newRoute.country = null;
    }
    else
    {
      this.selectedCountry = this.countries.find(status => status.code === this.newRoute.country.code)
    }

    this.date[0] = moment.unix(this.route.date.start.seconds).toDate();
    this.date[1] = moment.unix(this.route.date.end.seconds).toDate();
    this.backupDate = this.date

    this.nameToDisplay = this.newRoute.title

    //preoccupate combobox status value
    this.selectedStatus = this.statuses.find(status => status.id === this.newRoute.statusId)
    for(let i=0; i<this.route.orders.length; i++)
    {
      this.orders[i] = (await this.route.orders[i].ref.get()).data()
      if(this.orders[i].statusId === 6)
      {
        this.ordersMarkedAsDelivered.push({
          id: this.orders[i].id,
          order: this.orders[i],
          delivered: true,
          currentStatusId: this.orders[i].statusId,
          client: this.orders[i].client.name,
          address: this.orders[i].delivery.address,
          primaryStatusId: (this.orders[i].recentStatusId !== undefined ? this.orders[i].recentStatusId : 3)
        })
      }
      else
      {
        this.ordersMarkedAsDelivered.push({
          id: this.orders[i].id,
          order: this.orders[i],
          delivered: false,
          currentStatusId: this.orders[i].statusId,
          client: this.orders[i].client.name,
          address: this.orders[i].delivery.address,
          primaryStatusId: (this.orders[i].recentStatusId !== undefined ? this.orders[i].recentStatusId : 3)
        })
      }
    }
    for(let y=0; y<this.orders.length; y++)
    {
      if(this.orders[y] !== undefined && this.orders[y] !== null)
      {
        if(this.orders[y].packages !== undefined && this.orders[y].packages !== null)
        {
          this.allPackagesScanned = true
          for(let i=0; i<this.orders[y].packages.length; i++)
          {
            if(!this.orders[y].packages[i].scannedInStorage)
            {
              this.allPackagesScanned = false
              break;
            }
          }
        }
        else
        {
          this.allPackagesScanned = false;
        }
      }
      if(!this.allPackagesScanned)
      {
        break;
      }
    }

    this.ordersDownloaded = true;
    this.loadRoute = false;
  },
  methods: {
    async reloadModal() {
      this.loadRoute = true;
      this.allPackagesScanned = false
      this.changeDateMode = false
      this.date = []
      this.disabledDates = []
      this.nameToDisplay = ''
      this.lastLoaded = null
      this.orders = []
      this.file = ''
      this.content = null
      this.parsed = false
      this.selectedStatus = null
      this.selectedCountry = null
      this.vehicle = {
        query: '',
      }
      this.group = {
        query: '',
      }
      this.driver = {
        query: '',
      }
      this.vehicles = []
      this.groups = []
      this.drivers = {
        all: [],
        input: {
          showList: false,
          query: ""
        },
      }
      this.deleteRouteCheckbox = false
      this.open = false
      this.newRoute = JSON.parse(JSON.stringify(this.route))
      this.oldVehicle = null
      this.oldDrivers = []
      this.markedEverthingAsDelivered = false
      this.ordersMarkedAsDelivered = []
      this.errors = {
        drivers: null,
        countires: false
      },
      this.ordersDownloaded = false
      this.backupDate = []
      this.hideClearableButton = false

      await this.getVehicles()
      await this.getGroups()
      await this.getDrivers()

      if(!this.newRoute.vehicle)
      {
        this.newRoute.vehicle = null
      }
      if(!this.newRoute.group)
      {
        this.newRoute.group = null
      }
      if(this.route.vehicle)
      {
        this.oldVehicle = JSON.parse(JSON.stringify(this.route.vehicle))
      } 
      if(this.route.drivers)
      {
        this.oldDrivers = this.route.drivers.slice()
      }
      if(!this.newRoute.country)
      {
        this.newRoute.country = null;
      }
      else
      {
        this.selectedCountry = this.newRoute.country
      }

      this.date[0] = moment.unix(this.route.date.start.seconds).toDate();
      this.date[1] = moment.unix(this.route.date.end.seconds).toDate();
      this.backupDate = this.date

      this.nameToDisplay = this.newRoute.title

      //preoccupate combobox status value
      this.selectedStatus = this.statuses.find(status => status.id === this.newRoute.statusId)
      for(let i=0; i<this.route.orders.length; i++)
      {
        this.orders[i] = (await this.route.orders[i].ref.get()).data()
        if(this.orders[i].statusId === 6)
        {
          this.ordersMarkedAsDelivered.push({
            id: this.orders[i].id,
            order: this.orders[i],
            delivered: true,
            currentStatusId: this.orders[i].statusId,
            client: this.orders[i].client.name,
            address: this.orders[i].delivery.address,
            primaryStatusId: (this.orders[i].recentStatusId !== undefined ? this.orders[i].recentStatusId : 3)
          })
        }
        else
        {
          this.ordersMarkedAsDelivered.push({
            id: this.orders[i].id,
            order: this.orders[i],
            delivered: false,
            currentStatusId: this.orders[i].statusId,
            client: this.orders[i].client.name,
            address: this.orders[i].delivery.address,
            primaryStatusId: (this.orders[i].recentStatusId !== undefined ? this.orders[i].recentStatusId : 3)
          })
        }
      }
      for(let y=0; y<this.orders.length; y++)
      {
        if(this.orders[y] !== undefined && this.orders[y] !== null)
        {
          if(this.orders[y].packages !== undefined && this.orders[y].packages !== null)
          {
            this.allPackagesScanned = true
            for(let i=0; i<this.orders[y].packages.length; i++)
            {
              if(!this.orders[y].packages[i].scannedInStorage)
              {
                this.allPackagesScanned = false
                break;
              }
            }
          }
          else
          {
            this.allPackagesScanned = false;
          }
        }
        if(!this.allPackagesScanned)
        {
          break;
        }
      }

      this.ordersDownloaded = true;
      this.loadRoute = false;
    },
    refreshModalData() {
      if(this.ownChange !== true) {
        this.$store.commit('setNotification',{
          show: true,
          head: "Pomyślnie odświeżono dane",
          subheader: ``,
          success: true
        });
      }
      this.$emit('refreshModalData')
    },
    async changeDate(){
      this.loadingDateChange = true
      let driversIds = []
      let vehicleId = ""
      for (let d = 0; d < this.selectedDrivers.length; d++) {
        const driver = this.selectedDrivers[d];
        driversIds.push(driver.id)
      }
      this.ownChange = true;
      vehicleId = this.newRoute.vehicle.id
      const res = await axios.post(`${this.$store.state.apiLink}/route/changeDate/NotAvailableDates`, {
        driversIds: driversIds,
        vehicleId: vehicleId,
        currentDates: {
          start: this.route.date.start,
          end: this.route.date.end
        }
      })
      if(res.data.success)
      {
        this.changeDateMode = true
        this.disabledDates = res.data.datesToExclude
        this.loadingDateChange = false
      }else{
        this.ownChange = false;
        this.loadingDateChange = false
      }
    },
    checkDate(val)
    {
      if(val === 'closed')
      {
        this.hideClearableButton = true;
      }
      else if(val === 'open')
      {
        this.hideClearableButton = false;
      }
      else if(val === 'cleared')
      {
        this.date = this.backupDate;
        this.hideClearableButton = false;
      }
    },
    async printLabels()
    {
      this.ordersDownloaded = false;
      let docDefinition = {
        pageSize: 'A6',
        pageMargins: [10, 35, 10, 0],
        content: []
      };
      if(this.newRoute.loadingSequence != null) {
        for(let i=0; i<this.orders.length; i++) {
            let orderData = this.orders[i];
            if(orderData.packages == null) continue
            for(let y = 0; y < orderData.packages.length; y++) {
                let packageData = orderData.packages[y];
                if(this.newRoute.loadingSequence != null && this.newRoute.loadingSequence.packedItems.length > 0) {
                    for(let z = 0; z < this.newRoute.loadingSequence.packedItems.length; z++) {
                        let packedItem = this.newRoute.loadingSequence.packedItems[z];
                        const orderId = packedItem.itemId.slice(packedItem.itemId.indexOf("_o_") + 3, packedItem.itemId.indexOf("_pn_"));
                        const partNumber = packedItem.itemId.slice(packedItem.itemId.indexOf("_pn_") + 4, packedItem.itemId.indexOf("_v_"));
                        if(orderId+':'+partNumber === packageData.id) {
                            packageData.packageData = packedItem;
                            break
                        }
                    }
                }
            }
        }

        let currentOrderId = null;
        let newOrder = []
        for(let y = 0; y < this.newRoute.loadingSequence.packedItems.length; y++) {
            let packedItem = this.newRoute.loadingSequence.packedItems[y];
            let itemId = packedItem.itemId;
            if((currentOrderId !== itemId.slice(itemId.indexOf("_o_") + 3, itemId.indexOf("_pn_")))) {
                if(currentOrderId !== null) {
                    for(let i=0; i<this.orders.length; i++) {
                        if(this.orders[i].id === currentOrderId) {
                            this.orders[i].packages = newOrder;
                            newOrder = [];
                        }
                    }
                }
            }
            currentOrderId = itemId.slice(itemId.indexOf("_o_") + 3, itemId.indexOf("_pn_"));
            for(let i=0; i<this.orders.length; i++) {
                let order = this.orders[i];
                for(let z=0; z < order.packages.length; z++) {
                    let packageData = order.packages[z];
                    if(itemId === packageData.packageData.itemId) {
                        newOrder.push(order.packages[z])
                    }
                }
            }

            if(y === this.newRoute.loadingSequence.packedItems.length - 1) {
                if(currentOrderId !== null) {
                    for(let i=0; i<this.orders.length; i++) {
                        if(this.orders[i].id === currentOrderId) {
                            this.orders[i].packages = newOrder;
                            newOrder = [];
                        }
                    }
                }
            }
        }
        this.orders.reverse()
      }
      for (let o = 0; o < this.orders.length; o++) {
        let order = this.orders[o]
        let address = []
        if(order.delivery.address.length > 0){
            address.push(order.delivery.address)
        }
        if(order.delivery.postCode.length > 0){
            address.push(order.delivery.postCode)
        }
        if(order.delivery.city.length > 0){
            address.push(order.delivery.city)
        }
        if(order.delivery.country.name.length > 0){
            address.push(order.delivery.country.name)
        }
        moment.locale('pl');
        let date = moment().format('L LT')

        let packageIndex = 0
        for(let i = 0; i < Object.keys(order.positions).length; i++) {
          let prod = (await order.positions[i+1].product.ref.get()).data()
          for(let y = 0; y < prod.variants[order.positions[i+1].product.variantId].amountOfPackages; y++) {
              let partName = 'BRAK NAZWY';
              if(order.packages != null && order.packages.length > 0)
              {
                if(order.packages[packageIndex] != null)
                {
                  partName = order.packages[packageIndex].id.slice(order.packages[packageIndex].id.indexOf(':')+1);
                }
              }
      
              let template = [
              {
                stack: [
                  {text: 'ETYKIETA ZAMÓWIENIA', fontSize: 14, bold:true, alignment: 'center'},
                  {text: `${order.id}`, fontSize: 12, bold:true, alignment: 'center'},
                  {text: '', margin: [0,4,0,4]},
                  {text: [{text: 'Produkt: ', bold:true},`${order.positions[i+1].id} z ${this.amountOfItemsForLabel(order.positions)}`], fontSize: 9, alignment: 'center'},
                  {text: '', margin: [0,9,0,9]},
                  {text: {text: 'Nazwa produktu: ', bold:true}, fontSize: 9, alignment: 'center'},
                  {text: '', margin: [0,1,0,1]},
                  {text: {text: `${prod.variants[order.positions[i+1].product.variantId].name.length > 0 ? prod.variants[order.positions[i+1].product.variantId].name.substring(0,40) + `${prod.variants[order.positions[i+1].product.variantId].name.length > 40 ? '...' : ''}` : '--'}`, bold:true}, fontSize: 10, alignment: 'center'},
                  {text: '', margin: [0,9,0,9]},
                  {text: 'Klient: ', bold:true, fontSize: 9, alignment: 'center'},
                  {text: '', margin: [0,1,0,1]},
                  {
                      table: {
                      widths: ['50%','50%'],
                      body: [
                          [
                              {text: {text: 'Imię i nazwisko:', bold:true}, fontSize: 9, alignment: 'center', border: [false, false, false, false]},
                              {text: {text: 'Firma: ', bold:true}, fontSize: 9, alignment: 'center', border: [false, false, false, false]},
                          ],
                          [
                              {text: {text: `${order.client.name.length > 0 ? order.client.name.substring(0,25) + `${order.client.name.length > 25 ? '...' : ''}` : order.invoice.fullname.length > 0 ? order.invoice.fullname.substring(0,25) + `${order.invoice.fullname.length > 25 ? '...' : ''}` : 'Brak'}`}, fontSize: 7, alignment: 'center', border: [false, false, false, false]},
                              {text: {text: `${order.client.company.length > 0 ? order.client.company.substring(0,25) + `${order.client.company.length > 25 ? '...' : ''}` : order.invoice.company.length > 0 ? order.invoice.company.substring(0,25) + `${order.invoice.company.length > 25 ? '...' : ''}` : order.delivery.company.length > 0 ? order.delivery.company.substring(0,25) + `${order.delivery.company.length > 25 ? '...' : ''}` : '--'}`}, fontSize: 7, alignment: 'center', border: [false, false, false, false]},
                          ]
                      ]}
                  },
                  {
                      table: {
                      widths: ['50%','50%'],
                      body: [
                          [
                              {text: {text: 'E-mail:', bold:true}, fontSize: 9, alignment: 'center', border: [false, false, false, false]},
                              {text: {text: 'Numer telefonu: ', bold:true}, fontSize: 9, alignment: 'center', border: [false, false, false, false]},
                          ],
                          [
                              {text: {text: `${order.client.email.length > 0 ? order.client.email.substring(0,25) + `${order.client.email.length > 25 ? '...' : ''}` : 'Brak'}`}, fontSize: 7, alignment: 'center', border: [false, false, false, false]},
                              {text: {text: `${order.client.phone.full.length > 0 ? order.client.phone.full : 'Brak'}`}, fontSize: 7, alignment: 'center', border: [false, false, false, false]},
                          ]
                      ]}
                  },
                  {text: '', margin: [0,9,0,9]},
                  {text: 'Dostawa: ', bold:true, fontSize: 9, alignment: 'center'},
                  {text: '', margin: [0,1,0,1]},
                  {text: {text: `${address.toString().substring(0,120)}${address.toString().length > 120 ? '...' : ''}`}, fontSize: 7, alignment: 'center', border: [false, false, false, false]},
                  {text: '', margin: [0,9,0,9]},
                  {
                      table: {
                      widths: ['50%','50%'],
                      body: [
                          [
                              { qr: `${i+1}_${order.id}:${partName === 'BRAK NAZWY' ? order.positions[i+1].id+'_'+(y+1) : partName}`, fit: '115', version: 3,  alignment: 'center', border: [false, false, false, false]},
                              {
                                  table: {
                                  widths: ['100%'],
                                  body: [
                                      [
                                          {text: {text: 'NAZWA PACZKI:', bold:true}, fontSize: 9, alignment: 'center', border: [false, false, false, false]},
                                      ],
                                      [
                                          {text: {text: `${partName === 'BRAK NAZWY' ? order.positions[i+1].id+'_'+(y+1) : partName}`, bold:true}, fontSize: 12, alignment: 'center', border: [false, false, false, false],margin: [0,0,0,6]},
                                      ],
                                      [
                                          {text: {text: 'Numer paczki:', bold:true}, fontSize: 9, alignment: 'center', border: [false, false, false, false]},
                                      ],
                                      [
                                          {text: {text: `${y+1} z ${prod.variants[order.positions[i+1].product.variantId].amountOfPackages}`, bold: true}, fontSize: 16, alignment: 'center', border: [false, false, false, false]},
                                      ]
                                  ]}, border: [false, false, false, false], margin: [0,0,0,0]
                              },
                          ]
                      ]}
                  },
                  {text: '', margin: [0,12,0,12]},
                  {
                    text: [
                      { text: 'Wygenerowano: '},`${date}`
                    ], fontSize: 7, alignment: 'right', pageBreak: `${parseFloat(order.positions[i+1].id) === parseFloat(this.amountOfItemsForLabel(order.positions)) && parseFloat(y+1) === parseFloat(prod.variants[order.positions[i+1].product.variantId].amountOfPackages) ? '' : 'after'}`
                  },
              ],
              unbreakable: true
              }]
              docDefinition.content.push(...template)
              packageIndex++
          }
        }
      }
      pdfMake.createPdf(docDefinition).download(`Etykiety_zamówień_${this.nameToDisplay}.pdf`);
      this.ordersDownloaded = true;
    },
    amountOfItemsForLabel(positions)
    {
        let posAm = 0;
        let items = 0;
        for (const pos in positions) {
            posAm = posAm +1
            items = items + positions[pos].product.quantity;
        }

        return `${posAm}`
    },
    emitOrdersModal(value)
    {
      const ref = this.route.orders;
      let old = JSON.parse(JSON.stringify(this.route));
      old.orders = ref
      if(value === 'managed')
      {
        old.managed = true;
      }
      this.$emit("manageOrders", old)
    },
    clearErrors()
    {
      this.errors.drivers = false;
      this.errors.countries = false;
    },
    async getOrders()
    {
      const stopOffPoint = {
        code: 'PL',
        postcode: '63-604',
        place: 'Baranów',
        address: 'ulica Cło',
        costs: '0',
        item6: '',
        item7: '',
        item8: '',
        item9: '',
        item10: '',
        item11: '',
        item12: '',
        item13: '',
        item14: '',
        item15: '0',
        item16: '',
        item17: '',
        item18: 'town',
        item19: '',
        item20: '',
        item21: '',
        item22: '',
        item23: '',
        item24: '',
        item25: '',
        item26: '',
        item27: '',
        item28: 'UNCHANGED',
        item29: '',
        item30: '',
      }

      const orders = [
        stopOffPoint
      ];

      let order = {
        code: '',
        postcode: '',
        place: '',
        address: '',
        costs: '0',
        item6: '',
        item7: '',
        item8: '',
        item9: '',
        item10: '',
        item11: '',
        item12: '',
        item13: '',
        item14: '',
        item15: '0',
        item16: '',
        item17: '',
        item18: 'town',
        item19: '',
        item20: '',
        item21: '',
        item22: '',
        item23: '',
        item24: '',
        item25: '',
        item26: '',
        item27: '',
        item28: 'UNCHANGED',
        item29: '',
        item30: '',
      };
      
      for(let i=0; i<this.orders.length; i++)
      {
        order = {
          code: this.orders[i].delivery.country.code,
          postcode: this.orders[i].delivery.postCode,
          place: this.orders[i].delivery.city,
          address: this.orders[i].delivery.address,
          costs: '0',
          item6: '',
          item7: '',
          item8: '',
          item9: '',
          item10: '',
          item11: '',
          item12: this.orders[i].id,
          item13: '',
          item14: '',
          item15: '0',
          item16: '',
          item17: '',
          item18: 'town',
          item19: '',
          item20: '',
          item21: '',
          item22: '',
          item23: '',
          item24: '',
          item25: '',
          item26: '',
          item27: '',
          item28: 'UNCHANGED',
          item29: '',
          item30: '',
        };
        orders.push(order)
      }
      orders.push(stopOffPoint)
      const csvdata = this.csvmaker(orders);
      this.download(csvdata);
    },
    csvmaker(data)
    {
      let csvRows = [];
      for(let i=0;i<data.length; i++)
      {
        const values = Object.values(data[i]).join(';');
        csvRows.push(values)
      }
      return csvRows.join('\n')
    },
    download(data)
    {
      let result = '';
      const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      let counter = 0;
      while (counter < 8)
      {
        result += characters.charAt(Math.floor(Math.random() * characters.length));
        counter += 1;
      }
      const blob = new Blob([data], { type: 'text/csv' });
      const url = window.URL.createObjectURL(blob)
      const a = document.createElement('a')
      a.setAttribute('href', url)
      a.setAttribute('download', `PTV-${result}.csv`);
      a.click()
    },
    async optimizeRoute() {
      try {
        this.routeOptimizing = true;
        const res = await axios.post(`${this.$store.state.herokuApiLink}/routes/${this.$store.state.herokuApiPath}/optimize`, {
          routeId: this.route.id,
          routeLastModificationDate: this.route.meta.lastModificationDate.seconds,
          name: this.$store.state.userData.name + ' ' + this.$store.state.userData.surname,
        }, {
          timeout: 60000,
          headers: {
              'x-api-jwt-token': this.$store.state.userData.jwt,
              'x-api-key': process.env.VUE_APP_APIKEY,
          },
        })
        this.routeOptimizing = false;
        this.optimizeRouteCheckbox = false;
      } catch(error) {
        console.log(error);
      }
    },
    async sortOrders()
    {
      try {
        this.$store.commit('setNotification',{
          show: true,
          head: "Sortowanie trasy..",
          subheader: `Nie zamykaj tego okna.`,
          success: true
        });
        
        if (this.content && this.content.data) {
          this.content.data = this.content.data.filter((value, index, self) => 
            index === self.findIndex((t) => (
              t.comment === value.comment
            ))
          );
          this.content.data.splice(0, 1);
        };
        this.actionRoute = true;
        let order = [];
        let correctFile = false;
        let preparedOrders = [];
        for(let i=0;i<this.route.orders.length; i++)
        {
          preparedOrders.push({
            index: i+1,
            ref: this.route.orders[i].ref.id,
          })
        }
    
        this.ownChange = true
        if(this.content !== null)
        {
          for(let i=0; i<this.content.data.length; i++)
          {
            if(this.content.data[i].comment !== undefined && this.content.data[i].stationnumberer !== undefined && this.content.data[i].city !== undefined )
            {
              correctFile = true;
            }
            else
            {
              correctFile = false;
            }
          }
   
          if(correctFile)
          {
            if(this.content.data.length === preparedOrders.length)
            {
              for(let i=0; i<this.content.data.length; i++)
              {
                for(let y=0; y<preparedOrders.length; y++)
                { 
                  if(this.content.data[i].city !== 'Baranów Mroczeń' || this.content.data[i].city !== 'Baranów')
                  {
                    if(this.content.data[i].comment === preparedOrders[y].ref)
                    {
                      order.push({
                        index: i+1,
                        ref: db.collection('Orders').doc(preparedOrders[y].ref)
                      })
                    } 
                  }
                }
              }
              await db.collection('Routes').doc(this.route.id).update({
                orders: order,
                "meta.lastModificationDate": moment().toDate(),
                "meta.name": this.$store.state.userData.name + ' ' + this.$store.state.userData.surname
              })
              this.emitClose();
              this.actionRoute = false;
              this.$store.commit('setNotification',{
                show: true,
                head: "Akcja zakończona sukcesem!",
                subheader: "Trasa została posortowana.",
                success: true
              });
            } else {
              this.emitClose();
              this.actionRoute = false;
              this.$store.commit('setNotification',{
                show: true,
                head: "Akcja zakończona niepowodzeniem!",
                subheader: "Trasa nie została posortowana.",
                success: false
              });
            }
          }
          else
          {
            this.emitClose();
            this.actionRoute = false;
            this.$store.commit('setNotification',{
              show: true,
              head: "Akcja zakończona niepowodzeniem!",
              subheader: `Sprawdź poprawność importowanego pliku CSV.`,
              success: false
            });
          }
        }
      }
      catch (error) {
        console.log(error);
      }
    },
    handleFileUpload(event)
    {
      if(event.target.files[0] !== undefined)
      {
        this.file = event.target.files[0];
        this.parseFile();
      }
      else
      {
        this.content = null;
      }
    },
    parseFile()
    {
      Papa.parse( this.file, {
          header: true,
          skipEmptyLines: true,
          delimiter: ";",
          complete: function( results ){
            this.content = results;
            this.parsed = true;
          }.bind(this)
      } );
    },
    async getVehicles()
    {
      try
      {
        this.dbListenerVehicles = db.collection("Vehicles").orderBy("meta.createdDate", "desc")
          .onSnapshot((querySnapshot) => {
  
          this.vehicles = [];
  
          querySnapshot.forEach((doc) => {
            const data = doc.data()
  
            const routeStart = this.route.date.start.seconds
            const routeEnd = this.route.date.end.seconds
  
            data.available = true
  
            data.bookings.forEach(booking => {
              const bookingStart = booking.start.seconds
              const bookingEnd = booking.end.seconds
  
              if(bookingEnd < routeStart) {
                //Booking ends before route starts
                return
              }
              if(bookingStart > routeEnd) {
                //Booking start after route ends
                return
              }
  
              data.available = false
            })
  
            this.vehicles.push(data);
          });
        
          this.vehicles.sort((a, b) => {
            if(a.available && !b.available){
              return -1
            }
            if(!a.available && b.available){
              return 1
            }
            return 0
          })
        });
      }
      catch (error)
      {
        console.log(error);
      }
    },
    async updateRoute()
    {
      this.ordersDownloaded = false;
      let newRouteDate = null
      if(Math.floor(this.date[0].getTime() / 1000) === Math.floor((moment.unix(this.route.date.start.seconds).toDate()).getTime() / 1000) && Math.floor(this.date[1].getTime() / 1000) === Math.floor((moment.unix(this.route.date.end.seconds).toDate()).getTime() / 1000)){
        newRouteDate = null
      }else{
        if(Math.floor(this.date[0].getTime() / 1000) === Math.floor((moment.unix(this.route.date.start.seconds).toDate()).getTime() / 1000) && Math.floor(this.date[1].getTime() / 1000) + 59 === Math.floor((moment.unix(this.route.date.end.seconds).toDate()).getTime() / 1000)){
          newRouteDate = null
        }else{
          this.date[1] = Math.floor(this.date[1].getTime() / 1000) + 59
          this.date[1] = new Date(this.date[1] * 1000)
          newRouteDate = {
            start: this.date[0],
            end: this.date[1]
          }
        }
      }
      this.ownChange = true
      const updateRes = await axios.post(`${this.$store.state.apiLink}/route/update`, {
        drivers: this.selectedDrivers.slice(),
        vehicle: this.oldVehicle,
        route: this.newRoute,
        name: this.$store.state.userData.name + ' ' + this.$store.state.userData.surname,
        newRouteDate: newRouteDate,
        markedOrders: this.ordersMarkedAsDelivered,
        deliveredOrders: this.deliveredOrders,
        status: 'update',
      })
      if(!updateRes.data.success)
      {
        this.callNotificationOnError(updateRes.data.log)
      }
      else
      {
        if(this.content !== null)
        {
          this.sortOrders();
        }
        this.$store.commit('setNotification',{
          show: true,
          head: "Akcja zakończona sukcesem!",
          subheader: "Trasa została zaktualizowana.",
          success: true
        });
        this.emitClose()
        this.ordersDownloaded = true;
      }
    },
    callNotificationOnError(translatedCode)
    {
      this.ordersDownloaded = true;
      this.emitClose();
      this.$store.commit('setNotification',{
        show: true,
        head: "Akcja zakończona niepowodzeniem!",
        subheader: translatedCode,
        success: false
      });
    },
    emitClose()
    {
      this.$emit("close");
    },
    async deleteRoute()
    {
      try {
        this.deleteAttempt = true;
        this.actionRoute = true;
        this.ownChange = true;
        let ordersToDelete = []; 
        for(let i=0; i<this.route.orders.length; i++)
        {
          ordersToDelete.push(this.route.orders[i].ref.id)
        }
        if(ordersToDelete.length !== this.route.orders.length) throw 'ERROR: array orders are not the same'
        const res = await axios.post(`${this.$store.state.apiLink}/route/delete`, {
          orders: ordersToDelete,
          route: this.route
        })
        if(!res.data.success)
        {
          throw res.data.log;
        }
        else
        {
          for(let orderId of ordersToDelete) {
            await this.$store.dispatch('updateHistory', {
              collectionName: "Orders",
              docId: orderId,
              message: "Usunięto trasę z zamówieniem.",
              details: [`Nazwa trasy: ${this.route.title || "BRAK"}`]
            })
          }
          setTimeout(() => {
            this.actionRoute = false;
          }, 1500);
          this.emitClose()
          this.$store.commit('setNotification', {
            show: true,
            head: "Akcja zakończona sukcesem!",
            subheader: res.data.log,
            success: true
          });
        }
      } catch (error)
      {
        console.log(error);
        setTimeout(() => {
          this.actionRoute = false;
        }, 1500);
        this.deleteAttempt = false;
        this.ownChange = false;
        this.emitClose()
        this.$store.commit('setNotification', {
          show: true,
          head: "Akcja zakończona niepowodzeniem!",
          subheader: error,
          success: false
        });
      }
    },
    async getGroups(){
      const groups = await db.collection("PostcodeGroups").get()

      //Getting all availble groups from db
      groups.forEach(doc => {
        const data = doc.data()
        if(data.name !== 'Wszystkie zamówienia')
        {
          this.groups.push(data)
        }
      })
    },
    async getDrivers() {
      this.drivers.all = []
      const drivers = await db.collection("UsersData").where("permissions.driver", "==", true).get()

      drivers.forEach(doc => {
        const driver = doc.data()
        const routeStart = this.route.date.start.seconds
        const routeEnd = this.route.date.end.seconds

        driver.available = true

        //check if the driver is assigned to the route
        if(this.route.drivers.find(el => el.id === driver.id)) {
          driver.selected = true
        } else {
          driver.routeSchedules.forEach(schedule => {
            const bookingStart = schedule.start.seconds;
            const bookingEnd = schedule.end.seconds;
            if(bookingEnd < routeStart) {
              //Booking ends before route starts so it's irrelevant
              return
            }
            if(bookingStart > routeEnd) {
              //Booking starts after route ends so it's irrelevant
              return
            }
            driver.available = false
          })
        }

        this.drivers.all.push(driver)
      })
    },
    routeValidator()
    {
      let errorsAmount = 0;
      this.clearErrors();
      if(this.selectedDrivers.length === 0)
      {
        errorsAmount++;
        this.errors.drivers = true;
      }
      if(this.selectedCountry === null)
      {
        errorsAmount++;
        this.errors.countries = true;
      }

      return errorsAmount;
    },
    validateRouteDataBeforeUpdate()
    {
      this.clearErrors();
      if(this.routeValidator() > 0) return;
      this.updateRoute();    
    },
    // validate()
    // {
    //   this.clearErrors()
    //   let validationSuccess = true

    //   if(!this.selectedDrivers.length) {
    //     this.errors.drivers = "Co najmniej jeden kierowca jest wymagany."
    //     validationSuccess = false
    //   }
    //   if(this.selectedCountry === null)
    //   {
    //     this.errors.countries = true;
    //     validationSuccess = false
    //   }

    //   return validationSuccess
    // }
  },
  computed: {
    selectedDrivers() {
      const selectedDrivers = []
      this.drivers.all.forEach(driver => {
        if(driver.selected) selectedDrivers.push(driver)
      })
      return selectedDrivers
    },
    filteredVehicles(){
      if(this.vehicle.query === '') {
        return this.vehicles
      } else {
        return this.vehicles.filter((item) => {
          const query = this.vehicle.query.toLowerCase()
          const { brand, model } = item
          const { value } = item.attribs.licensePlate

          if(brand.toLowerCase().includes(query) || model.toLowerCase().includes(query) || value.toLowerCase().includes(query)){
            return true
          }
        })
      }
    },
    filteredGroups(){
      if(this.group.query === '') return this.groups
      else {
        return this.groups.filter((item) => {
          const query = this.group.query.toLowerCase()

          if(item.name.toLowerCase().includes(query)) {
            return true
          } 
        })
      }
    },
    filteredDrivers() {
      if(this.driver.query === '') return this.drivers
      else {
        return this.drivers.filter((item) => {
          const query = this.driver.query.toLowerCase()

          if(item.name.toLowerCase().includes(query) || item.surname.toLowerCase().includes(query)) {
            return true
          } 
        })
      }
    },
    deliveredOrders() {
      const deliveredOrders = []

      this.ordersMarkedAsDelivered.forEach(order => {
        if(order.delivered === true){
          deliveredOrders.push(order.id)
        }
      })

      return deliveredOrders
    }
  },
  watch: {
    'route.meta.lastModificationDate.seconds'(a, b){
      if(a !== b) {
        this.reloadModal()
      }
    },
    'blockModal.state'() {
      if(this.blockModal.state && this.ownChange) {
        setTimeout(() => {
          this.$emit('refreshModalData')
          this.ownChange = false
        }, 200);
      }
    },
    selectedStatus(a){
      if(a !== null) {
        this.newRoute.statusId = this.selectedStatus.id
      }
    },
    selectedCountry(a){
      if(a !== null) {
        this.newRoute.country = this.selectedCountry
      }
    },
    markedEverthingAsDelivered(value){
      if(value === true) {
        this.ordersMarkedAsDelivered.forEach(order => {
          order.delivered = true
        })
      } else {
        this.ordersMarkedAsDelivered.forEach(order => {
          order.delivered = false
        })
      }
    },
  },
}
</script>

<style lang="scss" scoped>

$width: 15px;
$height: 15px;
$bounce_height: 30px;

.spinner {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 3.8px solid #616161;
  animation: spinner-bulqg1 1.1199999999999999s infinite linear alternate,
        spinner-oaa3wk 2.2399999999999998s infinite linear;
}

.text {
  color: #aeafb1;
  display: inline-block;
}

.bounceball {
  position: relative;
  display: inline-block;
  height: 37px;
  width: $width;
  &:before {
    position: absolute;
    content: '';
    display: block;
    top: 0;
    width: $width;
    height: $height;
    border-radius: 50%;
    background-color: #aeafb1;
    transform-origin: 50%;
    animation: bounce 500ms alternate infinite ease;
  }
}

@keyframes spinner-bulqg1 {
  0% {
      clip-path: polygon(50% 50%, 0 0, 50% 0%, 50% 0%, 50% 0%, 50% 0%, 50% 0%);
  }

  12.5% {
      clip-path: polygon(50% 50%, 0 0, 50% 0%, 100% 0%, 100% 0%, 100% 0%, 100% 0%);
  }

  25% {
      clip-path: polygon(50% 50%, 0 0, 50% 0%, 100% 0%, 100% 100%, 100% 100%, 100% 100%);
  }

  50% {
      clip-path: polygon(50% 50%, 0 0, 50% 0%, 100% 0%, 100% 100%, 50% 100%, 0% 100%);
  }

  62.5% {
      clip-path: polygon(50% 50%, 100% 0, 100% 0%, 100% 0%, 100% 100%, 50% 100%, 0% 100%);
  }

  75% {
      clip-path: polygon(50% 50%, 100% 100%, 100% 100%, 100% 100%, 100% 100%, 50% 100%, 0% 100%);
  }

  100% {
      clip-path: polygon(50% 50%, 50% 100%, 50% 100%, 50% 100%, 50% 100%, 50% 100%, 0% 100%);
  }
}

@keyframes spinner-oaa3wk {
  0% {
      transform: scaleY(1) rotate(0deg);
  }

  49.99% {
      transform: scaleY(1) rotate(135deg);
  }

  50% {
      transform: scaleY(-1) rotate(0deg);
  }

  100% {
      transform: scaleY(-1) rotate(-135deg);
  }
}

@keyframes bounce {
  0% {
    top: $bounce_height;
    height: 5px;
    border-radius: 60px 60px 20px 20px;
    transform: scaleX(2);
  }
  35% {
    height: $height;
    border-radius: 50%;
    transform: scaleX(1);
  }
  100% {
    top: 0;
  }
}

.fade-enter-active{
  transition: opacity 1s ease;
}
.fade-leave-active{
  transition: opacity 0s;
  display: none;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.fade2-enter-active,
.fade2-leave-active {
  transition: opacity 0.5s ease;
}

.fade2-enter-from,
.fade2-leave-to {
  opacity: 0;
}


</style>