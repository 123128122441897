<template>
  <TransitionRoot as="template" :show="open">
    <Dialog as="div" class="fixed z-10 inset-0 overflow-y-auto" >
      <div class="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
          <DialogOverlay class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </TransitionChild> 
        <!-- This element is to trick the browser into centering the modal contents. -->
        <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
        <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leave-from="opacity-100 translate-y-0 sm:scale-100" leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
          <div class="relative inline-block align-bottom bg-white rounded-lg px-36 py-20 text-left overflow-visible shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-xl sm:w-full sm:p-6">
            <LoadingSpinnerHub class="pb-14 pt-9 mt-5" v-if="loading" />
              <div v-if="step === 1">
                <div v-show="!loading">
                  <div class="flex justify-between pr-2 pl-1 pb-1" v-if="!variantsEmpty">
                  <div class="flex pt-1 pb-2">
                    <div class="select-none w-96">
                      <h4 class="text-lg font-bold text-gray-700">Wybierz wariant</h4>
                      <p class="text-base mt-1 text-gray-500">W tym miejscu możesz wybrać wariant produktu, którego możesz wydrukować etykiete produktową.</p>
                    </div>
                  </div>
                    <div class="pt-2 items-center -mr-1" >
                      <!-- <XIcon @click.prevent="emitClose(), addToCart(this.checkedVariants)" class="transition-all duration-300 w-6 h-6 text-gray-400 hover:text-gray-700" /> -->
                      <XIcon @click.prevent="emitClose()" class="transition-all duration-300 w-6 h-6 text-gray-400 hover:text-gray-700" />
                    </div>
                  </div>
                  <EmptyState v-if="variantsEmpty" />
                  <div v-if="!variantsEmpty" :class="[sortVariants.length > 4 ? 'overflow-y-scroll max-h-112' : '', 'pr-2 pl-1 pb-1']">
                    <div class="pb-3" v-if="this.variants.length > 0">
                      <span class="select-none text-sm font-semibold leading-7 text-indigo-600 uppercase mb-1">
                        Sugerowane
                      </span>
                      <ul role="list" class="divide-y divide-gray-100 space-y-3">
                        <li @click="selectVariant(ord, ord.id)" v-for="ord in mergedVariants" :key="ord.id" :class="[ord.checked ? 'bg-green-100' : 'bg-indigo-50', 'overflow-hidden rounded-md px-6 shadow-md flex justify-between gap-x-6 py-5 transition-all duration-300 select-none']">
                          <div class="flex min-w-0 gap-x-4">
                            <img :onload="loadImage" class="h-12 w-12 flex-none bg-gray-50 mix-blend-multiply" :src="ord.image.url !== undefined && ord.image.url !== null && ord.image.url.length > 0 ? ord.image.url : `https://api.dicebear.com/7.x/initials/svg?seed=${ord.name}`" alt="" />
                            <div class="min-w-0 flex-auto">
                              <p class="text-sm font-semibold leading-6 text-gray-900">{{ord.name}}</p>
                              <p class="truncate text-xs leading-5 text-gray-500">{{ord.manufacturer.name !== undefined && ord.manufacturer.name !== null  ? ord.manufacturer.name : ord.manufacturer}}</p>
                            </div>
                          </div>
                          <div class="items-center flex">
                            <input v-model="ord.checked" type="checkbox" class="h-4 w-4 rounded border-gray-500 text-indigo-600 focus:ring-indigo-600" />
                          </div>
                        </li>
                      </ul>
                    </div>
                    <div>
                      <span class="select-none text-sm font-semibold leading-7 text-indigo-600 uppercase mb-1">
                        Wszystkie warianty
                      </span>
                      <ul role="list" class="divide-y divide-gray-100 space-y-3">
                        <li @click="selectVariant(ord, ord.id)" v-for="(ord, index) in this.sortVariants" :key="ord.id" :class="[ord.checked ? 'bg-green-100' : 'bg-gray-50', 'overflow-hidden rounded-md px-6 shadow-md flex justify-between gap-x-6 py-5 transition-all duration-300 select-none']">
                          <div class="flex min-w-0 gap-x-4">
                            <img :onload="loadImage" class="h-12 w-12 flex-none bg-gray-50 mix-blend-multiply" :src="ord.image.url !== undefined && ord.image.url !== null && ord.image.url.length > 0 ? ord.image.url : `https://api.dicebear.com/7.x/initials/svg?seed=${ord.name}`" alt="" />
                            <div class="min-w-0 flex-auto">
                              <p class="text-sm font-semibold leading-6 text-gray-900">{{ord.name}}</p>
                              <p class="truncate text-xs leading-5 text-gray-500">{{ord.manufacturer.name !== undefined && ord.manufacturer.name !== null  ? ord.manufacturer.name : ord.manufacturer}}</p>
                            </div>
                          </div>
                          <div class="items-center flex">
                            <input v-model="ord.checked" type="checkbox" class="h-4 w-4 rounded border-gray-500 text-indigo-600 focus:ring-indigo-600" />
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <Transition name="fade">
                  <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse pr-2 pl-1 pb-1 gap-5" v-if="checkedVariants.length > 0">
                    <button type="button" :disabled="checkedVariants.length === 0 && blockButton" :class="[blockButton ? 'pointer-events-none opacity-40' : '', checkedVariants.length === 0 ? 'pointer-events-none opacity-40' : '', 'w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 text-base flex-1 font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:w-auto sm:text-sm transition-all duration-300 bg-indigo-600 hover:bg-indigo-500 gap-x-2']" @click="changeStep(2)">
                      <CheckCircleIcon class="-ml-0.5 h-5 w-5" aria-hidden="true" />
                      Przejdź dalej
                    </button>
                    <button type="button" :class="['transition-all duration-300 border border-gray-300 w-full inline-flex justify-center rounded-md shadow-sm px-4 py-2 text-base flex-1 font-medium sm:w-auto sm:text-sm bg-white text-gray-700 hover:bg-gray-100 gap-x-2 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500']" @click="close">
                      Anuluj
                    </button>
                  </div>
                  </Transition>
                </div>
              </div>
              <div v-if="step === 2">
                <!-- title -->
                <div class="flex justify-between pr-2 pl-1 pb-1">
                  <div class="flex pt-1 pb-2">
                    <div class="select-none w-96">
                      <h4 class="text-lg font-bold text-gray-700">Wybierz ilość do wygenerowania.</h4>
                      <p class="text-base mt-1 text-gray-500">W tym miejscu możesz wybrać ilość produktu, ile chcesz wygenerować w pliku PDF.</p>
                    </div>
                  </div>
                  <div class="pt-2 items-center -mr-1" >
                    <!-- <XIcon @click.prevent="emitClose(), addToCart(this.checkedVariants)" class="transition-all duration-300 w-6 h-6 text-gray-400 hover:text-gray-700" /> -->
                    <XIcon @click.prevent="emitClose()" class="transition-all duration-300 w-6 h-6 text-gray-400 hover:text-gray-700" />
                  </div>
                </div>

                <ul v-if="this.checkedVariants.length > 0" role="list" class="space-y-3 py-2">
                  <li v-for="(variant, index) in this.checkedVariants" :key="index" class="overflow-hidden rounded-md bg-white px-6 py-4 shadow">
                    <!-- <span @click.prevent="createPartsForProduct()">
                    </span> -->
                    <div @click.prevent :class="['flex justify-between items-center gap-2 text-sm select-none']">
                      <div class="justify-start flex items-center gap-3">
                          <img class="mix-blend-multiply w-12 h-12" :src="variant.image.url !== undefined && variant.image.url !== null && variant.image.url.length > 0 ? variant.image.url : `https://api.dicebear.com/7.x/initials/svg?seed=${variant.name}`" alt="">
                          <!-- <span v-tooltip="`${variant.name}`">
                            {{variant.name.slice(0, 40)}}{{variant.name.slice(0, 40).length === 40 ? '...' : ''}}
                          </span> -->
                          <span v-tooltip="`${variant.name}`" class="truncate">{{variant.name}}</span>
                      </div>
                      <div class="flex items-center justify-end gap-2">
                          <input @change="checkNumber(variant)" v-model="variant.printAmount" type="number" min="1" max="999" class="block w-16 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
                          <TrashIcon @click.prevent="delVariant(index)" class="text-gray-500 hover:text-red-500 transition-all duration-300 h-6 w-6" />
                      </div>
                    </div>
                  </li>
                </ul>
                  
                <Transition name="fade">
                  <EmptyState v-if="this.checkedVariants.length === 0" />
                </Transition>

                <Transition name="fade">
                  <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse pr-2 pl-1 pb-1 gap-5">
                    <button type="button" :disabled="checkedVariants.length === 0 && blockButton" :class="[checkedVariants.length === 0 ? 'opacity-40 pointer-events-none' : '', blockButton ? 'pointer-events-none opacity-40' : '', checkedVariants.length === 0 ? 'pointer-events-none opacity-40' : '', 'w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 text-base flex-1 font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:w-auto sm:text-sm transition-all duration-300 bg-indigo-600 hover:bg-indigo-500 gap-x-2']" @click="emitPrint('withoutCart', this.checkedVariants)">
                      <CheckCircleIcon class="-ml-0.5 h-5 w-5" aria-hidden="true" />
                      Akceptuj
                    </button>
                    <button type="button" :class="['transition-all duration-300 border border-gray-300 w-full inline-flex justify-center rounded-md shadow-sm px-4 py-2 text-base flex-1 font-medium sm:w-auto sm:text-sm bg-white text-gray-700 hover:bg-gray-100 gap-x-2 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500']" @click="changeStep(1)">
                      Wróć
                    </button>
                  </div>
                </Transition>

              </div>
          </div>
        </TransitionChild>

      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>
import axios from 'axios';
import { Dialog, DialogOverlay, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'
import { TrashIcon, BanIcon, ExclamationIcon, CursorClickIcon, XIcon, CheckCircleIcon} from '@heroicons/vue/outline';
import LoadingSpinnerHub from './LoadingSpinnerHub.vue';
import EmptyState from './EmptyState.vue';

export default {
  components: {
    EmptyState, TrashIcon, CheckCircleIcon, BanIcon, LoadingSpinnerHub, XIcon, Dialog, DialogOverlay, DialogTitle, TransitionChild, TransitionRoot, ExclamationIcon, CursorClickIcon
  },
  props: ['orders', 'variants'],
  data() {
    return {
      open: true,
      loading: true,
      checkedVariants: [],
      mergedVariants: [],
      blockButton: false,
      step: 1,
      variantsEmpty: false,
      sortVariants: null
    }
  },
  // computed: {
  //   sortedVariantsArray() {
  //     const variantsArray = Object.entries(this.orders.variants).map(([key, value]) => ({
  //       ...value, 
  //       id: key
  //     }));

  //     return variantsArray.sort((a, b) => {
  //       const numberPattern = /\d+$/; // Regex to match numbers at the end of the string
  //       const numberA = parseInt(a.name.match(numberPattern), 10);
  //       const numberB = parseInt(b.name.match(numberPattern), 10);
  //       return numberB - numberA;
  //     });
  //   }
  // },
  methods: {
    delVariant(indx)
    {
      for(let i=0 ; i<this.checkedVariants.length; i++)
      {
        if(i === indx)
        {
          this.checkedVariants[i].checked = false;
          this.checkedVariants.splice(indx, 1)
        }
      }
    },
    addToCart(val)
    {
      this.$store.dispatch("addVariantsToCart", val);
    },
    changeStep(val)
    {
      switch(val)
      {
        case 1: 
          this.step = 1;
          this.loading = true; 
          break;
        case 2:
          this.step = 2;
      }
    },
    loadImage()
    {
      setTimeout(() => {
        this.loading = false;
      }, 500);
    },
    selectVariant(val, index)
    {
      if(!val.checked)
      {
        val.checked = true;
        val.printAmount = 1;
        this.checkedVariants.push(val)
      }
      else
      {
        val.checked = false;
        for(let i=0; i<this.checkedVariants.length; i++)
        {
          if(index === this.checkedVariants[i].id)
          {
            this.checkedVariants.splice(i, 1)
            break;
          }
        }
      }
   
    },
    checkNumber(val)
    {
      if(val.printAmount <= 0)
      {
        val.printAmount = 1;
      }
      else
      {
        val.printAmount = Math.floor(val.printAmount)
      }
    },
    checkIfExists()
    {
      for(let i=0; i<this.variants.length; i++)
      {
        for(let y=0; y<Object.keys(this.orders.variants).length; y++)
        {
          const products = Object.entries(this.orders.variants);
          if(this.variants[i].id === products[y][0])
          {
            let variantProduct = this.mergedVariants.find((prod) => prod.id === this.variants[i].id)
            if(!variantProduct)
            {
              this.mergedVariants.push(products[y][1])
            }
          }
        }
      }
      if(Object.keys(this.orders.variants).length === 0)
      {
        this.loading = false;
        this.variantsEmpty = true;
      }
      else
      {
        this.loading = false;
      } 
    },
    emitClose()
    {
      this.$emit("close");
    },
    emitPrint()
    {
      let print = {
        name: 'withoutCart',
        variants: this.checkedVariants,
      };
      this.$emit("print", print);
      // this.createPartsForProduct();
      this.close();
    },
    emitAccept()
    {
      this.blockButton = true;
      // this.$emit("accept", this.checkedVariants);
      // this.$emit("close");
      setTimeout(() => {
        this.blockButton = false;
      }, 2000);
    },
    close()
    {
      this.emitClose();
      this.open = false
    }
  },
  created() {
    this.checkIfExists();
    const variantsArray = Object.entries(this.orders.variants).map(([key, value]) => ({
      ...value, 
      id: key
    }));

    this.sortVariants = variantsArray.sort((a, b) => {
      const numberPattern = /\d+$/; // Regex to match numbers at the end of the string
      const numberA = parseInt(a.name.match(numberPattern), 10);
      const numberB = parseInt(b.name.match(numberPattern), 10);
      return numberB - numberA;
    });
  }
}
</script>

<style scoped>
/* width */
::-webkit-scrollbar {
    width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
    background: rgba(209, 213, 219);
    border-radius: 5px;
}
  
/* Handle */
::-webkit-scrollbar-thumb {
    background: rgba(107, 114, 128);
    border-radius: 5px;
}
  
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
} 
</style>