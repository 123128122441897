<template>
<div class="min-h-full" v-if="$store.state.authIsReady">
  <div class="bg-gray-800 pb-32">
    <Disclosure as="nav" class="bg-gray-800" v-slot="{ open }">
      <div class="mx-auto max-w-7xl sm:px-6 lg:px-8">
        <div class="border-b border-gray-700">
          <div class="flex h-16 items-center justify-between px-4 sm:px-0">
            <div class="flex items-center">
              <div class="flex-shrink-0">
                
              </div>
              <div class="hidden md:block">
                <div class=" flex items-baseline space-x-4 text-white text-medium select-none text-xs">
                    Jan-Art Sp. z o.o. <span class="opacity-20 ml-1 text-xxs">{{this.$store.state.app.version}}</span> 
                  <!-- <a v-for="item in navigation" :key="item.name" :href="item.href" :class="[item.current ? 'bg-gray-900 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white', 'px-3 py-2 rounded-md text-sm font-medium']" :aria-current="item.current ? 'page' : undefined">{{ item.name }}</a> -->
                </div>
              </div>
            </div>
            <div class="hidden md:block">
              <div class="ml-4 flex items-center md:ml-6">

                <!-- Profile dropdown -->
                <Menu as="div" class="relative ml-3">
                  <div>
                    <MenuButton class="flex max-w-xs items-center rounded-full bg-gray-800 text-sm focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
                      <span class="sr-only">Open user menu</span>
                      <img class="h-8 w-8 rounded-full" :src="`https://api.dicebear.com/7.x/initials/svg?seed=${this.$store.state.userData.name} ${this.$store.state.userData.surname}`" alt="" />
                    </MenuButton>
                  </div>
                  <transition enter-active-class="transition ease-out duration-100" enter-from-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100" leave-active-class="transition ease-in duration-75" leave-from-class="transform opacity-100 scale-100" leave-to-class="transform opacity-0 scale-95">
                    <MenuItems class="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                      <MenuItem >
                        <div @click.prevent="logout" class="block px-4 py-2 text-sm text-gray-700 cursor-pointer hover:bg-gray-100">Wyloguj się</div>
                      </MenuItem>
                    </MenuItems>
                  </transition>
                </Menu>
              </div>
            </div>
            <div class="-mr-2 flex md:hidden">
              <!-- Mobile menu button -->
              <DisclosureButton class="inline-flex items-center justify-center rounded-md bg-gray-800 p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
                <span class="sr-only">Open main menu</span>
                <Bars3Icon v-if="!open" class="block h-6 w-6" aria-hidden="true" />
                <XMarkIcon v-else class="block h-6 w-6" aria-hidden="true" />
              </DisclosureButton>
            </div>
          </div>
        </div>
      </div>

      <DisclosurePanel class="border-b border-gray-700 md:hidden">
        <div class="space-y-1 px-2 py-3 sm:px-3">
          
        </div>
        <div class="border-t border-gray-700 pt-4 pb-3">
          <div class="flex items-center px-5">
            <div class="flex-shrink-0">
              <img class="h-10 w-10 rounded-full" :src="`https://api.dicebear.com/7.x/initials/svg?seed=${this.$store.state.userData.name} ${this.$store.state.userData.surname}`" alt="" />
            </div>
            <div class="ml-3">
              <div class="text-base font-medium leading-none text-white">{{ $store.state.userData.name}}</div>
              <div class="text-sm font-medium leading-none text-gray-400">{{ $store.state.userData.surname }}</div>
            </div>
          </div>
          <div class="mt-3 space-y-1 px-2">
            <DisclosureButton @click.prevent="logout" class="block rounded-md px-3 py-2 text-base font-medium text-gray-400 hover:bg-gray-700 hover:text-white">Wyloguj się</DisclosureButton>
          </div>
        </div>
      </DisclosurePanel>
    </Disclosure>
    <header class="py-10">
      <div class="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        <h1 class="text-3xl font-bold tracking-tight text-white select-none">Wybierz moduł</h1>
      </div>
    </header>
  </div>
  <main class="-mt-32 max-w-7xl mx-auto">
    <div class="grid grid-cols-1 gap-4 sm:grid-cols-2 px-4 sm:px-6 lg:px-8" v-if="!hubLoading">
      <div v-for="mdl in modules" :key="mdl" class="select-none relative flex items-center space-x-4 rounded-lg border border-gray-300 bg-white hover:bg-gray-100 px-6 py-5 shadow-sm focus-within:ring-2 focus-within:ring-gray-500 focus-within:ring-offset-2 hover:shadow-lg hover:border-gray-300 transition-all duration-300">
        <div class="flex-shrink-0">
          <img class="h-10 w-10 rounded-full" :src="mdl.introduction.img" alt="" />
        </div>
        <div class="min-w-0 flex-1">
          <router-link :to="mdl.url" class="focus:outline-none">
            <span class="absolute inset-0" aria-hidden="true" />
            <p class="text-sm font-medium text-gray-900">{{ mdl.introduction.header }}</p>
            <p class="truncate text-sm text-gray-500">{{ mdl.introduction.subheader }}</p>
          </router-link>
        </div>
      </div>
    </div>
  </main>
  <Loader v-if="hubLoading" />
  <div v-else class="text-gray-500 text-xs select-none flex justify-center py-4 w-full sm:fixed relative bottom-0">
    <p class="text-center text-xs text-gray-400">&copy; {{moment().year()}} Powered by <a href="https://complaia.systems/" class="transition ease-in-out duration-300 text-transparent font-medium bg-clip-text bg-gradient-to-r from-purple-500 to-blue-500 opacity-90 hover:opacity-100">Complaia Systems</a></p>
  </div>
</div>
</template>

<script>
import { auth,db } from "@/firebase/gfbconf";
import { Disclosure, DisclosureButton, DisclosurePanel, Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue'
import { MenuIcon as Bars3Icon, XIcon as XMarkIcon } from '@heroicons/vue/solid'
import Loader from '@/components/LoadingSpinnerHub.vue';
import moment from 'moment';
//import axios from 'axios';
//import {db,storage} from "@/firebase/gfbconf.js"
export default {
  name: "Hub",
  data() {
    return {
      modules: [],
      hubLoading: false
    }
  },
  watch:{
      '$route'()
      {
          this.$store.commit("resetGlobalLoader");
      }
  },
  components: {
    Disclosure, DisclosureButton, DisclosurePanel, Menu, MenuButton, MenuItem, MenuItems, Bars3Icon, XMarkIcon,Loader
  },
  created() {
    this.moment = moment;
    // Auth guard
    const loginSubscription = auth.onAuthStateChanged(async (user) => {
      if(user)
      {
        this.$store.commit("setUser", user);
        // Get user data by id from db
        let userCred = await db.collection("UsersData").doc(this.$store.state.user.uid).get();
        if(userCred.exists)
        {
          this.$store.commit('setUserCredentials', userCred.data());
          this.$store.commit("setAuthIsReady", true);
          await this.getModules();
        }
        else
        {
            this.$router.push("/");
        }
      }
      else
      {
        this.$router.push("/");
      }
    })
  },
  methods:
  {
    // Get menu options
    async getModules()
    {
      this.hubLoading = true;
      const hubList = await db.collection("HubList").get();
      if(hubList.empty)
      {
        this.modules = [];
      }
      else
      {
        for(let i=0; i<hubList.docs.length; i++)
        {
          let moduleData = hubList.docs[i].data();
          if(this.$store.state.userData.permissions[moduleData.permission] === true || this.$store.state.userData.permissions.admin === true)
          {
            this.modules.push(hubList.docs[i].data());
          }
          this.modules.sort((a, b) => parseFloat(a.index) - parseFloat(b.index));
        }
      }
      setTimeout(() => {
        this.hubLoading = false;
      }, 1000)
    },
    async logout() {
      try {
        await this.$store.dispatch("logout");
        this.$router.push("/");
      } catch (err)
      {
        console.log(err.message);
      }
    }
  },
  computed:
  {
  }
}
</script>
<style lang="scss" scoped>

</style>