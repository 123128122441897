<template>
  <div id="login" class="h-screen bg-gray-100">
    <transition name="fade">
      <Loader v-if="$store.state.globalLoader.show" :header="$store.state.globalLoader.head" :key="$store.state.globalLoader.head" :subheader="$store.state.globalLoader.subheader"></Loader>
    </transition>
    <div class="min-h-full flex">
      <div class="flex-1 flex flex-col justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
        <div class="mx-auto w-full max-w-sm lg:w-96">
          <transition name="fade">
            <div class="rounded-md bg-red-50 p-4 transition-all mb-6" v-show="error">
              <div class="flex">
                <div class="ml-3">
                  <h3 class="text-sm font-medium text-red-800">
                    Coś poszło nie tak w trakcie procesu logowania. Podejmij następujące akcje:
                  </h3>
                  <div class="mt-2 text-sm text-red-700">
                    <ul role="list" class="list-disc pl-5 space-y-1">
                      <li>
                        Sprawdź e-mail lub hasło.
                      </li>
                      <li>
                        Sprawdź połączenie z internetem. 
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </transition>
          <div>
            <h2 class="mt-6 text-3xl font-extrabold text-gray-900">
              Witaj, udanej pracy!
            </h2>
            <p class="mt-2 text-sm text-gray-600">
              Jan-Art Sp. z o.o.
              {{ ' ' }}
              
            </p>
          </div>

          <div class="mt-8">
            <div>
              <div class="mt-6 relative">
                <div class="absolute inset-0 flex items-center" aria-hidden="true">
                  <div class="w-full border-t border-gray-300" />
                </div>
                <div class="relative flex justify-center text-sm">
                  <span class="px-2 bg-gray-100 text-gray-500">
                    Wpisz swój email oraz hasło, aby się zalogować.
                  </span>
                </div>
              </div>
            </div>

            <div class="mt-6">
              <form action="#" method="POST" class="space-y-6">
                <div>
                  <label for="email" class="block text-sm font-medium text-gray-700">
                    E-mail
                  </label>
                  <div class="mt-1">
                    <input id="email" placeholder="E-mail" name="email" type="email" v-model="email" autocomplete="email" required="" class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-400 focus:border-blue-400 sm:text-sm transition-all" />
                  </div>
                </div>

                <div class="space-y-1">
                  <label for="password" class="block text-sm font-medium text-gray-700">
                    Hasło
                  </label>
                  <div class="mt-1">
                    <input id="password" placeholder="Hasło" name="password" v-model="password" type="password" autocomplete="current-password" required="" class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-400 focus:border-blue-400 sm:text-sm transition-all" />
                  </div>
                </div>

                <div>
                  <button @click.prevent="logInUser" :disabled="logginIn" type="submit" :class="[logginIn ? 'bg-gray-400 focus:ring-gray-500 select-none cursor-default pointer-events-none' : 'bg-blue-400 hover:bg-blue-500 focus:ring-indigo-500', 'gap-x-1 w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 transition-all duration-300']">
                    <LoginIcon v-if="!logginIn" class="transform rotate-180 h-5 w-5" aria-hidden="true" />
                    <span v-if="!logginIn">Zaloguj się</span>
                    <div v-if="logginIn" class="spinner"></div>
                  </button>
                </div>

                <div class="text-xs font-medium">
                  &copy; Powered by <a href="https://complaia.systems/" class="text-purple-500 hover:text-purple-400">Complaia Systems</a>.
                </div>

              </form>
            </div>
          </div>
        </div>
      </div>
      <div class="hidden lg:block relative w-0 flex-1">
        <img class="absolute inset-0 h-full w-full object-cover" src="https://firebasestorage.googleapis.com/v0/b/jan-art.appspot.com/o/assets%2Fpexels-ilya-shakir-2440471%20(1).jpg?alt=media&token=a49f003e-6f0e-4c77-97ed-3414b218211c" alt="System wykonany przez Complaia Systems" />
      </div>
    </div>
  </div>
</template>

<script>
import Loader from '../components/Loader.vue';
import { LoginIcon } from '@heroicons/vue/outline'
export default {
  name: "Login",
  components: {
    Loader, LoginIcon
  },
  created()
  {
    if(location.hostname === 'localhost')
    {
      if(process.env.VUE_APP_VERSION === 'SANDBOX_LOCAL' || process.env.VUE_APP_VERSION === 'SANDBOX')
      {
        this.email = "devteam@complaia.systems",
        this.password = "123456"
      } else if(process.env.VUE_APP_VERSION === 'PRODUCTION' || process.env.VUE_APP_VERSION === 'PRODUCTION_LOCAL')
      {
        this.email = "devteam@complaia.systems",
        this.password = "C00mpl44sys"
      }
    }
    if(this.$store.state.globalLoader.show)
    {
      setTimeout(() => {
        this.$store.commit("resetGlobalLoader");
      }, 1000);
    }
  },
   watch:{
    '$route'()
    {
      this.$store.commit("resetGlobalLoader");
    }
  },
  data: function () {
    return {
      logginIn: false,
      error: false,
      email: "",
      password: ""
    }
  },
  methods: {
    async logInUser() {
      try {
        this.logginIn = true;
        await this.$store.dispatch("login", {
          email: this.email,
          password: this.password,
        });
        this.error = false;
        this.$router.push(`/hub`);
      } catch (err) {
        this.error = true;
        setTimeout(() => {
          this.$store.commit("resetGlobalLoader");
          this.logginIn = false;
        }, 1000);
        console.log(err.message);
      }
    },
  },
};
</script>
<style scoped>
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

.spinner {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 3.8px solid #ffffff;
  animation: spinner-bulqg1 1.1199999999999999s infinite linear alternate,
  spinner-oaa3wk 2.2399999999999998s infinite linear;
}

@keyframes spinner-bulqg1 {
  0% {
    clip-path: polygon(50% 50%, 0 0, 50% 0%, 50% 0%, 50% 0%, 50% 0%, 50% 0%);
  }

  12.5% {
    clip-path: polygon(50% 50%, 0 0, 50% 0%, 100% 0%, 100% 0%, 100% 0%, 100% 0%);
  }

  25% {
    clip-path: polygon(50% 50%, 0 0, 50% 0%, 100% 0%, 100% 100%, 100% 100%, 100% 100%);
  }

  50% {
    clip-path: polygon(50% 50%, 0 0, 50% 0%, 100% 0%, 100% 100%, 50% 100%, 0% 100%);
  }

  62.5% {
    clip-path: polygon(50% 50%, 100% 0, 100% 0%, 100% 0%, 100% 100%, 50% 100%, 0% 100%);
  }

  75% {
    clip-path: polygon(50% 50%, 100% 100%, 100% 100%, 100% 100%, 100% 100%, 50% 100%, 0% 100%);
  }

  100% {
    clip-path: polygon(50% 50%, 50% 100%, 50% 100%, 50% 100%, 50% 100%, 50% 100%, 0% 100%);
  }
}

@keyframes spinner-oaa3wk {
  0% {
    transform: scaleY(1) rotate(0deg);
  }

  49.99% {
    transform: scaleY(1) rotate(135deg);
  }

  50% {
    transform: scaleY(-1) rotate(0deg);
  }

  100% {
    transform: scaleY(-1) rotate(-135deg);
  }
}
</style>
